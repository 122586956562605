import toast from 'react-hot-toast'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BanIcon } from '@heroicons/react/outline'
import { DotsHorizontalIcon, ReplyIcon } from '@heroicons/react/solid'

import {
  FetchErrorInterface,
  OMSErrorResponseInterface,
  OrderDetailItemInterface,
  StatusType
} from '../../../interfaces'
import { createToast } from '../../Toast'
import { useOrderDetail } from '../../../hooks'
import OverlayPortal from '../../OverlayPortal'
import LoadingSpinner from '../../LoadingSpinner'
import { updateIgnoreLineItemStatus } from '../helpers'
import { InlineMenuOptionInterface } from '../../InlineMenu'
import SingleSelectDropdown from '../../SingleSelectDropdown'

type IgnoredLineItemOptionValueType = 'put-back'

interface IgnoredLineItemOptionInterface extends InlineMenuOptionInterface {
  value: IgnoredLineItemOptionValueType
}

const IGNORED_LINE_ITEM_OPTIONS: IgnoredLineItemOptionInterface[] = [
  {
    content: (
      <span className="flex items-center pr-10">
        <ReplyIcon className="mr-2 h-6 w-6 text-gray-600" /> Put back
      </span>
    ),
    searchLabel: 'Put back',
    value: 'put-back'
  }
]

const LINE_ITEM_PUT_BACK_TOASTS = {
  ERROR: 'prodigiOrderLineItemPutBackToast'
}

export function IgnoredLineItemHeader({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  const { id: orderId } = useParams<{ id: string }>()
  const { mutateOrderDetails } = useOrderDetail(orderId)

  const [putBackItemStatus, setPutBackItemStatus] = useState<StatusType>('idle')

  useEffect(() => {
    return () => {
      toast.dismiss(LINE_ITEM_PUT_BACK_TOASTS.ERROR)
    }
  }, [])

  async function handlePutBackItem() {
    setPutBackItemStatus('loading')
    toast.dismiss(LINE_ITEM_PUT_BACK_TOASTS.ERROR)

    try {
      await updateIgnoreLineItemStatus({ lineItemId: lineItem.id, orderId: orderId, shouldIgnore: false })
      await mutateOrderDetails()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'An error occurred while putting back item',
        id: LINE_ITEM_PUT_BACK_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setPutBackItemStatus('error')
    }
  }

  function handleSelectedOption(value: IgnoredLineItemOptionValueType) {
    if (value === 'put-back') {
      handlePutBackItem()
    }
  }

  return (
    <div
      className="flex items-center justify-between border border-gray-300 bg-gray-100 p-4"
      data-test={`ignored-line-item-header-${lineItem.id}`}
    >
      <div className="flex items-center gap-2 text-sm text-gray-700 md:text-base">
        <BanIcon className="h-9 w-9" />
        <div>
          <strong className="">Ignored</strong>
          {lineItem.salesChannelProductDetails.title && <span>: {lineItem.salesChannelProductDetails.title}</span>}
        </div>
      </div>

      <div>
        {putBackItemStatus === 'loading' ? (
          <button
            className="flex aspect-square items-center justify-center rounded-lg border border-gray-300 bg-gray-100 py-2 px-3"
            disabled={true}
          >
            <LoadingSpinner className="h-6 w-6" />
          </button>
        ) : (
          <SingleSelectDropdown
            align="end"
            className="aspect-square border-gray-300 bg-gray-100"
            dropdownName="Options"
            triggerContent={
              <>
                <DotsHorizontalIcon className="h-6 w-6 text-gray-500" />
                <span className="sr-only">Options</span>
              </>
            }
            options={IGNORED_LINE_ITEM_OPTIONS}
            updateSelected={(value) => {
              handleSelectedOption(value as IgnoredLineItemOptionValueType)
            }}
          />
        )}
      </div>

      {putBackItemStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}
