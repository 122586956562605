import { useSplitToggle } from '../../../../hooks'
import { SettingsPanel } from '../SettingsPanel.component'
import { SettingsHeader } from '../SettingsHeader.component'
import { SettingsContainer } from '../SettingsContainer.component'
import { FEATURE_NAMES } from '../../../../../split-io/feature-names'
import { BrandedInsertsSetting } from './BrandedInsertsSetting.component'

export function SettingsBranding() {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  return (
    <SettingsContainer>
      <SettingsHeader />

      <SettingsPanel
        title={<h2>Inserts</h2>}
        subtitle={
          <div className="mt-4 max-w-lg text-gray-600">
            <p>
              Stickers, postcards &amp; flyers to be included in your orders. Ideal for brand promotion, thank you notes
              or additional marketing materials.
            </p>
            <p className="mt-2">Insert availability is dependent on fulfilment location</p>
          </div>
        }
      >
        {isBrandingSettingsOn && <BrandedInsertsSetting />}
      </SettingsPanel>
    </SettingsContainer>
  )
}
