// @flow
import React from 'react'
import type { Element } from 'react'
import type { Event } from '../../../../types'
import { BanIcon } from '@heroicons/react/solid'

// $FlowFixMe: TypeScript file
import Button from '../../../../v2/components/Button'
// $FlowFixMe: TypeScript file
import SavedItemsModalButton from '../../../../v2/components/SavedItemsModalButton'

type SearchActionsProps = {|
  onClickViewBasket: (e: Event) => void,
  onClickClearSearch: (e: Event) => void
|}

export default function SearchActions({ onClickClearSearch, onClickViewBasket }: SearchActionsProps): Element<'div'> {
  return (
    <div className="flex flex-wrap justify-end gap-1 self-end whitespace-nowrap lg:ml-auto">
      <SavedItemsModalButton />
      <Button
        dataTest="clear-search-button"
        variant="tertiary"
        theme="greyscale"
        startIcon={<BanIcon className="h-7 w-7 text-gray-400" />}
        onClick={onClickClearSearch}
      >
        Clear search
      </Button>
    </div>
  )
}
