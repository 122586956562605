import { useState } from 'react'
import toast from 'react-hot-toast'
import { SaveIcon } from '@heroicons/react/outline'

import Button from '../Button'
import { StatusEnum } from '../../enums'
import { createErrorToast } from '../Toast'
import { Mockups3DView } from './components'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { ImageMockupInterface, useMockups, useSplitToggle } from '../../hooks'
import { StatusType, DictionaryInterface, FetchErrorInterface } from '../../interfaces'
import { MockupPollCompleteInterface, MockupsPolling } from '../ImageEditor/components/MockupsPolling.component'

interface MockupViewInterface {
  attributes: DictionaryInterface<string>
  mockupGenerationStatus: StatusType
  mockupData: ImageMockupInterface | null
  orientation: string
  orientationSelectionAvailable: boolean
  productSku: string
  selectedMockupView: string | null
  transformationId: string | null
  onMockupsPollingComplete: (details: MockupPollCompleteInterface) => void
  onSelectMockupView: (view: string) => void
}

const MOCKUP_TOASTS = {
  ERROR: 'mockupErrorToast'
}

export default function MockupsView({
  attributes,
  mockupGenerationStatus,
  mockupData,
  orientation,
  orientationSelectionAvailable,
  productSku,
  selectedMockupView,
  transformationId,
  onMockupsPollingComplete,
  onSelectMockupView
}: MockupViewInterface) {
  const {
    isLoading,
    error: hasError,
    mockups,
    mockup
  } = useMockups({ attributes, productSku, orientation, orientationSelectionAvailable })

  const { splitIsOn: is3DMockupsSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_3D_MOCKUPS })

  const [downloadStatus, setDownloadStatus] = useState<StatusEnum>(StatusEnum.Idle)

  if (is3DMockupsSplitOn) {
    return (
      <Mockups3DView
        attributes={attributes}
        orientation={orientation}
        productSku={productSku}
        selectedMockupView={selectedMockupView}
        onSelectMockupView={onSelectMockupView}
      />
    )
  }

  if (mockupGenerationStatus === 'error' || hasError) {
    return null
  }

  if (isLoading || !mockups) {
    return (
      <div>
        <MockupsLoadingButton />
        <p className="font-normal text-gray-200">Our mockup files are png format, 1200x1200px</p>
      </div>
    )
  }

  if (!mockup) {
    return null
  }

  async function downloadMockup() {
    if (!mockupData || !mockupData.url) {
      return
    }

    window.analytics.track('Download mockup', productSku)
    toast.dismiss(MOCKUP_TOASTS.ERROR)
    setDownloadStatus(StatusEnum.Loading)

    try {
      await fetch(mockupData.url).then(async (response) => {
        if (!response.ok) {
          createErrorToast({
            errorCode: 'FDM' ?? '0',
            heading: 'Failed to download mockup',
            id: MOCKUP_TOASTS.ERROR
          })
          return
        }

        const blob = await response.blob()
        const linkElement = document.createElement('a')
        const blobUrl = URL.createObjectURL(blob)

        linkElement.style.display = 'none'
        linkElement.setAttribute('href', blobUrl)
        const fileName = 'prodigi-' + productSku + '.png'
        linkElement.setAttribute('download', fileName)
        document.body.appendChild(linkElement)

        linkElement.click()
        URL.revokeObjectURL(blobUrl)
        document.body.removeChild(linkElement)
        setDownloadStatus(StatusEnum.Success)
      })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>

      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.message,
        heading: 'Failed to download mockup',
        id: MOCKUP_TOASTS.ERROR
      })
      setDownloadStatus(StatusEnum.Error)
    }
  }

  return (
    <div>
      {transformationId && !mockupData && (
        <MockupsPolling
          productSku={productSku}
          transformationId={transformationId}
          mockup={mockup}
          onComplete={onMockupsPollingComplete}
        />
      )}

      <Button
        className="btn btn-primary my-12 w-full cursor-pointer transition-opacity duration-100"
        startIcon={<SaveIcon className="h-8 w-8" />}
        isLoading={!mockupData || downloadStatus === StatusEnum.Loading}
        disabled={!mockupData}
        onClick={downloadMockup}
      >
        Download full-size mockup
      </Button>
      <p className="font-normal text-gray-200">Our mockup files are png format, 1200x1200px</p>
    </div>
  )
}

function MockupsLoadingButton() {
  return (
    <Button
      className="btn btn-primary my-12 w-full cursor-pointer transition-opacity duration-100"
      disabled={true}
      isLoading={true}
      startIcon={<SaveIcon className="h-8 w-8" />}
    >
      Download full-size mockup
    </Button>
  )
}
