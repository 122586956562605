import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import { formatCost } from '../../../helpers'
import { ProductCostedAttributeInterface } from '../../../interfaces'

export function CostedAttributes({
  selectedAttributes,
  selectableCostedAttributes,
  sku,
  onChangeCostedAttribute
}: {
  selectedAttributes: Record<string, string>
  selectableCostedAttributes: [string, ProductCostedAttributeInterface | undefined][]
  sku: string
  onChangeCostedAttribute: (attributeData: { costedAttributeKey: string; costedAttributeValue: string }) => void
}) {
  return (
    <>
      {selectableCostedAttributes.map(([costedAttributeKey, costedAttributeData]) => {
        if (!costedAttributeData) {
          return null
        }

        return (
          <FormItem
            labelText={costedAttributeData.name}
            key={`${sku}-${costedAttributeKey}`}
            inputField={
              <SelectField
                dataTest={`attribute-select-${costedAttributeKey}`}
                value={selectedAttributes[costedAttributeKey] ?? ''}
                onChange={(event) =>
                  onChangeCostedAttribute({ costedAttributeKey, costedAttributeValue: event.target.value })
                }
              >
                <option disabled value="">
                  Select {costedAttributeData.name}
                </option>

                {costedAttributeData.options.map((costedAttributeOption) => {
                  const price =
                    costedAttributeOption.price.amount > 0
                      ? formatCost({
                          amount: costedAttributeOption.price.amount.toString(),
                          currencyCode: costedAttributeOption.price.currency
                        })
                      : null

                  return (
                    <option key={costedAttributeOption.value} value={costedAttributeOption.value}>
                      {price ? `${costedAttributeOption.name} (${price})` : costedAttributeOption.name}
                    </option>
                  )
                })}
              </SelectField>
            }
          />
        )
      })}
    </>
  )
}
