import { useState } from 'react'

import Button from '../../../Button'
import { BrandedInsertsModal } from '../../../BrandedInsertsModal'
import { InsertSetSettingsModal } from './InsertSetSettingsModal.component'
import { BrandedInsertsOrderModal } from '../../../BrandedInsertsOrderModal'

const DUMMY_BRANDED_INSERTS = {
  circularPackagingSticker: {
    title: 'Circular packaging sticker',
    description: 'Placed on the outside of the packaging. On tubes, this sticker is positioned on the end cap.',
    subText: '2.5x2.5" / 65x65mm',
    price: '£1.50',
    imageUrl: 'https://s3.amazonaws.com/kiteshopify/a75de0d2-57d8-4db6-b5cd-ad39df67e1ff.png'
  },
  rectangularPackagingSticker: {
    title: 'Rectangular packaging sticker',
    description: 'Placed on the outside of the packaging, close to the shipping label.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test1: {
    title: 'Test 1 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: 'https://s3.amazonaws.com/kiteshopify/a75de0d2-57d8-4db6-b5cd-ad39df67e1ff.png'
  },
  test2: {
    title: 'Test 2 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test3: {
    title: 'Test 3 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test4: {
    title: 'Test 4 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  }
}

const DUMMY_BRANDED_INSERTS_NO_IMAGE = {
  circularPackagingSticker: {
    title: 'Circular packaging sticker',
    description: 'Placed on the outside of the packaging. On tubes, this sticker is positioned on the end cap.',
    subText: '2.5x2.5" / 65x65mm',
    price: '£1.50',
    imageUrl: undefined
  },
  rectangularPackagingSticker: {
    title: 'Rectangular packaging sticker',
    description: 'Placed on the outside of the packaging, close to the shipping label.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test1: {
    title: 'Test 1 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test2: {
    title: 'Test 2 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test3: {
    title: 'Test 3 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  },
  test4: {
    title: 'Test 4 title',
    description: 'Test description.',
    subText: '4x3" / 102x76mm',
    price: '£1.50',
    imageUrl: undefined
  }
}

export function BrandedInsertsSetting() {
  const [isBrandedInsertsModalOpen, setIsBrandedInsertsModalOpen] = useState(false)

  return (
    <div>
      <ul className="flex flex-col gap-16">
        <InsertSet />
        <InsertSet />
      </ul>

      <div className="mt-16">
        <Button onClick={() => setIsBrandedInsertsModalOpen(true)}>Add inserts</Button>

        <BrandedInsertsModal
          inserts={DUMMY_BRANDED_INSERTS}
          open={isBrandedInsertsModalOpen}
          setOpen={setIsBrandedInsertsModalOpen}
          onSave={console.log}
          onCancel={() => setIsBrandedInsertsModalOpen(false)}
        />
      </div>

      <hr className="my-16" />

      <div className="">
        <TestOrderUI />
      </div>
      <div className="mt-16">
        <TestOrderSetsUI />
      </div>
    </div>
  )
}

function InsertSet() {
  const [isBrandedInsertsModalOpen, setIsBrandedInsertsModalOpen] = useState(false)
  const [isInsertSetSettingsModalOpen, setIsInsertSetSettingsModalOpen] = useState(false)

  return (
    <li className="flex flex-col text-black">
      <div className="text-lg font-medium">
        Insert set A <span className="font-normal">(£2.50)</span>
      </div>

      <div className="mt-2">Rectangular packaging sticker, postcard, flyer</div>
      <div className="text-sm text-gray-600">Default for: Test shop (Shopify), Manual orders, API</div>

      <div className="mt-4 flex gap-3">
        <Button variant="secondary" size="sm" onClick={() => setIsBrandedInsertsModalOpen(true)}>
          Edit inserts
        </Button>

        <Button variant="secondary" size="sm" onClick={() => setIsInsertSetSettingsModalOpen(true)}>
          Settings
        </Button>
      </div>

      <BrandedInsertsModal
        inserts={DUMMY_BRANDED_INSERTS}
        open={isBrandedInsertsModalOpen}
        setOpen={setIsBrandedInsertsModalOpen}
        onSave={console.log}
        onCancel={() => setIsBrandedInsertsModalOpen(false)}
      />

      <InsertSetSettingsModal
        open={isInsertSetSettingsModalOpen}
        setOpen={setIsInsertSetSettingsModalOpen}
        onClose={() => setIsInsertSetSettingsModalOpen(false)}
      />
    </li>
  )
}

function TestOrderUI() {
  const [isOrderInsertsModalOpen, setIsOrderInsertsModalOpen] = useState(false)
  const [isOrderInsertsNoImageModalOpen, setIsOrderInsertsNoImageModalOpen] = useState(false)

  return (
    <div className="flex w-fit flex-col gap-4">
      <Button variant="secondary" onClick={() => setIsOrderInsertsModalOpen(true)}>
        Has images, no sets
      </Button>
      <Button variant="secondary" onClick={() => setIsOrderInsertsNoImageModalOpen(true)}>
        No images, no sets
      </Button>

      <BrandedInsertsOrderModal
        inserts={DUMMY_BRANDED_INSERTS}
        open={isOrderInsertsModalOpen}
        setOpen={setIsOrderInsertsModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsModalOpen(false)}
      />

      <BrandedInsertsOrderModal
        inserts={DUMMY_BRANDED_INSERTS_NO_IMAGE}
        open={isOrderInsertsNoImageModalOpen}
        setOpen={setIsOrderInsertsNoImageModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsNoImageModalOpen(false)}
      />
    </div>
  )
}

const DUMMY_SETS = {
  'Test set 1': {
    circularPackagingSticker: {
      title: 'Circular packaging sticker',
      description: 'Placed on the outside of the packaging. On tubes, this sticker is positioned on the end cap.',
      subText: '2.5x2.5" / 65x65mm',
      price: '£1.50',
      imageUrl: 'https://s3.amazonaws.com/kiteshopify/35a23553-d463-41de-8838-7e20e9aaa643.jpeg'
    },
    rectangularPackagingSticker: {
      title: 'Rectangular packaging sticker',
      description: 'Placed on the outside of the packaging, close to the shipping label.',
      subText: '4x3" / 102x76mm',
      price: '£1.50',
      imageUrl: 'https://s3.amazonaws.com/kiteshopify/35a23553-d463-41de-8838-7e20e9aaa643.jpeg'
    }
  },
  'Test set 2': {
    circularPackagingSticker: {
      title: 'Circular packaging sticker',
      description: 'Placed on the outside of the packaging. On tubes, this sticker is positioned on the end cap.',
      subText: '2.5x2.5" / 65x65mm',
      price: '£1.50',
      imageUrl: 'https://s3.amazonaws.com/kiteshopify/a75de0d2-57d8-4db6-b5cd-ad39df67e1ff.png'
    },
    rectangularPackagingSticker: {
      title: 'Rectangular packaging sticker',
      description: 'Placed on the outside of the packaging, close to the shipping label.',
      subText: '4x3" / 102x76mm',
      price: '£1.50',
      imageUrl: 'https://s3.amazonaws.com/kiteshopify/a75de0d2-57d8-4db6-b5cd-ad39df67e1ff.png'
    }
  }
}

function TestOrderSetsUI() {
  const [isOrderInsertsModalOpen, setIsOrderInsertsModalOpen] = useState(false)
  const [isOrderInsertsNoImageModalOpen, setIsOrderInsertsNoImageModalOpen] = useState(false)

  return (
    <div className="flex w-fit flex-col gap-4">
      <Button variant="secondary" onClick={() => setIsOrderInsertsModalOpen(true)}>
        Has images, has sets
      </Button>
      <Button variant="secondary" onClick={() => setIsOrderInsertsNoImageModalOpen(true)}>
        No images, has sets
      </Button>

      <BrandedInsertsOrderModal
        inserts={DUMMY_BRANDED_INSERTS}
        insertSets={DUMMY_SETS}
        open={isOrderInsertsModalOpen}
        setOpen={setIsOrderInsertsModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsModalOpen(false)}
      />

      <BrandedInsertsOrderModal
        inserts={DUMMY_BRANDED_INSERTS_NO_IMAGE}
        insertSets={DUMMY_SETS}
        open={isOrderInsertsNoImageModalOpen}
        setOpen={setIsOrderInsertsNoImageModalOpen}
        onSave={console.log}
        onClose={() => setIsOrderInsertsNoImageModalOpen(false)}
      />
    </div>
  )
}
