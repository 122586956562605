import { motion } from 'framer-motion'
import clsx from 'clsx'
import { Link, useRouteMatch } from 'react-router-dom'

import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { useSplitToggle } from '../../../hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

export function SettingsHeader() {
  return (
    <header>
      <h1>Settings</h1>
      <nav className="relative flex max-w-full gap-6 overflow-x-auto pt-8">
        <SettingsLinks />
      </nav>
    </header>
  )
}

const SETTINGS_NAV_ITEMS = [
  {
    name: 'Account',
    path: ROUTE_PATHS.SETTINGS.ACCOUNT
  },
  {
    name: 'Preferences',
    path: ROUTE_PATHS.SETTINGS.PREFERENCES
  },
  {
    name: 'Billing',
    path: ROUTE_PATHS.SETTINGS.BILLING
  },
  {
    name: 'API',
    path: ROUTE_PATHS.SETTINGS.API
  }
]

function SettingsLinks() {
  const routeMatch = useRouteMatch()
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })
  const isSandboxEnvironment = process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX

  if (isSandboxEnvironment) {
    return null
  }

  let settingsNavItemsToUse = SETTINGS_NAV_ITEMS
  if (isBrandingSettingsOn) {
    settingsNavItemsToUse = [
      {
        name: 'Account',
        path: ROUTE_PATHS.SETTINGS.ACCOUNT
      },
      {
        name: 'Preferences',
        path: ROUTE_PATHS.SETTINGS.PREFERENCES
      },
      {
        name: 'Billing',
        path: ROUTE_PATHS.SETTINGS.BILLING
      },
      {
        name: 'Branding',
        path: ROUTE_PATHS.SETTINGS.BRANDING
      },
      {
        name: 'API',
        path: ROUTE_PATHS.SETTINGS.API
      }
    ]
  }

  return (
    <>
      {settingsNavItemsToUse.map((settingsNavItem) => (
        <Link
          className={clsx(
            'relative px-6 pb-6 font-medium',
            routeMatch.path === settingsNavItem.path && 'text-purple-500'
          )}
          key={settingsNavItem.path}
          to={settingsNavItem.path}
        >
          {settingsNavItem.name}
          <div>
            {routeMatch.path === settingsNavItem.path && (
              <motion.div
                layoutId="activeSettingsLink"
                className="absolute bottom-0 left-0 right-0 h-[4px] bg-purple-200"
              />
            )}
          </div>
        </Link>
      ))}
    </>
  )
}
