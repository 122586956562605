import { useEffect } from 'react'
import { motion } from 'framer-motion'
import { BookOpenIcon, DownloadIcon, InformationCircleIcon } from '@heroicons/react/outline'

import { TileComponent, ProductInfoHeader } from './components'
import DownloadPricing from '../DownloadPricing'

export default function ProductInfo() {
  useEffect(() => {
    window.analytics.page('Product Information')
  }, [])

  return (
    <motion.div animate={{ opacity: 1 }} className="container mx-auto gap-y-4" initial={{ opacity: 0 }}>
      <ProductInfoHeader />

      <div className="min-w-full pt-6">
        <DownloadPricing />
      </div>

      <div className="grid min-h-[300px] grid-cols-1 grid-rows-3 gap-10 pt-8 md:gap-12 lg:grid-cols-3 lg:grid-rows-1">
        <TileComponent
          heading="Product portfolio"
          content="Our complete current catalogue, presented in a single convenient pdf file."
          icon={<BookOpenIcon className="h-20 w-20 rounded-full bg-cyan-100 p-6 text-gray-800" />}
          linkAddress="https://www.prodigi.com/download/product-range/prodigi-portfolio.pdf"
          linkText="Download pdf"
        />
        <TileComponent
          heading="Downloads"
          content="Product photography, datasheets, artwork preparation files and user guides - all free to download and use."
          icon={<DownloadIcon className="h-20 w-20 rounded-full bg-teal-100 p-6 text-gray-800" />}
          linkAddress="https://www.prodigi.com/downloads/"
          linkText="Free assets"
        />
        <TileComponent
          heading="More information"
          content="Visit our dedicated product pages, for detailed descriptions, photography, eco properties and packaging information."
          icon={<InformationCircleIcon className="h-20 w-20 rounded-full bg-magenta-100 p-6 text-gray-800" />}
          linkAddress="https://www.prodigi.com/products/"
          linkText="Browse our products"
        />
      </div>
    </motion.div>
  )
}
