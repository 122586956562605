// @flow
import type {
  RsaaStatus,
  Match,
  CustomerAddress,
  MultiAssetBasketItem,
  InProgressOrder,
  Dictionary,
  Status,
  Quote,
  MultiAssetTemplates,
  CatalogueItem,
  RsaaStatusProps,
  V4ProductDetails
} from '../../../types'
import type { RouterHistory } from 'react-router'
import React, { PureComponent } from 'react'
import * as ct from '../../../data/constants'
import instruments from '../../../helpers/instruments'
import { SAMPLE_PACKS, SAMPLE_PACKS_SHOPIFY } from '../../../data/noImageProducts'
import OrderSummaryLayout from './OrderSummaryLayout'
import { entries } from '../../../helpers/dictionary'
import { setDefaultPrintAreaEntryAsFirst } from '../../../helpers/setDefaultPrintAreaEntryAsFirst'
import { removeUnfinishedOrderFromLocalStorage } from '../../../actions/manualOrderForm'

// $FlowFixMe: Typescript file
import { QUERY_PARAMS } from '../../../v2/constants'

export type Props = {|
  merchantId: string,
  orderId: string,
  selectedShippingMethodName: ?string,
  quotes: Quote[],
  quotesError: boolean,
  quotesLoading: boolean,
  quotesMsg: string,
  quotesSuccess: boolean,
  inProgressOrder: InProgressOrder,
  hasToSetUpCard: boolean,
  addressIsValid: boolean,
  currencyCode: ?string,
  customer: CustomerAddress,
  deliveryCountry: string,
  errorMessage: string,
  itemsToBeCreated: MultiAssetBasketItem[],
  catalogueItems: Dictionary<CatalogueItem>,
  match: Match,
  history: RouterHistory,
  isTransformUrlsOn: boolean,
  merchantOrderId: string,
  orderError: boolean,
  tag: string,
  changeMerchantOrderId: (merchantOrderId: string) => void,
  clearOrderData: () => void,
  resetOrderCreationStatus: () => void,
  updatePriceToUser: (priceToUserAsInt: number, basketItemId: number) => void,
  changeSelectedPrintAreaForBasketItem: (basketItemId: string, selectedPrintArea: string) => void,
  createV4Order: (isTransformUrlsOn?: boolean) => void,
  getMofBasketQuotes: () => void,
  location: Location,
  packingSlipUrl: ?string,
  orderCreationStatus: RsaaStatus,
  catalogueDataRsaaStatus: RsaaStatusProps,
  imageStatus: Dictionary<Status>,
  templateStatus: Dictionary<Status>,
  templates: Dictionary<MultiAssetTemplates>,
  v4ProductDetails: Dictionary<V4ProductDetails>
|}

type State = {|
  uploadPackingSlipStatus: 'idle' | 'loading' | 'success' | 'error'
|}

export default class OrderSummaryController extends PureComponent<Props, State> {
  state: State = {
    uploadPackingSlipStatus: 'idle'
  }

  componentDidMount() {
    window.analytics.page('MOF', 'Summary')
    this.props.getMofBasketQuotes()
    this.selectPrintAreasWithArtworks()
  }

  selectPrintAreasWithArtworks: () => void = () => {
    this.props.itemsToBeCreated.forEach((item) => {
      if (!item.printAreas) {
        console.error(`Print areas not found, item ${item.id} - ${item.sku}`)
        return
      }
      const orderedPrintAreaEntries = setDefaultPrintAreaEntryAsFirst(entries(item.printAreas))
      const firstPrintAreaEntryWithArtwork = orderedPrintAreaEntries.find(([printAreaName, printAreaArtworkData]) =>
        Boolean(printAreaArtworkData.artwork)
      )
      if (firstPrintAreaEntryWithArtwork) {
        const firstPrintAreaNameWithArtwork = firstPrintAreaEntryWithArtwork[0]
        this.props.changeSelectedPrintAreaForBasketItem(item.id.toString(), firstPrintAreaNameWithArtwork)
      }
    })
  }

  tooManyItems: () => boolean = (): boolean => {
    const items = this.props.itemsToBeCreated
    const { catalogueItems } = this.props
    let amountofSamplePacks = 0
    let amountofSamplePacksShopify = 0

    items.forEach((item) => {
      const catalogueItem = catalogueItems[item.sku]
      if (catalogueItem.category === SAMPLE_PACKS) {
        amountofSamplePacks = amountofSamplePacks + item.quantity
      }
      if (catalogueItem.category === SAMPLE_PACKS_SHOPIFY) {
        amountofSamplePacksShopify = amountofSamplePacksShopify + item.quantity
      }
    })

    return amountofSamplePacks > 1 || amountofSamplePacksShopify > 1
  }

  createOrder: () => void = () => {
    removeUnfinishedOrderFromLocalStorage()
    this.props.createV4Order(this.props.isTransformUrlsOn)
    this.props.clearOrderData()
    instruments.timer(ct.ORDER_TIMER_NAME).complete()
  }

  viewCreatedOrder: () => void = () => {
    this.props.clearOrderData()
    this.props.resetOrderCreationStatus()
    this.props.history.push(`/orders/${this.props.orderId}/detail?${QUERY_PARAMS.ORDER_DETAIL.SUCCESS}=true`)
  }

  placeAnotherOrder: () => void = () => {
    this.props.clearOrderData()
    this.props.resetOrderCreationStatus()
    this.props.history.push('/orders/create/select-country')
  }

  navigateToBasket: () => void = () => {
    this.props.resetOrderCreationStatus()
    this.props.history.replace('/orders/create/add-image')
  }

  setUpdatePackingSlipStatus: (status: 'idle' | 'loading' | 'success' | 'error') => void = (
    status: 'idle' | 'loading' | 'success' | 'error'
  ) => {
    this.setState({
      uploadPackingSlipStatus: status
    })
  }

  get disableOrderSubmission(): boolean {
    const tooManyItems = this.tooManyItems()

    return (
      !this.hasQuotes ||
      this.props.quotesLoading ||
      this.props.hasToSetUpCard ||
      this.props.itemsToBeCreated.length === 0 ||
      this.state.uploadPackingSlipStatus === 'loading' ||
      tooManyItems
    )
  }

  get isPriceAndShippingLoading(): boolean {
    const hasRequestedQuotes = Boolean(this.props.quotesLoading || this.props.quotesError || this.props.quotesSuccess)

    return !hasRequestedQuotes || this.props.quotesLoading
  }

  get hasPricingAndShippingError(): boolean {
    return Boolean(this.props.quotesError || (this.props.quotesSuccess && !this.hasQuotes))
  }

  get selectedQuote(): ?Quote {
    return this.props.quotes.find((quote) => quote.shipmentMethod === this.props.selectedShippingMethodName)
  }

  get hasQuotes(): boolean {
    return Boolean(this.props.quotesSuccess && this.selectedQuote)
  }

  render(): React$Node {
    return (
      <OrderSummaryLayout
        orderId={this.props.orderId}
        hasQuotes={this.hasQuotes}
        selectedQuote={this.selectedQuote}
        updatePriceToUser={this.props.updatePriceToUser}
        isPriceAndShippingLoading={this.isPriceAndShippingLoading}
        hasPricingAndShippingError={this.hasPricingAndShippingError}
        updatePackingSlipStatus={this.state.uploadPackingSlipStatus}
        setUpdatePackingSlipStatus={this.setUpdatePackingSlipStatus}
        orderError={this.props.orderError}
        errorMessage={this.props.errorMessage}
        match={this.props.match}
        clearOrderData={this.props.clearOrderData}
        basketItems={this.props.itemsToBeCreated}
        catalogueItems={this.props.catalogueItems}
        catalogueDataRsaaStatus={this.props.catalogueDataRsaaStatus}
        customer={this.props.customer}
        changeSelectedPrintAreaForBasketItem={this.props.changeSelectedPrintAreaForBasketItem}
        disableOrderSubmission={this.disableOrderSubmission}
        hasToSetUpCard={this.props.hasToSetUpCard}
        addressIsValid={this.props.addressIsValid}
        deliveryCountry={this.props.deliveryCountry}
        merchantOrderId={this.props.merchantOrderId}
        changeMerchantOrderId={this.props.changeMerchantOrderId}
        currencyCode={this.props.currencyCode}
        createOrder={this.createOrder}
        pathname={location.pathname}
        imageStatus={this.props.imageStatus}
        templateStatus={this.props.templateStatus}
        templates={this.props.templates}
        orderCreationStatus={this.props.orderCreationStatus}
        navigateToBasket={this.navigateToBasket}
        resetOrderCreationStatus={this.props.resetOrderCreationStatus}
        viewCreatedOrder={this.viewCreatedOrder}
        placeAnotherOrder={this.placeAnotherOrder}
        v4ProductDetails={this.props.v4ProductDetails}
      />
    )
  }
}
