import { Link } from 'react-router-dom'
import { ViewGridIcon, OfficeBuildingIcon, PhotographIcon } from '@heroicons/react/outline'

import { useSplitToggle } from '../../../hooks'
import { NavGroupTitle } from './NavGroupTitle.component'
import { FEATURE_NAMES } from 'src/split-io/feature-names'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupCatalogue({ className }: { className?: string }) {
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })
  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return null
  }

  return (
    <div className={className}>
      <NavGroupTitle>Catalogue</NavGroupTitle>

      <NavGroupListContainer>
        {!isShopifySplitOn && (
          <li>
            <Link
              to={isAPCSplitOn ? ROUTE_PATHS.PRODUCT_CATALOGUE : ROUTE_PATHS.PRODUCT_INFO}
              className="side-nav-item"
            >
              <ViewGridIcon className="h-7 w-7" />
              Browse products
            </Link>
          </li>
        )}

        <li>
          <Link to={ROUTE_PATHS.SALES_CHANNELS.INDEX} className="side-nav-item">
            <OfficeBuildingIcon className="h-7 w-7" />
            Sales channels
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.IMAGE_LIBRARY.INDEX} className="side-nav-item">
            <PhotographIcon className="h-7 w-7" />
            Image library
          </Link>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
