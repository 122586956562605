import { SearchIcon } from '@heroicons/react/solid'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

import Button from '../../Button'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'

export function VariantDataError({ errorCode, onChooseProduct }: { errorCode: string; onChooseProduct: () => void }) {
  return (
    <div className="p-8">
      <div className="ml-4 flex flex-col items-center gap-4 md:flex-row md:items-start md:pl-[36px]">
        <div className="grid h-60 w-60 place-content-center bg-gray-200">
          <ExclamationCircleIcon className="h-10 w-10 text-gray-600" />
        </div>
        <div>
          <div className="max-w-[60ch] break-words text-red-500" style={{ wordBreak: 'break-word' }}>
            An error occurred while fetching product info, please try again later or{' '}
            <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists. Code:{' '}
            {errorCode} <ClipboardCopy className="pt-1 align-top" text={errorCode} showText={false} />
          </div>

          <Button
            className="mt-6"
            size="sm"
            startIcon={<SearchIcon className="h-7 w-7" />}
            variant="secondary"
            onClick={onChooseProduct}
          >
            Choose product
          </Button>
        </div>
      </div>
    </div>
  )
}
