function GlobalProductIcon({ className = '' }: { className?: string }) {
  return (
    <svg
      className={className}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 14C9.0913 14 10.6174 13.3679 11.7426 12.2426C12.8679 11.1174 13.5 9.5913 13.5 8C13.5 6.4087 12.8679 4.88258 11.7426 3.75736C10.6174 2.63214 9.0913 2 7.5 2C5.9087 2 4.38258 2.63214 3.25736 3.75736C2.13214 4.88258 1.5 6.4087 1.5 8C1.5 9.5913 2.13214 11.1174 3.25736 12.2426C4.38258 13.3679 5.9087 14 7.5 14ZM3.249 6.52025C3.52747 5.72279 4.02434 5.01958 4.683 4.49075C4.884 4.7975 5.2305 5 5.625 5C5.92337 5 6.20952 5.11853 6.4205 5.3295C6.63147 5.54048 6.75 5.82663 6.75 6.125V6.5C6.75 6.89783 6.90804 7.27936 7.18934 7.56066C7.47064 7.84196 7.85218 8 8.25 8C8.64783 8 9.02936 7.84196 9.31066 7.56066C9.59196 7.27936 9.75 6.89783 9.75 6.5C9.74988 6.16432 9.86236 5.8383 10.0694 5.5741C10.2765 5.30991 10.5663 5.12281 10.8923 5.04275C11.608 5.8615 12.0017 6.91252 12 8C12 8.255 11.979 8.50625 11.9378 8.75H11.25C10.8522 8.75 10.4706 8.90804 10.1893 9.18934C9.90804 9.47065 9.75 9.85218 9.75 10.25V11.8978C9.06622 12.2934 8.28998 12.5012 7.5 12.5V11C7.5 10.6022 7.34196 10.2206 7.06066 9.93934C6.77936 9.65804 6.39783 9.5 6 9.5C5.60218 9.5 5.22064 9.34197 4.93934 9.06066C4.65804 8.77936 4.5 8.39783 4.5 8C4.50013 7.64525 4.37453 7.30193 4.1455 7.03102C3.91647 6.76011 3.59883 6.57914 3.249 6.52025Z"
        fill="#1D7737"
      />
    </svg>
  )
}

export { GlobalProductIcon }
