// @flow
import { WOOCOMMERCE_NAME_HEADERS } from '.'

export const ADDRESS_1 = 'address1'
export const ADDRESS_2 = 'address2'

export const FIRST_PART_OF_ATTRIBUTE = 'attribute-'
export const ATTRIBUTE_WRAP = 'attribute-wrap'
export const ATTRIBUTE_SIZE = 'attribute-size'
export const ATTRIBUTE_FRAME_COLOR = 'attribute-frameColour'
export const ATTRIBUTE_MOUNT_COLOR = 'attribute-mountColour'
export const DEFAULT_ATTRIBUTE_NAMES = [ATTRIBUTE_WRAP, ATTRIBUTE_SIZE, ATTRIBUTE_FRAME_COLOR, ATTRIBUTE_MOUNT_COLOR]

export const DESCRIPTION = 'description'

export const MAPPED_CSV_HEADERS: {|
  address1: Array<string>,
  address2: Array<string>,
  "attribute-brand": Array<string>,
  "attribute-edge": Array<string>,
  "attribute-finish": Array<string>,
  "attribute-frame": Array<string>,
  "attribute-frameColour": Array<string>,
  "attribute-glaze": Array<string>,
  "attribute-mountColour": Array<string>,
  "attribute-size": Array<string>,
  "attribute-style": Array<string>,
  "attribute-substrateWeight": Array<string>,
  "attribute-wrap": Array<string>,
  countryCode: Array<string>,
  county: Array<string>,
  customsValue: Array<string>,
  description: Array<string>,
  email: Array<string>,
  merchantOrderId: Array<string>,
  mobileTelephone: Array<string>,
  name: Array<string>,
  packingSlip: Array<string>,
  postcode: Array<string>,
  preferredShippingMethod: Array<string>,
  quantity: Array<string>,
  sizing: Array<string>,
  sku: Array<string>,
  town: Array<string>,
  unknownPrintAreaImageUrl: Array<string>,
|} = {
  'merchantOrderId': ['Order ID', 'Name', 'Order no.'],
  'name': ['Recipient Name', 'Delivery Name', 'Shipping Name', 'Full Name', ...WOOCOMMERCE_NAME_HEADERS, 'Recipient name'],
  [ADDRESS_1]: ['Address 1', 'Delivery Address1', 'Shipping Address1', 'Street 1', 'Address 1&2 (Shipping)', 'Delivery address'],
  [ADDRESS_2]: ['Address 2', 'Delivery Address2', 'Shipping Address2', 'Street 2'],
  'town': ['Town', 'Shipping City', 'Delivery City', 'Ship City', 'City (Shipping)', 'Delivery city', 'City'],
  'county': ['State/County', 'Delivery State', 'Shipping Province', 'Ship State', 'State Code (Shipping)', 'Delivery state', 'State'],
  'countryCode': ['Country Code', 'Delivery Country', 'Shipping Country', 'Ship Country', 'Country Code (Shipping)', 'Delivery country'],
  'postcode': ['Postcode', 'Delivery Zipcode', 'Shipping Zip', 'Ship Zipcode', 'Postcode (Shipping)', 'Delivery ZIP/postal code'],
  'preferredShippingMethod': ['Shipping', 'Order Delivery', 'Shipping Method', 'Shipping Method Title', 'Delivery method'],
  'sku': ['Sku', 'Lineitem sku', 'SKU'],
  'quantity': ['Quantity', 'Lineitem quantity', 'Number of Items', 'Qty'],
  [ATTRIBUTE_WRAP]: ['attribute-wrap', 'Wrap'],
  [ATTRIBUTE_SIZE]: ['attribute-size', 'Size'],
  [ATTRIBUTE_FRAME_COLOR]: ['attribute-framecolour', 'attribute-colour', 'Frame', 'Color'],
  [ATTRIBUTE_MOUNT_COLOR]: ['attribute-mountcolour', 'Mount'],
  'attribute-finish': ['attribute-finish'],
  'attribute-glaze': ['attribute-coating', 'attribute-glaze'],
  'attribute-frame': ['attribute-frame'],
  'attribute-brand': ['attribute-brand'],
  'attribute-style': ['attribute-style'],
  'attribute-edge': ['attribute-edge'],
  'attribute-substrateWeight': ['attribute-substrateweight'],
  'unknownPrintAreaImageUrl': ['fileurl', 'Image URL'],
  [DESCRIPTION]: ['Item Name', 'Lineitem Name', 'Variations', 'Product Name', 'Lineitem variant', 'Lineitem name'],
  'email': ['RecipientEmail', 'Email', 'Email (Billing)', 'Contact email'],
  'mobileTelephone': ['RecipientPhone', 'Shipping Phone', 'Phone (Billing)', 'Recipient phone no.'],
  'customsValue': ['CustomsValue', 'Order Value', 'Total', 'Order Total Amount'],
  'packingSlip': ['Packing Slip', 'Shipping label'],
  'sizing': ['sizing']
}
