// @flow
import React from 'react'

import Heading from '../Heading'
import styles from './CsvOrderDetails.module.css'
import TextField from '../../../../components/inputs/TextField'
import NumberField from '../../../../components/inputs/NumberField'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'
import type { FormattedCsvRow } from '../../../../types'

// $FlowFixMe: TypeScript component
import { CsvPackingSlipUploadV2 } from '../../../../components/CsvPackingSlipUpload/CsvPackingSlipUploadV2.component'

type Props = {|
  order: FormattedCsvRow,
  canBeEdited: boolean,
  isFetchingShipping: boolean,
  canDisplayTotal: boolean,
  formattedTotal: string,
  currencySymbol: ?string,
  customsValueAsFloat: number,
  packingSlip: string,
  isUsSalesTaxAlreadyCollected: boolean,
  toggleUsSalesTax: () => void,
  onCustomsValueChange: (customsValue: number) => void,
  updateMerchantOrderId: (index: string, orderId: string) => void
|}

export default function CsvOrderDetailsView(props: Props): React$Node {
  const { order, canBeEdited, canDisplayTotal } = props
  const isOrderForUnitedStates = order.customer.countryCode === 'US'

  return (
    <div className={styles.orderId}>
      <Heading>Your Order ID</Heading>
      <TextField
        disabled={!canBeEdited}
        className={styles.merchantOrderId}
        onChange={(e) => props.updateMerchantOrderId(order.id, e.target.value)}
        type="text"
        id={order.id + 'merchantOrderId'}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        tabIndex="1"
        dataTest="order-id"
        value={order.merchantOrderId}
      />

      <CsvPackingSlipUploadV2 orderId={order.id} />

      <div className={styles.customs}>
        <Heading className={styles.customsHeading}>
          Order value ({props.currencySymbol})<span className={styles.customsExplanation}>For customs use only</span>
        </Heading>
        <NumberField
          disabled={!canBeEdited}
          className={styles.customsValue}
          onChange={(e) => props.onCustomsValueChange(e.target.value)}
          id={order.id + 'customs'}
          step="0.01"
          min="0"
          tabIndex="1"
          defaultValue={props.customsValueAsFloat}
          dataTest="order-value"
        />
      </div>

      {isOrderForUnitedStates && (
        <div className={styles.salesTaxInfo}>
          <input
            id={`sales-tax-toggle-${order.id}`}
            data-test={`sales-tax-toggle-${order.id}`}
            type="checkbox"
            onChange={props.toggleUsSalesTax}
            className={styles.salesTaxToggle}
            checked={props.isUsSalesTaxAlreadyCollected}
          />
          <label htmlFor={`sales-tax-toggle-${order.id}`} className={styles.salesTaxLabel}>
            Sales tax already collected
          </label>
        </div>
      )}

      {
        <div className={styles.orderTotal}>
          {props.isFetchingShipping ? (
            <LoadingEllipsis />
          ) : (
            canDisplayTotal && (
              <span>
                <strong>Total cost</strong> {props.formattedTotal} + taxes
              </span>
            )
          )}
        </div>
      }
    </div>
  )
}
