import { PhotographIcon } from '@heroicons/react/solid'

import { AssetStatusEnum } from '../../../enums'
import { formatAssetStatusName } from '../helpers'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { formatCost, formatToSentenceCase } from '../../../helpers'
import { OrderDetailAttributesDisplay } from './OrderDetailAttributesDisplay.component'
import { OrderDetailItemInterface, OrderDetailItemAssetInterface } from '../../../interfaces'

interface OrderDetailShipmentDisplayItemPropsInterface {
  item: OrderDetailItemInterface
  itemCost: CostInterface | undefined
  totalCost: CostInterface | undefined
}

export function OrderDetailShipmentDisplayItem({
  item,
  itemCost,
  totalCost
}: OrderDetailShipmentDisplayItemPropsInterface) {
  const isMultiAssetProduct = item.assets.length > 1
  return (
    <tr
      className="grid grid-cols-[min-content_min-content_auto] sm:grid-cols-[max-content_min-content_min-content_auto] md:table-row"
      data-test={`shipment-item-${item.id}`}
    >
      <td className="col-span-3 row-span-5 m-2 mr-4 w-full align-top sm:col-span-1 sm:w-48 md:w-64 md:p-2 md:pb-12 lg:pr-6">
        <div className="flex flex-col gap-4">
          {item.assets.map((asset: OrderDetailItemAssetInterface, index: number) => (
            <div key={`item-image-${item.id}-${index}`}>
              <DisplayItemThumbnail asset={asset} isMultiAssetProduct={isMultiAssetProduct} />
            </div>
          ))}
        </div>
      </td>
      <td className="col-span-3 p-2 align-top sm:col-start-2 md:table-cell">
        <div className="font-medium" data-test="shipment-item__description">
          {item.prodigiSkuDescription}
        </div>
        <div className="mt-2 text-sm" data-test="shipment-item__sku">
          {item.prodigiSku}
        </div>
        <div className="whitespace-normal">
          <OrderDetailAttributesDisplay attributes={item.attributes} sku={item.prodigiSku} />
        </div>
      </td>
      <td
        className="col-start-1 whitespace-nowrap pt-2 pl-2 align-top text-sm sm:col-start-2 md:p-2 md:text-right md:text-base"
        data-test="shipment-item__cost"
      >
        {formatCost({
          amount: itemCost?.amount,
          currencyCode: itemCost?.currencyCode
        })}
      </td>
      <td
        className="col-start-2 whitespace-nowrap pt-2 align-top text-sm sm:col-start-3 md:p-2 md:text-right md:text-base"
        data-test="shipment-item__quantity"
      >
        <span className="px-2 md:hidden">x</span>
        {item.quantity}
        <span className="pl-2 md:hidden">item</span>
      </td>
      <td
        className="col-start-3 whitespace-nowrap pt-2 align-top text-sm sm:col-start-4 md:p-2 md:text-right md:text-base"
        data-test="shipment-item__total"
      >
        <span className="px-2 md:hidden">=</span>
        {formatCost({
          amount: totalCost?.amount,
          currencyCode: totalCost?.currencyCode
        })}
      </td>
    </tr>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

function DisplayItemThumbnail({
  asset,
  isMultiAssetProduct
}: {
  asset: OrderDetailItemAssetInterface
  isMultiAssetProduct: boolean
}) {
  if (!asset) return null

  if (asset.assetStatus === AssetStatusEnum.Success && isMultiAssetProduct) {
    return (
      <div className="flex flex-col items-center">
        <span className="mb-2 min-h-[22px] text-sm">{formatToSentenceCase(asset.printAreaName)}</span>
        <ItemThumbnail asset={asset} />
      </div>
    )
  }

  if (asset.assetStatus === AssetStatusEnum.Success && !isMultiAssetProduct) {
    return <ItemThumbnail asset={asset} />
  }

  let isError = false
  if (asset.assetStatus === AssetStatusEnum.Error || !asset.assetStatus) isError = true
  return (
    <div
      className="relative grid h-48 w-48 place-content-center bg-gray-100 text-sm text-gray-700 md:h-64 md:w-64"
      data-test="shipment-item__image-placeholder"
    >
      <PhotographIcon className="h-10 w-10 text-gray-400" />
      <span className={`absolute bottom-4 w-full text-center ${isError && 'text-red-800'}`}>
        {formatAssetStatusName(asset.assetStatus)}
      </span>
    </div>
  )
}

function ItemThumbnail({ asset }: { asset: OrderDetailItemAssetInterface }) {
  return (
    <img
      loading="lazy"
      src={asset.assetThumbnailUrl}
      alt={asset.printAreaName}
      className="h-48 w-48 object-contain object-top md:h-64 md:w-64"
      data-test="shipment-item__image"
    />
  )
}
