// @flow
import type { Node } from 'react'
import React from 'react'
import styles from './FileUpload.module.css'
import Dropzone from 'react-dropzone'
import FontAwesome from 'react-fontawesome'
import cx from 'classnames'
import type { DropEvent } from 'react-dropzone'

type DropzoneUploaderProps = {|
  uploadText: string,
  fileType: {
    [key: string]: string[]
  },
  multiple?: boolean,
  uploadFile: (e: DropEvent) => void,
  onUploadRejected: (event: File[]) => void
|}

export default function DropzoneUploader(props: DropzoneUploaderProps): Node {
  return (
    <Dropzone
      multiple={Boolean(props.multiple)}
      onDrop={props.uploadFile}
      onDropRejected={(fileRejection, event) => {
        const files = fileRejection.map((file) => file.file)
        props.onUploadRejected(files)
      }}
      accept={props.fileType}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div {...getRootProps()} className={cx(styles.container, { [styles.dropZoneActive]: isDragActive })}>
            <input {...getInputProps()} data-test="file-upload-input" />
            <FontAwesome name="cloud-upload" className={styles.uploadIcon} />
            <p className={styles.uploadText}>{props.uploadText}</p>
          </div>
        )
      }}
    </Dropzone>
  )
}
