// @flow
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import { getV4ProductDetailsBySku } from '../../selectors/catalogue'
import type { ThunkAsync, AppState, DispatchFunc } from '../../types'
import type { GetV4ProductDetailsSuccessResult } from '../types/catalogue'

export const GET_V4_PRODUCT_DETAILS = 'GET_V4_PRODUCT_DETAILS'
export const GET_V4_PRODUCT_DETAILS_SUCCESS = 'GET_V4_PRODUCT_DETAILS_SUCCESS'
export const GET_V4_PRODUCT_DETAILS_ERROR = 'GET_V4_PRODUCT_DETAILS_ERROR'

export function getV4ProductDetails (sku: string): ThunkAsync<*> {
  return async (dispatch: DispatchFunc): Promise<GetV4ProductDetailsSuccessResult> => {
    return dispatch({
      [RSAA]: {
        method: 'GET',
        endpoint: selectProdigiApiEndpoint() + '/v4.0/products/' + sku,
        bailout: shouldBailout(sku),
        types: [
          {
            type: GET_V4_PRODUCT_DETAILS,
            meta: {
              enhancements: [ LOG_STATUS ],
              loggerId: sku
            }
          },
          GET_V4_PRODUCT_DETAILS_SUCCESS,
          GET_V4_PRODUCT_DETAILS_ERROR
        ]
      }
    })
  }
}

function shouldBailout (sku: string) {
  return (state: AppState): boolean => {
    const productDetails = getV4ProductDetailsBySku(state, sku)
    const hasProductDetails = Boolean(productDetails)

    return hasProductDetails
  }
}
