import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { ChevronDownIcon, TagIcon, TrashIcon } from '@heroicons/react/outline'
import { PhotographIcon, TagIcon as SolidTagIcon } from '@heroicons/react/solid'

import { ImageLibraryImageInterface } from '../../../hooks'
import SingleSelectDropdown from '../../SingleSelectDropdown'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { AddTagsModal, DeleteImagesModal, RemoveTagsModal, UpdateTypeModal } from '.'

export type ManageActionsValueType = 'change-type' | 'add-tags' | 'remove-tags' | 'delete'

export function ManageActions({
  selectedImages,
  onSetImageLibraryMode
}: {
  selectedImages: ImageLibraryImageInterface[]
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
}) {
  const [actionModalToOpen, setActionModalToOpen] = useState<ManageActionsValueType | null>(null)
  const options = buildOptions(selectedImages)

  return (
    <>
      <span className="flex h-fit flex-grow items-center sm:flex-grow-0">
        <SingleSelectDropdown
          align="end"
          className="border-purple-500 disabled:pointer-events-none disabled:border-gray-600 disabled:opacity-50"
          disabled={selectedImages.length === 0}
          dropdownName="Actions"
          options={options}
          triggerContent={
            <div className={twMerge(selectedImages.length > 0 ? 'text-purple-500' : 'text-gray-600')}>
              <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                <ChevronDownIcon className="h-7 w-7" />
              </span>
              <span className="ml-8 select-none pr-2" data-test="singleselect-dropdown__button-text">
                Actions
              </span>
            </div>
          }
          updateSelected={(selected) => setActionModalToOpen(selected as ManageActionsValueType)}
        />
      </span>

      <UpdateTypeModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
      <AddTagsModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
      <RemoveTagsModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
      <DeleteImagesModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
    </>
  )
}

function buildOptions(selectedImages: ImageLibraryImageInterface[]) {
  const atLeastOneSelectedImagesHasATag = selectedImages.some((image) => image.tags.length > 0)

  return [
    {
      content: (
        <span className="relative mr-10 -mb-3 flex items-center">
          <PhotographIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
          <span>Change type</span>
        </span>
      ),
      searchLabel: 'Change type',
      value: 'change-type'
    },
    {
      content: (
        <span className="mr-10 -mb-3 flex items-center pr-10">
          <SolidTagIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
          <span>Add tags</span>
        </span>
      ),
      searchLabel: 'Add tags',
      value: 'add-tags'
    },
    {
      disabled: !atLeastOneSelectedImagesHasATag,
      content: (
        <span className="mr-10 -mb-3 flex items-center border-b border-gray-200 pr-10 pb-3">
          <TagIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
          <span>Remove tags</span>
        </span>
      ),
      searchLabel: 'Remove tags',
      value: 'remove-tags'
    },
    {
      disabled: false,
      content: (
        <span className="mr-10 flex items-center pr-10 text-magenta-800">
          <TrashIcon className="h-12 w-12 pr-4" aria-hidden="true" />
          Delete
        </span>
      ),
      searchLabel: 'Delete',
      value: 'delete'
    }
  ]
}
