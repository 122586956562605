import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { PauseIcon } from '@heroicons/react/outline'

import Button from '../../Button'
import { fetcher } from '../../../helpers'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { createErrorToast, createToast } from '../../Toast'
import { useOrderDetail, useOrdersOverview } from '../../../hooks'
import { ORDER_EDIT_WINDOW_TOASTS } from './OrderPausedWindow.component'
import { FetchErrorInterface, OrderDetailDataErrorInterface } from '../../../interfaces'

export function HoldOrderButton() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const { mutateOrdersOverview } = useOrdersOverview()

  const [orderHeldStatus, setOrderHeldStatus] = useState<StatusEnum>(StatusEnum.Idle)

  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  async function handleHoldIndefinitely() {
    toast.dismiss(ORDER_EDIT_WINDOW_TOASTS.ERROR)
    setOrderHeldStatus(StatusEnum.Loading)

    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/hold`, { method: 'PUT' })
      await mutateOrderDetails()
      mutateOrdersOverview()

      createToast({ heading: 'Order paused', type: 'success' })
      setOrderHeldStatus(StatusEnum.Success)
      window.analytics.track('Order pause override', { option: 'held indefinitely' })
    } catch (error) {
      window.analytics.track('Order editing - pause indefinitely failed')
      const errorResponse = error as FetchErrorInterface<OrderDetailDataErrorInterface>

      createErrorToast({
        errorCode: errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0',
        errorMessage: errorResponse.message,
        heading: 'An error occurred while pausing the order',
        id: ORDER_EDIT_WINDOW_TOASTS.ERROR
      })
      setOrderHeldStatus(StatusEnum.Error)
    }
  }

  return (
    <>
      <Button
        size="sm"
        variant="secondary"
        isLoading={orderHeldStatus === StatusEnum.Loading}
        startIcon={<PauseIcon className="h-8 w-8" />}
        onClick={handleHoldIndefinitely}
      >
        Pause indefinitely
      </Button>

      {orderHeldStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
