// @flow

import type { PageAction } from '../types'

import * as React from 'react'
import Title from '../../Title'
import Button from '../../Button'
import styles from './styles.module.css'

type Props = {|
  title: string,
  titleAccessory?: () => React$Node,
  description?: string,
  backAction?: PageAction,
  primaryAction?: PageAction,
  secondaryActions?: Array<PageAction>
|}

function PageHeader(props: Props): React$Node {
  return (
    <header className={styles.header}>
      {props.backAction && (
        <div className={styles.back}>
          <Button plain icon="arrow-left" onClick={props.backAction.onClick}>
            {props.backAction.title}
          </Button>
        </div>
      )}
      <div>
        <div className={styles.titleContainer}>
          <Title dataTest="page-title" className={styles.title}>
            {props.title}
          </Title>
          {props.titleAccessory && props.titleAccessory()}
        </div>
        {props.description && (
          <p data-testid="page-header__description" className={styles.description}>
            {props.description}
          </p>
        )}
      </div>
      {(props.primaryAction || props.secondaryActions) && (
        <div className={styles.actionsContainer}>
          {props.secondaryActions &&
            props.secondaryActions.map((action, i) => (
              <Button
                dataTest={`secondary-action-${i}`}
                destructive={action.isDestructive}
                icon={action.icon}
                iconPosition={action.iconPosition}
                onClick={action.onClick}
                disabled={action.disabled}
                key={action.title}
                data-testid="secondary-action"
              >
                {action.title}
              </Button>
            ))}
          {props.primaryAction && (
            <Button
              primary
              destructive={props.primaryAction.isDestructive}
              icon={props.primaryAction.icon}
              iconPosition={props.primaryAction.iconPosition}
              disabled={props.primaryAction.disabled}
              onClick={props.primaryAction.onClick}
              dataTest="header-primary-action"
            >
              {props.primaryAction.title}
            </Button>
          )}
        </div>
      )}
    </header>
  )
}

export default PageHeader
