import { AnimatePresence, motion } from 'framer-motion'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

import LoadingSpinner from '../../LoadingSpinner'
import { UploadingImageInterface } from '../interfaces'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'

export function UploadingImageComponent({
  fileDetails,
  imageLibraryMode
}: {
  fileDetails: UploadingImageInterface
  imageLibraryMode: ImageLibraryFullPageModeType
}) {
  return (
    <div className="relative mx-auto min-h-[200px] w-full md:min-h-[260px]">
      <div
        data-test={`image-library-tile-${fileDetails.name}`}
        className="group w-full text-left text-gray-700 antialiased"
      >
        <figure>
          <ImageThumbnail fileDetails={fileDetails} />
          <figcaption className="mt-1 break-words font-medium line-clamp-2">{fileDetails.name}</figcaption>
        </figure>
      </div>

      <AnimatePresence>
        {imageLibraryMode === 'manage' && (
          <motion.div
            className="pointer-events-none absolute inset-0 grid aspect-square w-full place-content-start bg-white/25 pt-4 pl-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          ></motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function ImageThumbnail({ fileDetails }: { fileDetails: UploadingImageInterface }) {
  const { imageLibraryImage, status } = fileDetails
  if (status === 'idle' || status === 'loading') {
    return (
      <div className="grid aspect-square w-full place-content-center bg-gray-200 bg-opacity-50">
        <LoadingSpinner className="h-10 w-10" />
      </div>
    )
  }

  if (status === 'error') {
    return (
      <div className="grid aspect-square w-full place-content-center justify-items-center gap-2 bg-gray-200 bg-opacity-50 px-4 text-center text-sm text-red-600">
        <XCircleIcon className="h-10 w-10" />
        <p className="font-medium">An error occurred when uploading this image.</p>
        {fileDetails.errorMessage && <p>{fileDetails.errorMessage}</p>}
        <p>Code {fileDetails.errorCode}</p>
      </div>
    )
  }

  if (!imageLibraryImage || !imageLibraryImage.thumbnail_urls.small) {
    return (
      <div className="grid aspect-square w-full place-content-center justify-items-center bg-gray-200 bg-opacity-50 text-center text-green-600">
        <CheckCircleIcon className="h-10 w-10" />
        <span>Uploaded</span>
      </div>
    )
  }

  return (
    <div className="relative flex aspect-square items-center justify-center">
      <img
        alt={imageLibraryImage.name}
        className="block aspect-square h-auto w-full max-w-full object-cover object-center"
        loading="lazy"
        src={imageLibraryImage.thumbnail_urls.small}
      />
      <div className="absolute inset-0 m-auto grid aspect-square h-auto w-full place-content-center justify-items-center bg-gray-200 bg-opacity-75 text-center">
        <CheckCircleIcon className="h-10 w-10 text-green-600" />
      </div>
    </div>
  )
}
