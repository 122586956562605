import { useState } from 'react'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { PlusIcon } from '@heroicons/react/outline'

import Button from '../Button'
import SelectField from '../SelectField'
import Modal, { ModalPropsInterface } from '../Modal'
import { BrandedInsertItem } from '../BrandedInsertsModal'

interface BrandedInsertsOrderModalPropsIntereface
  extends BrandedInsertsOrderPropsInterface,
    Pick<ModalPropsInterface, 'open' | 'closeOnEscape' | 'closeOnInteractionOutside' | 'title' | 'setOpen'> {}

export function BrandedInsertsOrderModal({
  open,
  closeOnEscape,
  closeOnInteractionOutside,
  title = 'Inserts on this order',
  setOpen,
  ...props
}: BrandedInsertsOrderModalPropsIntereface) {
  return (
    <Modal
      className="w-90vw max-w-[1000px]"
      closeOnEscape={closeOnEscape}
      closeOnInteractionOutside={closeOnInteractionOutside}
      title={title}
      open={open}
      setOpen={setOpen}
    >
      <div className="max-h-[60vh] overflow-y-auto">
        <BrandedInsertsOrder {...props} />
      </div>
    </Modal>
  )
}

type InsertDataType = {
  title: string
  description: string
  subText: string
  price: string
  imageUrl?: string
}

type InsertsDataType = Record<string, InsertDataType>

interface BrandedInsertsOrderPropsInterface {
  inserts?: InsertsDataType
  insertSets?: Record<string, InsertsDataType>
  onSave: (insertsData: InsertsDataType) => void
  onClose?: () => void
}

function BrandedInsertsOrder({ inserts = {}, insertSets = {}, onClose, onSave }: BrandedInsertsOrderPropsInterface) {
  const hasInsertsWithImage = Object.values(inserts).some((insertData) => Boolean(insertData.imageUrl))
  const hasInsertSets = Object.keys(insertSets).length > 0

  const [insertsData, setInsertsData] = useState(inserts)
  const [showInsertsWithoutImage, setShowInsertsWithoutImage] = useState(() => {
    if (hasInsertsWithImage || hasInsertSets) {
      return false
    } else {
      return true
    }
  })

  const insertsWithImage: InsertsDataType = {}
  const insertsWithoutImage: InsertsDataType = {}

  Object.entries(insertsData).forEach(([insertKey, insertData]) => {
    if (insertData.imageUrl) {
      insertsWithImage[insertKey] = insertData
    } else {
      insertsWithoutImage[insertKey] = insertData
    }
  })

  const numberOfInsertsWithImage = Object.keys(insertsWithImage).length

  function handleChangeInsertData({ insertKey, newInsertData }: { insertKey: string; newInsertData: InsertDataType }) {
    setInsertsData({
      ...insertsData,
      [insertKey]: { ...inserts[insertKey], ...newInsertData }
    })
  }

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1">
        <ul className="flex w-full flex-col gap-4">
          {Object.entries(insertsWithImage).map(([insertKey, insertData]) => {
            return (
              <BrandedInsertItem
                key={insertKey}
                insertData={insertData}
                editButton={{ text: 'Change for this order' }}
                deleteButton={{ text: 'Remove from this order' }}
                onChangeInsertData={(newInsertData) => handleChangeInsertData({ insertKey, newInsertData })}
              />
            )
          })}
        </ul>

        {showInsertsWithoutImage && (
          <motion.ul
            initial={{ height: hasInsertsWithImage || hasInsertSets ? 0 : 'auto' }}
            animate={{ height: 'auto' }}
            className="mt-4 flex w-full flex-col gap-4"
          >
            {Object.entries(insertsWithoutImage).map(([insertKey, insertData]) => {
              return (
                <BrandedInsertItem
                  key={insertKey}
                  insertData={insertData}
                  onChangeInsertData={(newInsertData) => handleChangeInsertData({ insertKey, newInsertData })}
                />
              )
            })}
          </motion.ul>
        )}

        {hasInsertSets && (
          <div className="mt-4 flex items-center gap-2 overflow-x-auto bg-[#FBFBFB] px-10 py-6">
            <div className="flex items-center gap-3">
              <div className="whitespace-nowrap">Assign a set</div>
              <SelectField
                className="mt-0 w-48"
                value=""
                onChange={(event) => {
                  const selectedInsertSetKey = event.target.value
                  const selectedInsertSetData = insertSets[selectedInsertSetKey]

                  if (!selectedInsertSetData) {
                    return
                  }

                  // TODO: Check if we need this when working with real data, will depend on how we store sets
                  const newInsertsData: InsertsDataType = {}
                  Object.entries(insertsData).forEach(([insertKey, insertData]) => {
                    if (selectedInsertSetData[insertKey]) {
                      newInsertsData[insertKey] = selectedInsertSetData[insertKey]
                    } else {
                      newInsertsData[insertKey] = {
                        ...insertData,
                        imageUrl: undefined
                      }
                    }
                  })

                  setInsertsData(newInsertsData)
                  setShowInsertsWithoutImage(false)
                  toast.success('Set assigned')
                }}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {Object.keys(insertSets).map((insertSetKey) => {
                  return (
                    <option key={insertSetKey} value={insertSetKey}>
                      {insertSetKey}
                    </option>
                  )
                })}
              </SelectField>
            </div>

            {!showInsertsWithoutImage && (
              <>
                or
                <Button className="whitespace-nowrap" variant="text" onClick={() => setShowInsertsWithoutImage(true)}>
                  add one-off inserts
                </Button>
              </>
            )}
          </div>
        )}

        {!hasInsertSets && !showInsertsWithoutImage && (
          <div className="mt-4 flex items-center bg-[#FBFBFB] px-10 py-6">
            <Button
              variant="text"
              startIcon={<PlusIcon className="h-5 w-5 stroke-2" />}
              onClick={() => setShowInsertsWithoutImage(true)}
            >
              {numberOfInsertsWithImage > 0 ? 'Add more' : 'Add inserts'}
            </Button>
          </div>
        )}
      </div>

      <div className="sticky bottom-0 flex flex-wrap-reverse items-center gap-2 bg-white pt-12">
        <div className="flex gap-2">
          <Button variant="primary" onClick={() => onSave(insertsData)}>
            <span>
              Save<span className="hidden md:inline"> inserts</span>
            </span>
          </Button>

          {onClose && (
            <Button variant="tertiary" theme="greyscale" onClick={onClose}>
              Cancel
            </Button>
          )}
        </div>

        {/* TODO: Show total here */}
        <div className="ml-auto font-medium text-black">
          {numberOfInsertsWithImage === 1
            ? `${numberOfInsertsWithImage} insert`
            : `${numberOfInsertsWithImage} inserts`}
          , £TODO
        </div>
      </div>
    </div>
  )
}
