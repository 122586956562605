import styles from './SummaryItemList.module.css'

export function MetaDataDisplay({
  metaData
}: {
  metaData?: { spineText?: string; spineBackgroundColourHexCode?: string; spineTextColourHexCode?: string }
}) {
  return (
    <>
      {Boolean(metaData?.spineText) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Text:</td>
          <td className={styles.meta}>{metaData?.spineText}</td>
        </tr>
      )}

      {Boolean(metaData?.spineBackgroundColourHexCode) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Background colour:</td>
          <td className={styles.meta}>
            <div className="tailwind">
              <div className="h-7 w-7 border" style={{ backgroundColor: metaData?.spineBackgroundColourHexCode }}></div>
            </div>
          </td>
        </tr>
      )}

      {Boolean(metaData?.spineTextColourHexCode) && (
        <tr>
          <td className={styles.attributeTitle}>Spine Text colour:</td>
          <td className={styles.meta}>
            <div className="tailwind">
              <div className="h-7 w-7 border" style={{ backgroundColor: metaData?.spineTextColourHexCode }}></div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
