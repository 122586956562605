import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PencilAltIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/outline'

import Button from '../Button'
import { cn } from '../../helpers'
import { createErrorToast } from '../Toast'
import { QUERY_PARAMS } from '../../constants'
import ImageLibraryModal from '../ImageLibraryModal'
import Modal, { ModalPropsInterface } from '../Modal'
import { ImageLibraryImageInterface } from '../../hooks'
import { PACKING_SLIP_ALLOWED_FILE_TYPES } from '../PackingSlip'

interface BrandedInsertsModalPropsIntereface
  extends BrandedInsertsPropsIntereface,
    Pick<ModalPropsInterface, 'open' | 'closeOnEscape' | 'closeOnInteractionOutside' | 'title' | 'setOpen'> {}

export function BrandedInsertsModal({
  open,
  closeOnEscape,
  closeOnInteractionOutside,
  title = 'Available inserts',
  setOpen,
  ...props
}: BrandedInsertsModalPropsIntereface) {
  return (
    <Modal
      className="w-90vw max-w-[1000px]"
      closeOnEscape={closeOnEscape}
      closeOnInteractionOutside={closeOnInteractionOutside}
      title={title}
      open={open}
      setOpen={setOpen}
    >
      <div className="h-60vh overflow-y-auto">
        <BrandedInserts {...props} />
      </div>
    </Modal>
  )
}

type InsertDataType = {
  title: string
  description: string
  subText: string
  price: string
  imageUrl?: string
}

type InsertsDataType = Record<string, InsertDataType>

interface BrandedInsertsPropsIntereface {
  inserts?: InsertsDataType
  onSave: (insertsData: InsertsDataType) => void
  onCancel?: () => void
}

// TODO: Replace with real data and revisit props interface
function BrandedInserts({ inserts = {}, onCancel, onSave }: BrandedInsertsPropsIntereface) {
  const [insertsData, setInsertsData] = useState(inserts)

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1">
        <ul className="flex w-full flex-col gap-4">
          {Object.entries(insertsData).map(([insertKey, insertData]) => {
            return (
              <BrandedInsertItem
                key={insertKey}
                insertData={insertData}
                onChangeInsertData={(newInsertData) =>
                  setInsertsData({
                    ...insertsData,
                    [insertKey]: { ...inserts[insertKey], ...newInsertData }
                  })
                }
              />
            )
          })}
        </ul>
      </div>

      <div className="sticky bottom-0 flex flex-wrap-reverse items-center gap-2 bg-white pt-10">
        <div className="flex gap-2">
          <Button variant="primary" onClick={() => onSave(insertsData)}>
            <span>
              Save<span className="hidden md:inline"> inserts</span>
            </span>
          </Button>

          {onCancel && (
            <Button variant="tertiary" theme="greyscale" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>

        {/* TODO: Show total here */}
        <div className="ml-auto font-medium text-black">TODO</div>
      </div>
    </div>
  )
}

const BRANDED_INSERT_ERROR_TOAST_ID = 'prodigiBrandedInsertImageErrorToast'

// TODO: Can move this to its own file if it's being re-used
export function BrandedInsertItem({
  insertData,
  editButton,
  deleteButton,
  onChangeInsertData
}: {
  insertData: InsertDataType
  editButton?: { text?: string }
  deleteButton?: { text?: string }
  onChangeInsertData: (newInsertData: InsertDataType) => void
}) {
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const hasImage = Boolean(insertData.imageUrl)

  function handleOpenImageLibrary() {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
    newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))

    history.replace({ search: newSearchParams.toString() })

    setIsImageLibraryModalOpen(true)
  }

  function handleSelectImage(imageLibraryImage: ImageLibraryImageInterface) {
    console.log({ imageLibraryImage })
    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)

    if (!PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      createErrorToast({
        errorMessage: `${
          imageLibraryImage.file_type
        } is not supported for inserts. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        heading: 'Failed to update insert',
        id: BRANDED_INSERT_ERROR_TOAST_ID
      })
      setIsImageLibraryModalOpen(false)
      return
    }

    onChangeInsertData({ ...insertData, imageUrl: imageLibraryImage.url })
    setIsImageLibraryModalOpen(false)
  }

  function handleDeleteImage() {
    onChangeInsertData({ ...insertData, imageUrl: undefined })
  }

  function onSetIsImageLibraryModalOpen(open: boolean) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpen(open)
  }

  return (
    <li
      className={cn(
        'flex w-full flex-col justify-between gap-8 p-10 text-black transition-colors md:flex-row',
        hasImage ? 'bg-[hsla(254,87%,97%,1)]' : 'bg-[hsla(0,0%,96%,0.5)]'
      )}
    >
      <div className="flex shrink-0 md:justify-center">
        {hasImage && insertData.imageUrl ? (
          <img
            key={insertData.imageUrl}
            className="h-[75px] w-[75px] object-contain"
            src={insertData.imageUrl}
            alt=""
          />
        ) : (
          <motion.button
            className="group flex h-[75px] w-[75px] items-center justify-center"
            whileHover="hover"
            whileTap="tap"
            onClick={handleOpenImageLibrary}
          >
            <motion.div
              variants={{
                hover: {
                  scale: 1.1
                },
                tap: {
                  scale: 0.9
                }
              }}
            >
              <PlusCircleIcon className="h-[40px] w-[40px] stroke-1 text-purple-500 transition-colors group-hover:text-purple-700" />
            </motion.div>
          </motion.button>
        )}
      </div>

      <div className="flex flex-1 flex-col justify-center gap-1">
        <div className="text-lg font-medium">{insertData.title}</div>
        <div>{insertData.description}</div>
        <div className="text-gray-700">{insertData.subText}</div>

        {hasImage && (
          <div className="flex flex-wrap items-center gap-6 pt-2">
            <Button
              className="hover:underline"
              startIcon={<PencilAltIcon className="h-6 w-6 text-purple-600" />}
              variant="text"
              size="sm"
              onClick={handleOpenImageLibrary}
            >
              {editButton?.text ?? 'Edit'}
            </Button>

            <Button
              className="hover:underline"
              startIcon={<TrashIcon className="h-6 w-6 text-magenta-800" />}
              variant="text"
              size="sm"
              theme="danger"
              onClick={handleDeleteImage}
            >
              {deleteButton?.text ?? 'Delete'}
            </Button>
          </div>
        )}
      </div>

      <div className={cn('shrink-0', hasImage ? 'font-medium' : 'opacity-50')}>{insertData.price}</div>

      <ImageLibraryModal
        // TODO: Determine if we need to force save
        // config={{ forceSaveToLibrary: true }}
        open={isImageLibraryModalOpen}
        type="packing"
        setOpen={onSetIsImageLibraryModalOpen}
        onSelectImage={handleSelectImage}
      />
    </li>
  )
}
