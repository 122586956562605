import { ReactNode, useState } from 'react'

import Button from '../../../Button'
import { cn } from '../../../../helpers'
import FormItem from '../../../FormItem'
import Checkbox from '../../../Checkbox'
import TextField from '../../../TextField'
import ConfirmationModal from '../../../ConfirmationModal'
import Modal, { ModalHeader, ModalPropsInterface } from '../../../Modal'

interface InsertSetSettingsModalPropsIntereface
  extends InsertSetSettingsPropsInterface,
    Pick<ModalPropsInterface, 'open' | 'closeOnEscape' | 'closeOnInteractionOutside' | 'title' | 'setOpen'> {
  onClose: () => void
}

export function InsertSetSettingsModal({
  open,
  closeOnEscape,
  closeOnInteractionOutside,
  title = 'Insert set settings',
  setOpen,
  onClose,
  ...props
}: InsertSetSettingsModalPropsIntereface) {
  return (
    <Modal
      className="w-90vw max-w-[850px]"
      closeOnEscape={closeOnEscape}
      closeOnInteractionOutside={closeOnInteractionOutside}
      showBorder={false}
      showHeader={false}
      title={title}
      open={open}
      setOpen={setOpen}
    >
      <ModalHeader className="px-8 md:px-12" subtitle="" title={title} onClose={onClose} />

      <div className="max-h-[40vh] overflow-y-auto px-8 md:max-h-[60vh] md:px-12">
        <InsertSetSettings onClose={onClose} {...props} />
      </div>

      <div className="bg-gray-100 px-8 py-12 md:px-12">
        <DeleteInsertSetSection />
      </div>
    </Modal>
  )
}

interface InsertSetSettingsPropsInterface {
  onClose: () => void
}

/* TODO: Replace with real data */
function InsertSetSettings({ onClose }: InsertSetSettingsPropsInterface) {
  const [insertSettings, setInsertSettings] = useState({
    name: 'Insert set A',
    usage: {
      API: { enabled: true },
      Online: { enabled: false }
    }
  })

  return (
    <form
      className="flex h-full flex-col"
      onSubmit={(event) => {
        event.preventDefault()
        console.log({ insertSettings })
      }}
    >
      <div className="flex flex-1 flex-col gap-16">
        <InsertSetSettingsSection
          className="border-none py-0"
          title="Name"
          description="Name this set of inserts for easy reference when ordering."
        >
          <FormItem
            className="max-w-[350px]"
            labelClassName="py-0 mt-0"
            labelText="Name"
            inputField={
              <TextField
                required
                value={insertSettings.name}
                onChange={(event) => {
                  setInsertSettings({ ...insertSettings, name: event.target.value })
                }}
              />
            }
          />
        </InsertSetSettingsSection>

        <InsertSetSettingsSection
          title="Usage"
          description={
            <>
              <p>Select which orders should include these inserts by default.</p>
              <p className="mt-4">
                You can always add, remove or change individual inserts on a per order basis if required.
              </p>
            </>
          }
        >
          <ul className="flex flex-col gap-6">
            {Object.entries(insertSettings.usage).map(([insertUsageKey, insertUsageData]) => {
              return (
                <li key={insertUsageKey}>
                  <Checkbox
                    checked={insertUsageData.enabled}
                    id={insertUsageKey}
                    label={insertUsageKey}
                    labelClassName="text-black text-base"
                    onChange={(event) => {
                      setInsertSettings({
                        ...insertSettings,
                        usage: {
                          ...insertSettings.usage,
                          [insertUsageKey]: { enabled: event.target.checked }
                        }
                      })
                    }}
                  />
                </li>
              )
            })}
          </ul>
        </InsertSetSettingsSection>
      </div>

      <div className="sticky bottom-0 flex items-center gap-2 bg-white py-12">
        <Button variant="primary" type="submit">
          Save
        </Button>

        <Button variant="tertiary" theme="greyscale" type="button" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

function InsertSetSettingsSection({
  className,
  title,
  description,
  children
}: {
  className?: string
  title: ReactNode
  description: ReactNode
  children: ReactNode
}) {
  return (
    <div className={cn('flex flex-col gap-8 border-y border-gray-200 py-16 md:flex-row md:gap-16', className)}>
      <div className="max-w-[275px] shrink-0">
        <h2>{title}</h2>
        <div className="mt-4 text-gray-600">{description}</div>
      </div>

      <div className="flex-1">{children}</div>
    </div>
  )
}

function DeleteInsertSetSection() {
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false)

  return (
    <div className="flex items-center gap-10 md:flex-nowrap">
      <div className="hidden flex-col gap-2 text-gray-700 md:flex">
        <div className="font-medium">Delete Insert Set A</div>
        <div className="max-w-[430px]">
          This action cannot be undone. Any in-progress orders using these inserts will not be affected.
        </div>
      </div>

      <div className="ml-auto">
        <Button
          className="whitespace-nowrap"
          variant="primary"
          theme="danger"
          onClick={() => setIsDeleteConfirmationModalVisible(true)}
        >
          Delete this set
        </Button>
      </div>

      <ConfirmationModal
        closeButton={{ text: 'Cancel', variant: 'tertiary', theme: 'greyscale' }}
        continueButton={{ text: 'Delete set', theme: 'danger' }}
        isLoading={false}
        open={isDeleteConfirmationModalVisible}
        title="Delete this set"
        setOpen={setIsDeleteConfirmationModalVisible}
        onCancel={() => setIsDeleteConfirmationModalVisible(false)}
        onContinue={() => setIsDeleteConfirmationModalVisible(false)}
      >
        <p>Are you sure you want to delete this insert set?</p>

        <p className="mt-2">
          This action cannot be undone. Any in-progress orders using these inserts will not be affected.
        </p>
      </ConfirmationModal>
    </div>
  )
}
