import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { useInterval } from '@mantine/hooks'
import { ClockIcon } from '@heroicons/react/outline'

import { useOrderDetail } from '../../../hooks'
import { calculateTimeRemaining } from '../helpers'
import { HoldOrderButton } from './HoldOrderButton.component'
import { SubmitOrderButton } from './SubmitOrderButton.component'
import { DelayedSubmissionStatusInterface } from '../../../interfaces'

export const ORDER_EDIT_WINDOW_TOASTS = {
  ERROR: 'prodigiOrderEditWindowErrorToast'
}

export function OrderPausedWindow() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)

  const [, setMinutes] = useState(0)
  const interval = useInterval(() => setMinutes((minutes) => minutes + 1), 60 * 1000)

  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  const isIndefinite =
    orderDetails.delayedSubmissionStatus === DelayedSubmissionStatusInterface.Delayed &&
    !orderDetails.delayedSubmissionDateTime

  const timeRemaining =
    !isIndefinite && orderDetails.delayedSubmissionDateTime
      ? calculateTimeRemaining(orderDetails.delayedSubmissionDateTime)
      : null

  useEffect(() => {
    return () => {
      toast.dismiss(ORDER_EDIT_WINDOW_TOASTS.ERROR)
    }
  }, [])

  useEffect(() => {
    interval.start()
    return interval.stop
    // As on example https://mantine.dev/hooks/use-interval/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (timeRemaining?.hasIntervalPassed) {
      mutateOrderDetails()
    }
  }, [mutateOrderDetails, timeRemaining?.hasIntervalPassed])

  let timeRemainingText = null
  if (isIndefinite) {
    timeRemainingText = 'Paused indefinitely'
  } else if (timeRemaining && !timeRemaining.hasIntervalPassed && timeRemaining.text) {
    timeRemainingText = `${timeRemaining.text} until production`
  } else if (timeRemaining?.hasIntervalPassed) {
    timeRemainingText = 'Order submission in progress'
  }

  return (
    <div className="isolate w-full" data-test="order-edit-window">
      <div className="flex flex-col gap-8 border border-purple-200 bg-purple-100 p-6 text-base">
        {timeRemainingText && (
          <h2 className="flex items-center gap-x-2 text-center text-base font-normal text-purple-700">
            <ClockIcon className="h-8 w-8" />
            {timeRemainingText}
          </h2>
        )}

        <div className="flex flex-col flex-wrap gap-x-4 gap-y-4 sm:flex-row">
          <SubmitOrderButton />
          {!isIndefinite && <HoldOrderButton />}
        </div>

        <span className="text-sm text-gray-700">Once in production this order cannot be changed or cancelled.</span>
      </div>
    </div>
  )
}
