import { ChevronRightIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

export interface BreadcrumbInterface {
  href?: string
  title: string
}

export default function Breadcrumbs({
  className = '',
  itemClassName = '',
  listClassName = '',
  pages,
  pageTitleClassName = ''
}: {
  className?: string
  itemClassName?: string
  listClassName?: string
  pages: BreadcrumbInterface[]
  pageTitleClassName?: string
}) {
  return (
    <nav aria-label="Breadcrumb" className={clsx(className, 'flex max-w-full overflow-x-auto')}>
      <ol className={`flex items-center space-x-2 md:space-x-4 ${listClassName}`} role="list">
        {pages.map((page, index) => (
          <li key={page.title + index}>
            <div className={`flex items-center gap-2 ${itemClassName}`}>
              {page.href && (
                <Link
                  className={clsx(
                    pageTitleClassName,
                    index + 1 !== pages.length && 'text-purple-600',
                    'whitespace-nowrap hover:cursor-pointer'
                  )}
                  to={page.href}
                >
                  {page.title}
                </Link>
              )}
              {!page.href && (
                <span className="whitespace-nowrap" aria-current={index === pages.length - 1 ? 'page' : undefined}>
                  {page.title}
                </span>
              )}

              {index < pages.length - 1 && <ChevronRightIcon className="h-6 w-6 text-gray-600" />}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
