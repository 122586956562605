import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Dispatch, MouseEvent, ReactNode, useState } from 'react'

import Button from '../../Button'
import TextField from '../../TextField'
import { RegisterReducerActionType } from '../types'
import { PASSWORD_MIN_LENGTH } from '../../../constants'
import ToggleHidePasswordButton from '../../ToggleHidePasswordButton'
import { EmailAvailableIndicator } from './EmailAvailableIndicator.component'
import { EmailAvailabilityStatusEnum, RegisterReducerActionTypeEnum } from '../enums'
import type { RegisterFormEmailFieldInterface, RegisterFormStringFieldInterface } from '../interfaces'

interface PropTypes {
  accountCreationError: string | null
  autoFocusFormField: boolean
  children?: ReactNode
  email: RegisterFormEmailFieldInterface
  password: RegisterFormStringFieldInterface
  registerFormDispatch: Dispatch<RegisterReducerActionType>
  onStepChange: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, step: number) => void
}

export default function Step1({
  autoFocusFormField,
  email,
  accountCreationError,
  password,
  children,
  onStepChange,
  registerFormDispatch
}: PropTypes) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex min-h-full w-full flex-col"
    >
      <div className="flex flex-col">
        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="email">
            Email
          </label>
        </span>
        <span className="relative">
          <TextField
            hasError={email.error !== null}
            dataTest="register-email"
            className="pr-12"
            autoFocus={autoFocusFormField}
            value={email.value}
            onChange={(e) => {
              registerFormDispatch({
                type: RegisterReducerActionTypeEnum.SetEmail,
                payload: {
                  email: e.target.value,
                  validity: e.target.validity
                }
              })
            }}
            id="email"
            name="email"
            type="email"
            required
          />
          <EmailAvailableIndicator emailError={email.error} emailAvailability={email.availability} />
        </span>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span animate={email.error ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
            {email.error}&nbsp;
          </motion.span>
        </span>

        <span className="flex items-center justify-between">
          <label className="text-sm uppercase text-gray-600" htmlFor="password">
            Create Password
          </label>
        </span>
        <span className="relative">
          <TextField
            className="pr-12"
            dataTest="register-password"
            value={password.value}
            onChange={(e) => {
              registerFormDispatch({
                type: RegisterReducerActionTypeEnum.SetPassword,
                payload: {
                  password: e.target.value,
                  validity: e.target.validity
                }
              })
            }}
            id="password"
            name="password"
            minLength={PASSWORD_MIN_LENGTH}
            type={showPassword ? 'text' : 'password'}
            required
          />
          <ToggleHidePasswordButton
            className="mt-1"
            onShowPasswordChange={setShowPassword}
            showPassword={showPassword}
          />
        </span>
      </div>

      <span className="mt-1 text-right text-sm text-red-500">
        <motion.span animate={password.error ? { opacity: 1 } : { opacity: 0 }} initial={{ opacity: 0 }}>
          {password.error}&nbsp;
        </motion.span>
      </span>

      <Button
        className="mt-8"
        type="submit"
        disabled={Boolean(
          email.error ||
            password.error ||
            !email.dirty ||
            !password.dirty ||
            email?.availability?.availability !== EmailAvailabilityStatusEnum.Available
        )}
        onClick={(event) => {
          onStepChange(event, 2)
        }}
      >
        Continue
      </Button>

      {children}

      {accountCreationError && (
        <div className="pt-3 text-red-500">
          {accountCreationError === 'is already taken' ? (
            <>
              This email address is already registered. Please{' '}
              <Link className="underline" to="/login">
                log in
              </Link>{' '}
              or{' '}
              <Link className="underline" to="/password-reset">
                reset your password
              </Link>
              .
            </>
          ) : (
            accountCreationError
          )}
        </div>
      )}
    </motion.form>
  )
}
