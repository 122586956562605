import { PhotographIcon } from '@heroicons/react/solid'

import Skeleton from '../../Skeleton'
import { ImageLibraryImageInterface } from '../../../hooks'
import { ExternalLinkIcon } from '@heroicons/react/outline'

export function ImageContainer({ imageLibraryImage }: { imageLibraryImage?: ImageLibraryImageInterface }) {
  if (!imageLibraryImage) {
    return <Skeleton className="grid aspect-square h-full w-full" />
  }

  if (imageLibraryImage.mime_type === 'application/pdf') {
    return <ImageDetailPdfPreview imageLibraryImage={imageLibraryImage} />
  }

  if (!imageLibraryImage?.thumbnail_urls.large) {
    return (
      <div className="grid aspect-square w-full place-content-center justify-items-center border-4 border-gray-200">
        <PhotographIcon className="h-10 w-10 text-gray-400" />
        <span>Image is not available</span>
      </div>
    )
  }

  return (
    <img
      alt={imageLibraryImage.name}
      width={imageLibraryImage.pixel_width ?? ''}
      height={imageLibraryImage.pixel_height ?? ''}
      className="mx-auto block max-h-[800px] w-auto"
      loading="lazy"
      src={imageLibraryImage.thumbnail_urls.large}
    />
  )
}

function ImageDetailPdfPreview({ imageLibraryImage }: { imageLibraryImage: ImageLibraryImageInterface }) {
  return (
    <div className="h-[75vh]">
      <object type="application/pdf" className="relative h-full w-full" data={imageLibraryImage.original_url}>
        <div className="absolute inset-0 grid place-content-center bg-gray-800 text-gray-100">
          {imageLibraryImage.thumbnail_urls.small && (
            <img src={imageLibraryImage.thumbnail_urls.small} className="mx-auto mb-4 w-28" />
          )}
          <p className="text-center">File preview cannot be shown</p>
          <a
            className="btn btn-sm mx-auto mt-4 w-fit border-gray-400 text-gray-200 hover:border-gray-300"
            href={imageLibraryImage.original_url}
            target="_blank"
            rel="noreferrer noopener"
          >
            <span>View pdf</span>
            <ExternalLinkIcon className="h-6 w-6" />
          </a>
        </div>
      </object>
    </div>
  )
}
