import { useRef, useLayoutEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ChevronLeftIcon } from '@heroicons/react/outline'

interface OrderFilterHeaderPropsInterface {
  showClearAction: boolean
  title: string
  onBack: () => void
  onClear: () => void
}

export function OrderFilterHeader({ showClearAction, title, onBack, onClear }: OrderFilterHeaderPropsInterface) {
  const backButtonRef = useRef<HTMLButtonElement | null>(null)
  useLayoutEffect(() => {
    backButtonRef.current?.focus()
  }, [])

  return (
    <div className="mb-2 flex pt-6 pb-2" data-test="order-filter-header">
      <motion.button
        className="relative flex items-center pl-10 text-black"
        data-test="order-filter-header__back"
        ref={backButtonRef}
        whileHover={'hover'}
        whileFocus={'hover'}
        onClick={onBack}
      >
        <span className="sr-only">{`${title} filter opened, click to go back`}</span>
        <motion.span
          variants={{
            hover: {
              x: -2
            }
          }}
          className="absolute inset-0 flex items-center pl-3 pr-2"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </motion.span>
        <span className="font-medium" aria-hidden="true">
          {title}
        </span>
      </motion.button>
      <AnimatePresence initial={false}>
        {showClearAction && (
          <motion.button
            animate={{ opacity: 1 }}
            className="ml-auto pr-8 text-xs text-purple-600"
            data-test="order-filter-header__clear"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            key="clearFilter"
            onClick={onClear}
          >
            Clear
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  )
}
