import { HEADER_NAMES } from '../constants'
import { DictionaryInterface } from '../../../interfaces'

export function buildVariantTableColumnVisibility(rotateable: boolean) {
  const columnVisibility: DictionaryInterface<boolean> = {}

  if (!rotateable) {
    columnVisibility[HEADER_NAMES.ORIENTATION] = false
  }

  return columnVisibility
}
