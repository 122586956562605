import { useState } from 'react'
import toast from 'react-hot-toast'

import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import Modal, { ModalHeader } from '../../Modal'
import { useMerchantService } from '../../../hooks'
import { SalesChannelInterface } from '../../../interfaces'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../../SalesChannelConfigure/constants'
import { DeleteChannelModalButton, SalesChannelPackingSlipSetting, SalesChannelPreferencesSetting } from '.'

interface SalesChannelSettingsModal {
  isSettingsModalOpen: boolean
  salesChannel: SalesChannelInterface
  setIsSettingsModalOpen: (open: boolean) => void
}

export function SalesChannelSettingsModal({
  isSettingsModalOpen,
  salesChannel,
  setIsSettingsModalOpen
}: SalesChannelSettingsModal) {
  const { merchantDetails } = useMerchantService()

  const [preferencesUpdateStatus, setPreferencesUpdateStatus] = useState(StatusEnum.Idle)
  const [packingSlipUpdateStatus, setPackingSlipUpdateStatus] = useState(StatusEnum.Idle)

  if (!merchantDetails) {
    throw new Error('No merchant details')
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setPreferencesUpdateStatus(StatusEnum.Idle)
      setPackingSlipUpdateStatus(StatusEnum.Idle)
      toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    }
    setIsSettingsModalOpen(isOpen)
  }

  const isPreferencesUpdateLoading = preferencesUpdateStatus === StatusEnum.Loading
  const isPackingSlipUpdateLoading = packingSlipUpdateStatus === StatusEnum.Loading

  return (
    <Modal
      className="relative max-w-[95vw] bg-white"
      closeOnInteractionOutside={!isPreferencesUpdateLoading && !isPackingSlipUpdateLoading}
      closeOnEscape={!isPreferencesUpdateLoading && !isPackingSlipUpdateLoading}
      open={isSettingsModalOpen}
      showBorder={false}
      showHeader={false}
      setOpen={handleOpenChange}
    >
      <ModalHeader
        className="px-8 md:px-12"
        subtitle=""
        title="Channel settings"
        onClose={() => handleOpenChange(false)}
      />

      <div className="max-h-60vh overflow-auto px-8 text-base md:px-12">
        <SalesChannelPreferencesSetting
          isLoading={isPreferencesUpdateLoading}
          salesChannel={salesChannel}
          onCancel={() => handleOpenChange(false)}
          setPreferencesUpdateStatus={setPreferencesUpdateStatus}
        />

        <SalesChannelPackingSlipSetting
          isLoading={isPackingSlipUpdateLoading}
          salesChannel={salesChannel}
          setPackingSlipUpdateStatus={setPackingSlipUpdateStatus}
        />
      </div>

      <div className="bg-gray-100 px-8 py-12 md:px-12">
        <div className="w-fit bg-white">
          <DeleteChannelModalButton salesChannelId={salesChannel.id} platform={salesChannel.platform} />
        </div>
      </div>

      {(isPreferencesUpdateLoading || isPackingSlipUpdateLoading) && <OverlayPortal />}
    </Modal>
  )
}
