import { ChatAlt2Icon, ExternalLinkIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline'

import { NavGroupTitle } from './NavGroupTitle.component'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupHelp({ className, onOpenChat }: { className?: string; onOpenChat?: () => void }) {
  return (
    <div className={className}>
      <NavGroupTitle>Help</NavGroupTitle>

      <NavGroupListContainer>
        <li>
          <button
            className="side-nav-item text-inherit w-full"
            onClick={() => {
              if (window.zE) {
                window.zE('messenger', 'open')
              }
              onOpenChat?.()
            }}
          >
            <ChatAlt2Icon className="h-7 w-7" />
            Support
          </button>
        </li>

        <li>
          <a
            className="side-nav-item text-inherit w-full"
            href="https://www.prodigi.com/faq/"
            rel="noreferrer"
            target="_blank"
            onClick={() => {
              window.analytics.track('Clicked: FAQs in menu')
            }}
          >
            <QuestionMarkCircleIcon className="h-7 w-7" />
            FAQs
            <ExternalLinkIcon className="h-5 w-5 stroke-purple-100" />
          </a>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
