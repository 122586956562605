import { TruckIcon } from '@heroicons/react/outline'
import { BanIcon, ExternalLinkIcon } from '@heroicons/react/solid'
import { addHours, format, isFuture, isWeekend, nextMonday } from 'date-fns'

import Skeleton from '../../Skeleton'
import { useProductSlas } from '../hooks'
import { ShipmentStatus } from '../../../enums'
import { findLongestSLA } from '../helpers/findLongestSLA.helper'

interface OrderDetailShipmentStatusPropsInterface {
  countryCode: string
  orderAllocationDateString?: string
  skusInShipment: string[]
  status: ShipmentStatus
  trackingUrl?: string
}

export function OrderDetailShipmentStatus({
  countryCode,
  orderAllocationDateString,
  skusInShipment,
  status,
  trackingUrl
}: OrderDetailShipmentStatusPropsInterface) {
  switch (status) {
    case ShipmentStatus.SHIPPED:
      return <Shipped trackingUrl={trackingUrl} />
    case ShipmentStatus.CANCELLED:
      return <Cancelled />
    case ShipmentStatus.IN_PROGRESS:
      return (
        <InProduction
          countryCode={countryCode}
          orderAllocationDateString={orderAllocationDateString}
          skusInShipment={skusInShipment}
        />
      )
    default:
      return <div />
  }
}

const defaultStyle = 'row-start-1 md:col-start-2 inline-flex items-center md:justify-end space-x-1 truncate'

function InProduction({
  countryCode,
  orderAllocationDateString,
  skusInShipment
}: {
  countryCode: string
  orderAllocationDateString?: string
  skusInShipment: string[]
}) {
  if (!orderAllocationDateString) {
    return (
      <div className={defaultStyle}>
        <span>In production</span>
      </div>
    )
  }

  return (
    <EstimatedDispatchDisplay
      countryCode={countryCode}
      orderAllocationDateString={orderAllocationDateString}
      skusInShipment={skusInShipment}
    />
  )
}

function EstimatedDispatchDisplay({
  countryCode,
  orderAllocationDateString,
  skusInShipment
}: {
  countryCode: string
  orderAllocationDateString: string
  skusInShipment: string[]
}) {
  const { isLoading, error: hasError, productsSlas } = useProductSlas({ countryCode, productSkus: skusInShipment })

  if (hasError) {
    return null
  }

  if (isLoading || !productsSlas) {
    return (
      <div className={defaultStyle}>
        <Skeleton className="w-28 bg-purple-200 py-2" />
      </div>
    )
  }

  if (Object.keys(productsSlas).length === 0) {
    return null
  }

  const slaInHours = findLongestSLA({ slaList: productsSlas })
  const currentDate = new Date()
  const orderAllocationDate = new Date(orderAllocationDateString)
  const dispatchDateAccordingToSla = addHours(orderAllocationDate, slaInHours)
  const estimatedDispatchDate = isFuture(dispatchDateAccordingToSla) ? dispatchDateAccordingToSla : currentDate
  const estimatedDispatchDateAdjustedForWeekend = isWeekend(estimatedDispatchDate)
    ? nextMonday(estimatedDispatchDate)
    : estimatedDispatchDate

  return (
    <div className={defaultStyle}>
      <span className="text-gray-700">
        Est. despatch {format(estimatedDispatchDateAdjustedForWeekend, 'eee dd MMM')}
      </span>
    </div>
  )
}

function Shipped({ trackingUrl }: { trackingUrl?: string }) {
  if (!trackingUrl) {
    return (
      <div className={`${defaultStyle} text-gray-900`}>
        <span>Shipped</span>
        <TruckIcon className="h-8 w-8" />
      </div>
    )
  }

  return (
    <a
      className={`${defaultStyle} cursor-pointer text-purple-600 underline-offset-1 hover:underline`}
      data-test="shipment-header__tracking"
      href={trackingUrl}
      target="_blank"
      title="Track shipment on the carrier site"
      rel="noreferrer noopener"
    >
      <span>Track</span>
      <ExternalLinkIcon className="h-8 w-8" />
    </a>
  )
}

function Cancelled() {
  return (
    <div className={`${defaultStyle} text-red-800`}>
      <span>Cancelled</span>
      <BanIcon className="h-8 w-8" />
    </div>
  )
}
