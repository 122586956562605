import Countries from 'country-list'
import { motion } from 'framer-motion'
import { useRef, useState } from 'react'

import {
  DebouncedSearchInputRefType,
  DebouncedSearchInput,
  ProductTypesList,
  ProductCategoriesList,
  ProductFilterPill,
  ProductSearch,
  SelectProductSavedItemsModalButton
} from './components'
import Button from '../Button'
import { ProductInterface } from '../../interfaces'
import { CATALOGUE_PRODUCTS } from '../../constants'
import { ProductDetailSuccessResponseInterface, TemplateServiceSuccessResponseInterface } from '../../hooks'

export interface SelectedProductInterface {
  searchResultData: ProductInterface
  selectedAttributes: Record<string, string> | null
  sku: string
}

export interface ProductAdditionalDataInterface {
  productDetails: ProductDetailSuccessResponseInterface
  templates: TemplateServiceSuccessResponseInterface
}

export interface SelectProductPropsInterface {
  countryCode: string
  defaultSearchQuery?: string
  defaultSelectedAttributes?: Record<string, string>
  selectingSkuName?: string
  showCountryPicker?: boolean
  onSelectProduct?: (selectedProduct: SelectedProductInterface) => void
  onSelectProductWithAdditionalData?: (
    selectedProduct: SelectedProductInterface,
    additionalProductData: ProductAdditionalDataInterface
  ) => void
}

export default function SelectProduct({
  countryCode,
  defaultSearchQuery = '',
  defaultSelectedAttributes = {},
  selectingSkuName,
  showCountryPicker = false,
  onSelectProduct,
  onSelectProductWithAdditionalData
}: SelectProductPropsInterface) {
  const searchInputRef = useRef<DebouncedSearchInputRefType>(null)

  const [searchQuery, setSearchQuery] = useState(defaultSearchQuery)
  const [category, setCategory] = useState<string | null>(null)
  const [productType, setProductType] = useState<string | null>(null)

  const categoryHasProductTypes = Boolean(
    category && Object.keys(CATALOGUE_PRODUCTS[category]?.productTypes ?? {}).length > 0
  )
  const showCategories = Boolean(!searchQuery && !category)
  const showProductTypes = Boolean(!searchQuery && categoryHasProductTypes && !productType)
  const showSearchResults = Boolean(searchQuery || (categoryHasProductTypes ? productType : category))

  function handleClearCatalogueSelection() {
    setCategory(null)
    setProductType(null)
    focusSearchInput()
  }

  function handleClearSearch() {
    handleClearCatalogueSelection()
    setSearchQuery('')
    searchInputRef.current?.clearSearchInput?.()
  }

  function focusSearchInput() {
    const deviceSupportsHover = window.matchMedia('(hover: hover)').matches
    if (deviceSupportsHover) {
      searchInputRef.current?.focus()
    }
  }

  return (
    <div className="flex min-h-full flex-col">
      <div className="sticky top-0 z-10 flex max-w-full items-center gap-4 overflow-x-auto border bg-white p-4">
        <div className="w-96 flex-shrink-0 sm:w-[300px]">
          <DebouncedSearchInput
            ref={searchInputRef}
            category={category}
            defaultValue={defaultSearchQuery}
            productType={productType}
            onChange={setSearchQuery}
          />
        </div>

        <ProductFilterPill value={category} onClear={handleClearCatalogueSelection} />

        <ProductFilterPill
          value={productType}
          onClear={() => {
            setProductType(null)
            focusSearchInput()
          }}
        />

        <motion.div layout className="ml-auto flex gap-1">
          <motion.div layout>
            <SelectProductSavedItemsModalButton
              countryCode={countryCode}
              selectingSkuName={selectingSkuName}
              showCountryPicker={showCountryPicker}
              onSelectProduct={onSelectProduct}
              onSelectProductWithAdditionalData={onSelectProductWithAdditionalData}
            />
          </motion.div>

          {!showCategories && (
            <motion.div layout>
              <Button
                className="whitespace-nowrap"
                variant="tertiary"
                size="sm"
                theme="greyscale"
                onClick={handleClearSearch}
              >
                Clear search
              </Button>
            </motion.div>
          )}
        </motion.div>
      </div>

      <div className="my-8 flex-1">
        {showCategories && (
          <ProductCategoriesList
            onSelect={(category: string) => {
              setCategory(category)
              focusSearchInput()
            }}
          />
        )}

        {showProductTypes && category && (
          <ProductTypesList
            category={category}
            onSelect={(productType) => {
              if (productType) {
                setProductType(productType)
              } else {
                handleClearCatalogueSelection()
              }
              focusSearchInput()
            }}
          />
        )}

        {showSearchResults && (
          <ProductSearch
            category={category}
            defaultSearchQuery={defaultSearchQuery}
            defaultSelectedAttributes={defaultSelectedAttributes}
            countryCode={countryCode}
            productType={productType}
            searchQuery={searchQuery}
            selectingSkuName={selectingSkuName}
            showCountryPicker={showCountryPicker}
            onClearSearch={handleClearSearch}
            onSelectProduct={onSelectProduct}
            onSelectProductWithAdditionalData={onSelectProductWithAdditionalData}
          />
        )}
      </div>

      <div className="sticky -bottom-1 flex items-center gap-2 bg-gray-100 px-1 pt-6 pb-1">
        Shipping to
        <img
          alt={Countries().getName(countryCode)}
          className="w-7 object-contain"
          src={`/img/flags-24/${countryCode}.png`}
        />
        {Countries().getName(countryCode)}
      </div>
    </div>
  )
}
