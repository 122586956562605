import { forwardRef, useRef } from 'react'
import { useHotkeys } from '@mantine/hooks'
import { SearchIcon } from '@heroicons/react/outline'

interface SearchFieldPropsInterface
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  dataTest?: string
  hotkey?: string
  hotkeyDisplay?: string
  onClearSearch: () => void
}

export const SearchField = forwardRef(
  (
    {
      autoCapitalize = 'off',
      autoComplete = 'off',
      className = '',
      dataTest = 'search-field',
      hotkey,
      hotkeyDisplay,
      type = 'search',
      value,
      onClearSearch,
      onChange,
      ...props
    }: SearchFieldPropsInterface,
    ref: React.LegacyRef<HTMLInputElement> | undefined
  ) => {
    const inputRef = useRef<null | HTMLInputElement>(null)

    function handleClearSearch() {
      onClearSearch()
      inputRef.current?.focus()
    }

    useHotkeys(hotkey ? [[hotkey, () => inputRef.current?.focus()]] : [])

    const hasValue = value && typeof value === 'string' && value.length > 0

    return (
      <span className="input-select-container">
        <div>
          <SearchIcon className="h-7 w-7 text-gray-800" aria-hidden="true" />
        </div>

        <div className="relative flex w-full items-center">
          <input
            autoCapitalize={autoCapitalize}
            autoComplete={autoComplete}
            className={`ml-2 w-full self-center truncate bg-white text-input focus:outline-none sm:text-base ${className}`}
            data-test={dataTest}
            ref={ref ?? inputRef}
            type={type}
            value={value}
            onChange={onChange}
            {...props}
          />

          {hasValue && (
            <button
              className="flex h-7 w-7 cursor-pointer items-center bg-contain"
              style={{
                background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'><path stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /></svg>") no-repeat 100% 100%`
              }}
              onClick={handleClearSearch}
            >
              <span className="sr-only">Clear search</span>
            </button>
          )}

          {!hasValue && hotkeyDisplay && (
            <span className="pointer-events-none absolute right-1 text-sm text-gray-400">{hotkeyDisplay}</span>
          )}
        </div>
      </span>
    )
  }
)

SearchField.displayName = 'SearchField'
