// @flow
import type { CsvUploadState } from '../../types'
import type {
  CsvActions,
  CsvOrderSubmissionAction,
  RemoveIgnoredOrder,
  SelectProductForCsvOrder,
  RemovePackingSlip,
  FinishPackingSlipUpload,
  StartPackingSlipUpload,
  FailedPackingSlipUpload,
  ToggleIsUsSalesTaxCollectedByOrderId,
  ToggleIsUsSalesTaxCollectedForAllOrders,
  InitPrintAreasForCsvItem,
  RemoveCsvItemArtwork,
  UpdateCsvItemUrl,
  UpdateCsvItemPrintAreas
} from '../../actions/types'
import {
  UPLOAD_CSV_SUCCESS,
  UPLOAD_CSV_ERROR,
  UPLOAD_CSV,
  UPDATE_MERCHANT_ORDER_ID,
  UPDATE_CUSTOMS_VALUE,
  UPDATE_SHIPPING_METHOD,
  CREATE_CSV_ORDER,
  CREATE_CSV_ORDER_SUCCESS,
  CREATE_CSV_ORDER_ERROR,
  ADD_IMAGES_TO_CSV_ORDER,
  ADD_IMAGES_TO_CSV_ORDER_SUCCESS,
  ADD_IMAGES_TO_CSV_ORDER_ERROR,
  SUBMIT_CSV_ORDER,
  SUBMIT_CSV_ORDER_SUCCESS,
  SUBMIT_CSV_ORDER_ERROR,
  SELECT_PRODUCT_FOR_CSV_ORDER,
  FIND_PRODUCT_DETAILS_SUCCESS,
  FIND_PRODUCT_DETAILS_ERROR,
  FIND_PRODUCT_DETAILS,
  RESET_CSV_UPLOAD_STATE,
  UPDATE_CSV_ORDER_ADDRESS,
  ADD_PRODUCT_DESCRIPTIONS,
  ADD_IGNORED_ORDER,
  REMOVE_IGNORED_ORDER,
  UPDATE_CSV_ITEM_URL,
  UPDATE_CSV_ORDER_ITEM_QUANTITY,
  IGNORE_ALL_ORDERS,
  SELECT_ALL_ORDERS,
  TOGGLE_MULTISELECT_MODE,
  COPY_SHIPPING_METHOD,
  COPY_PRODUCT_DETAILS,
  REMOVE_CSV_ITEM_ARTWORK,
  TOGGLE_IS_US_SALES_TAX_COLLECTED_BY_ORDER_ID,
  TOGGLE_IS_US_SALES_TAX_COLLECTED_FOR_ALL_ORDERS
} from '../../actions/csvUpload'
import {
  CSV_PACKING_SLIP_UPLOAD,
  CSV_PACKING_SLIP_UPLOAD_SUCCESS,
  CSV_PACKING_SLIP_UPLOAD_ERROR,
  REMOVE_CSV_PACKING_SLIP
} from '../../actions/csvUpload/uploadPackingSlip'
import { LOADING, SUCCESS, ERROR } from '../../data/rsaa'
import { updateProductDetails } from './updateProductDetails'
import { updateOrderItemSkus } from './updateOrderItemSkus'
import { updateOrderSubmissionProgress } from './updateOrderSubmissionProgress'
import { updateOrderShippingMethods } from './updateOrderShippingMethods'
import { updateOrderItemProductDetails } from './updateOrderItemProductDetails'
import { values, entries } from '../../helpers/dictionary'
import { removeElement } from '../../helpers/removeElement'
import { INIT_PRINT_AREAS_FOR_CSV_ITEM } from '../../actions/csvUpload/initPrintAreasForCsvItem'
import { UPDATE_CSV_ITEM_PRINT_AREAS } from '../../actions/csvUpload/updateCsvItemPrintAreas'

const initialState: CsvUploadState = {
  hasCsvUploadedSuccessfully: false,
  totalSubmittedOrders: 0,
  uploadMessage: null,
  csvFileType: null,
  uploadedOrders: {},
  orderItems: {},
  progress: {},
  productDetails: {},
  productDetailsProgress: {},
  productDescriptions: {},
  ignoredOrders: [],
  isMultiselectModeOn: false,
  packingSlips: {},
  packingSlipsProgress: {}
}

export default function csvUpload(state: CsvUploadState = initialState, action: CsvActions): CsvUploadState {
  switch (action.type) {
    case TOGGLE_IS_US_SALES_TAX_COLLECTED_FOR_ALL_ORDERS: {
      const currentAction: ToggleIsUsSalesTaxCollectedForAllOrders = action
      return {
        ...state,
        uploadedOrders: entries(state.uploadedOrders).reduce((orderAcc, [orderId, order]) => {
          orderAcc[orderId] = {
            ...order,
            isUsSalesTaxCollected: currentAction.usSalesTaxToggleState
          }
          return orderAcc
        }, {})
      }
    }

    case TOGGLE_IS_US_SALES_TAX_COLLECTED_BY_ORDER_ID: {
      const currentAction: ToggleIsUsSalesTaxCollectedByOrderId = action
      return {
        ...state,
        uploadedOrders: entries(state.uploadedOrders).reduce((orderAcc, [orderId, order]) => {
          if (currentAction.orderId === orderId) {
            orderAcc[orderId] = {
              ...order,
              isUsSalesTaxCollected: !state.uploadedOrders[orderId].isUsSalesTaxCollected
            }
          } else {
            orderAcc[orderId] = order
          }

          return orderAcc
        }, {})
      }
    }

    case REMOVE_CSV_PACKING_SLIP: {
      const currentAction: RemovePackingSlip = action
      return {
        ...state,
        uploadedOrders: {
          ...state.uploadedOrders,
          [currentAction.index]: {
            ...state.uploadedOrders[currentAction.index],
            packingSlip: ''
          }
        },
        packingSlips: removeElement(state.packingSlips, currentAction.index),
        packingSlipsProgress: removeElement(state.packingSlipsProgress, currentAction.index)
      }
    }

    case CSV_PACKING_SLIP_UPLOAD_SUCCESS: {
      const currentAction: FinishPackingSlipUpload = action
      const { index, packingSlipUrl } = currentAction.payload
      return {
        ...state,
        uploadedOrders: {
          ...state.uploadedOrders,
          [index]: {
            ...state.uploadedOrders[index],
            packingSlip: packingSlipUrl
          }
        },
        packingSlips: {
          ...state.packingSlips,
          [index]: packingSlipUrl
        },
        packingSlipsProgress: {
          ...state.packingSlipsProgress,
          [index]: {
            status: SUCCESS,
            errorMessage: ''
          }
        }
      }
    }

    case CSV_PACKING_SLIP_UPLOAD: {
      const currentAction: StartPackingSlipUpload = action
      return {
        ...state,
        packingSlipsProgress: {
          ...state.packingSlipsProgress,
          [currentAction.payload.index]: {
            status: LOADING,
            errorMessage: ''
          }
        }
      }
    }

    case CSV_PACKING_SLIP_UPLOAD_ERROR: {
      const currentAction: FailedPackingSlipUpload = action
      return {
        ...state,
        packingSlipsProgress: {
          ...state.packingSlipsProgress,
          [currentAction.meta.orderId]: {
            status: ERROR,
            errorMessage: currentAction.payload.errorMessage
          }
        }
      }
    }

    case UPDATE_CUSTOMS_VALUE: {
      return {
        ...state,
        uploadedOrders: {
          ...state.uploadedOrders,
          [action.index]: {
            ...state.uploadedOrders[action.index],
            customsValue: action.customsValue
          }
        }
      }
    }

    case COPY_PRODUCT_DETAILS: {
      return {
        ...state,
        orderItems: updateOrderItemProductDetails(
          state.orderItems,
          action.idsOfItemsToUpdate,
          action.sku,
          action.selectedAttributes
        )
      }
    }

    case COPY_SHIPPING_METHOD: {
      return {
        ...state,
        uploadedOrders: updateOrderShippingMethods(
          state.uploadedOrders,
          action.idsOfOrdersToUpdate,
          action.shippingMethod
        )
      }
    }

    case TOGGLE_MULTISELECT_MODE: {
      return {
        ...state,
        isMultiselectModeOn: !state.isMultiselectModeOn
      }
    }

    case UPDATE_CSV_ORDER_ITEM_QUANTITY: {
      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          [action.itemId]: {
            ...state.orderItems[action.itemId],
            quantity: action.quantity
          }
        }
      }
    }

    case REMOVE_CSV_ITEM_ARTWORK: {
      const currentAction: RemoveCsvItemArtwork = action
      const orderItemToUpdate = state.orderItems[currentAction.orderItemId]

      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          [currentAction.orderItemId]: {
            ...orderItemToUpdate,
            printAreaImageUrls: entries(orderItemToUpdate.printAreaImageUrls).reduce(
              (printAreaImageUrlAcc, [printAreaName, url]) => {
                if (currentAction.printArea !== printAreaName) {
                  return {
                    ...printAreaImageUrlAcc,
                    [printAreaName]: url
                  }
                } else {
                  return {
                    ...printAreaImageUrlAcc,
                    [printAreaName]: null
                  }
                }
              },
              {}
            ),
            transformImageUrls: entries(orderItemToUpdate.transformImageUrls).reduce(
              (transformImageUrlAcc, [printAreaName, url]) => {
                if (currentAction.printArea !== printAreaName) {
                  return {
                    ...transformImageUrlAcc,
                    [printAreaName]: url
                  }
                } else {
                  return {
                    ...transformImageUrlAcc,
                    [printAreaName]: null
                  }
                }
              },
              {}
            )
          }
        }
      }
    }

    case UPDATE_CSV_ITEM_URL: {
      const currentAction: UpdateCsvItemUrl = action
      const orderItemToUpdate = state.orderItems[currentAction.itemId]

      if (!orderItemToUpdate) {
        return state
      }

      if (currentAction.printArea) {
        return {
          ...state,
          orderItems: {
            ...state.orderItems,
            [currentAction.itemId]: {
              ...orderItemToUpdate,
              printAreaImageUrls: {
                ...orderItemToUpdate.printAreaImageUrls,
                [currentAction.printArea]: action.url
              },
              transformImageUrls: {
                ...orderItemToUpdate.transformImageUrls,
                [currentAction.printArea]: action.transformImageUrl
              }
            }
          }
        }
      } else {
        return {
          ...state,
          orderItems: {
            ...state.orderItems,
            [currentAction.itemId]: {
              ...orderItemToUpdate,
              unknownPrintAreaImageUrl: action.url
            }
          }
        }
      }
    }

    case ADD_PRODUCT_DESCRIPTIONS: {
      return {
        ...state,
        productDescriptions: action.descriptions
      }
    }

    case ADD_IGNORED_ORDER: {
      return {
        ...state,
        ignoredOrders: [...state.ignoredOrders, action.index]
      }
    }

    case REMOVE_IGNORED_ORDER: {
      const currentAction: RemoveIgnoredOrder = action
      return {
        ...state,
        ignoredOrders: state.ignoredOrders.filter((orderId, index) => orderId !== currentAction.index)
      }
    }

    case IGNORE_ALL_ORDERS: {
      return {
        ...state,
        ignoredOrders: values(state.uploadedOrders).map((order) => order.id)
      }
    }

    case SELECT_ALL_ORDERS: {
      return {
        ...state,
        ignoredOrders: []
      }
    }

    case SELECT_PRODUCT_FOR_CSV_ORDER: {
      const currentAction: SelectProductForCsvOrder = action
      const stateWithUpdatedProductDetails = updateProductDetails(state, currentAction.product)
      return {
        ...stateWithUpdatedProductDetails,
        orderItems: {
          ...state.orderItems,
          [currentAction.itemId]: {
            ...state.orderItems[currentAction.itemId],
            sku: action.product.sku,
            selectedAttributes: action.selectedAttributes
          }
        }
      }
    }

    case UPLOAD_CSV: {
      return {
        ...state,
        hasCsvUploadedSuccessfully: false,
        uploadMessage: null,
        csvFileType: null,
        uploadedOrders: {},
        orderItems: {},
        productDetailsProgress: {}
      }
    }

    case FIND_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetailsProgress: {
          ...state.productDetailsProgress,
          [action.sku]: LOADING
        }
      }
    }

    case FIND_PRODUCT_DETAILS_SUCCESS: {
      return updateProductDetails(state, action.product)
    }

    case FIND_PRODUCT_DETAILS_ERROR: {
      return {
        ...state,
        productDetailsProgress: {
          ...state.productDetailsProgress,
          [action.sku]: ERROR
        },
        orderItems: updateOrderItemSkus(state.orderItems, action.sku)
      }
    }

    case INIT_PRINT_AREAS_FOR_CSV_ITEM: {
      const currentAction: InitPrintAreasForCsvItem = action
      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          [currentAction.itemId]: {
            ...state.orderItems[currentAction.itemId],
            selectedPrintArea: currentAction.selectedPrintArea,
            printAreaImageUrls: {
              ...state.orderItems[currentAction.itemId]?.printAreaImageUrls,
              ...currentAction.printAreaImageUrls
            },
            transformImageUrls: {
              ...state.orderItems[currentAction.itemId]?.transformImageUrls,
              ...currentAction.printAreaImageUrls
            }
          }
        }
      }
    }

    case UPDATE_CSV_ITEM_PRINT_AREAS: {
      const currentAction: UpdateCsvItemPrintAreas = action
      return {
        ...state,
        orderItems: {
          ...state.orderItems,
          [currentAction.orderItemId]: {
            ...state.orderItems[currentAction.orderItemId],
            selectedPrintArea: currentAction.selectedPrintArea,
            printAreaImageUrls: currentAction.printAreaImageUrls,
            transformImageUrls: currentAction.transformImageUrls
          }
        }
      }
    }

    case UPLOAD_CSV_SUCCESS: {
      return {
        ...state,
        uploadedOrders: action.formattedOrders,
        orderItems: action.orderItems,
        hasCsvUploadedSuccessfully: true,
        csvFileType: action.fileType
      }
    }

    case UPLOAD_CSV_ERROR: {
      return {
        ...state,
        hasCsvUploadedSuccessfully: false
      }
    }

    case UPDATE_MERCHANT_ORDER_ID: {
      return {
        ...state,
        uploadedOrders: {
          ...state.uploadedOrders,
          [action.index]: {
            ...state.uploadedOrders[action.index],
            merchantOrderId: action.orderId
          }
        }
      }
    }

    case UPDATE_SHIPPING_METHOD: {
      return {
        ...state,
        uploadedOrders: {
          ...state.uploadedOrders,
          [action.index]: {
            ...state.uploadedOrders[action.index],
            preferredShippingMethod: action.shippingMethod
          }
        }
      }
    }

    case SUBMIT_CSV_ORDER_SUCCESS: {
      const currentAction: CsvOrderSubmissionAction<*> = action
      const stateWithUpdatedOrderSubmissionProgress = updateOrderSubmissionProgress(state, currentAction)
      const totalSubmitted = state.totalSubmittedOrders + 1

      return {
        ...stateWithUpdatedOrderSubmissionProgress,
        totalSubmittedOrders: totalSubmitted,
        uploadedOrders: Object.keys(state.uploadedOrders).reduce((orderAcc, orderId) => {
          if (currentAction.order.id !== orderId) {
            orderAcc[orderId] = state.uploadedOrders[orderId]
          }

          return orderAcc
        }, {})
      }
    }

    case CREATE_CSV_ORDER:
    case CREATE_CSV_ORDER_SUCCESS:
    case CREATE_CSV_ORDER_ERROR:
    case ADD_IMAGES_TO_CSV_ORDER:
    case ADD_IMAGES_TO_CSV_ORDER_SUCCESS:
    case ADD_IMAGES_TO_CSV_ORDER_ERROR:
    case SUBMIT_CSV_ORDER:
    case SUBMIT_CSV_ORDER_ERROR: {
      return updateOrderSubmissionProgress(state, action)
    }

    case RESET_CSV_UPLOAD_STATE: {
      return {
        ...state,
        hasCsvUploadedSuccessfully: false,
        totalSubmittedOrders: 0,
        uploadMessage: null,
        csvFileType: null,
        uploadedOrders: {},
        orderItems: {},
        progress: {},
        ignoredOrders: [],
        isMultiselectModeOn: false
      }
    }

    case UPDATE_CSV_ORDER_ADDRESS: {
      return {
        ...state,
        uploadedOrders: {
          ...state.uploadedOrders,
          [action.orderId]: {
            ...state.uploadedOrders[action.orderId],
            customer: {
              ...state.uploadedOrders[action.orderId].customer,
              ...action.address
            }
          }
        }
      }
    }

    default:
      return state
  }
}
