import { Link } from 'react-router-dom'
import { CreditCardIcon, CogIcon } from '@heroicons/react/solid'
import { UserIcon, AdjustmentsIcon, BadgeCheckIcon } from '@heroicons/react/outline'

import { useSplitToggle } from '../../../hooks'
import { NavGroupTitle } from './NavGroupTitle.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupSettings({ className }: { className?: string }) {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return (
      <div className={className}>
        <NavGroupTitle>Settings</NavGroupTitle>

        <NavGroupListContainer>
          <li>
            <Link to={ROUTE_PATHS.SETTINGS.API} className="side-nav-item">
              <CogIcon className="h-7 w-7" />
              API
            </Link>
          </li>
        </NavGroupListContainer>
      </div>
    )
  }

  return (
    <div className={className}>
      <NavGroupTitle>Settings</NavGroupTitle>

      <NavGroupListContainer>
        <li>
          <Link to={ROUTE_PATHS.SETTINGS.ACCOUNT} className="side-nav-item">
            <UserIcon className="h-7 w-7" />
            Account
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.SETTINGS.PREFERENCES} className="side-nav-item">
            <AdjustmentsIcon className="h-7 w-7" />
            Preferences
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.SETTINGS.BILLING} className="side-nav-item">
            <CreditCardIcon className="h-7 w-7" />
            Billing
          </Link>
        </li>

        {isBrandingSettingsOn && (
          <li>
            <Link to={ROUTE_PATHS.SETTINGS.BRANDING} className="side-nav-item">
              <BadgeCheckIcon className="h-7 w-7" />
              Branding
            </Link>
          </li>
        )}

        <li>
          <Link to={ROUTE_PATHS.SETTINGS.API} className="side-nav-item">
            <CogIcon className="h-7 w-7" />
            API
          </Link>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
