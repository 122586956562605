import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/solid'

import LogoProdigi from '../../LogoProdigi'
import { ROUTE_PATHS } from '../../../constants'
import { NavGroupHelp } from './NavGroupHelp.component'
import { NavGroupOrders } from './NavGroupOrders.component'
import { NavGroupSettings } from './NavGroupSettings.component'
import { NavGroupCatalogue } from './NavGroupCatalogue.component'
import { CreateOrderButton } from './CreateOrderButton.component'
import { FEATURE_NAMES } from 'src/split-io/feature-names'
import { useSplitToggle } from 'src/v2/hooks'

export function DesktopSideNav() {
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })
  return (
    <div className="h-full w-[25rem] overflow-auto bg-purple-950 p-6 text-white">
      <Link to={ROUTE_PATHS.DASHBOARD} className="flex w-fit">
        <LogoProdigi className="w-36 fill-white" type="logo" />
      </Link>

      {!isShopifySplitOn && <CreateOrderButton className="mt-10" />}

      <Link to={ROUTE_PATHS.DASHBOARD} className="side-nav-item mt-10">
        <HomeIcon className="h-7 w-7" />
        Home
      </Link>

      <NavGroupOrders className="mt-10" />

      <NavGroupCatalogue className="mt-10" />

      <NavGroupSettings className="mt-10" />

      <NavGroupHelp className="mt-10" />
    </div>
  )
}
