import clsx from 'clsx'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import Button from '../Button'
import { formatToSentenceCase } from '../../helpers'

export default function PrintAreaSelector({
  className,
  printAreas,
  selectedPrintArea,
  onPrintAreaChange
}: {
  className?: string
  printAreas: string[]
  selectedPrintArea: string
  onPrintAreaChange: (selectedPrintArea: string) => void
}) {
  const selectedPrintAreaIndex = printAreas.findIndex((printArea) => printArea === selectedPrintArea)

  function handlePrintAreaChange(newIndex: number) {
    if (printAreas[newIndex]) {
      onPrintAreaChange(printAreas[newIndex])
      return
    }

    const lastPrintArea = printAreas[printAreas.length - 1]
    if (newIndex < 0 && lastPrintArea) {
      onPrintAreaChange(lastPrintArea)
      return
    }

    const firstPrintArea = printAreas[0]
    if (newIndex >= printAreas.length && firstPrintArea) {
      onPrintAreaChange(firstPrintArea)
    }
  }

  return (
    <div className={clsx('flex items-center', className)}>
      <Button
        size="sm"
        variant="tertiary"
        theme="greyscale"
        className="relative"
        onClick={() => handlePrintAreaChange(selectedPrintAreaIndex - 1)}
      >
        <ChevronLeftIcon className="h-7 w-7 text-gray-700" />
        <span className="sr-only">Previous print area</span>
      </Button>

      <div className="flex flex-1 flex-col text-center text-sm ">
        <span>{formatToSentenceCase(selectedPrintArea)}</span>
        <span className="text-gray-600">
          ({selectedPrintAreaIndex + 1} of {printAreas.length})
        </span>
      </div>

      <Button
        size="sm"
        variant="tertiary"
        theme="greyscale"
        className="relative"
        onClick={() => handlePrintAreaChange(selectedPrintAreaIndex + 1)}
      >
        <ChevronRightIcon className="h-7 w-7 text-gray-700" />
        <span className="sr-only">Next print area</span>
      </Button>
    </div>
  )
}
