import { ReactNode } from 'react'
import { useParams } from 'react-router'
import { PhotographIcon } from '@heroicons/react/solid'
import { ExternalLinkIcon } from '@heroicons/react/outline'

import { useOrderDetail } from 'src/v2/hooks'
import { OrderStatusEnum } from 'src/v2/enums'
import ImageEditorPreview from '../../ImageEditorPreview'
import { getNoImageProductData, isNoImageProduct } from '../../../helpers'
import { OrderDetailItemAssetInterface, OrderDetailItemInterface } from '../../../interfaces'
import { ArtworkInterface, ArtworkTransformationsInterface } from '../../ImageEditor/interfaces'

export function ImagePreview({
  artwork,
  artworkTransformations,
  assetDataFromOrderDetail,
  attributes,
  countryCode,
  lineItem,
  editableContent,
  productType,
  previewImageUrl,
  selectedPrintArea,
  selectedProductData
}: {
  artwork?: ArtworkInterface
  artworkTransformations?: ArtworkTransformationsInterface
  assetDataFromOrderDetail?: OrderDetailItemAssetInterface | null
  attributes: Record<string, string>
  countryCode: string
  lineItem: OrderDetailItemInterface
  editableContent: ReactNode
  productType?: string
  previewImageUrl?: string
  selectedPrintArea: string
  selectedProductData: {
    sku: string
    attributes: Record<string, string> | null
  } | null
}) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  if (productType && isNoImageProduct(productType)) {
    const noImageProductData =
      selectedProductData &&
      getNoImageProductData({
        attributes: selectedProductData.attributes ?? {},
        productType,
        sku: selectedProductData.sku
      })

    return (
      <div className="h-[160px] w-[160px]">
        {noImageProductData ? (
          <div className="flex h-full w-full flex-col border border-gray-200">
            <img
              key={noImageProductData.labImage}
              alt=""
              className="h-full w-full object-contain"
              src={noImageProductData.labImage}
            />
          </div>
        ) : (
          <div className="grid h-full w-full place-content-center border border-gray-200">No image</div>
        )}
      </div>
    )
  }

  if (!orderDetails) {
    throw Error('No order details')
  }

  if (!artworkTransformations && orderDetails.status === OrderStatusEnum.ON_HOLD && previewImageUrl) {
    return (
      <PausedOrderPreview
        key={selectedPrintArea}
        editableContent={editableContent}
        previewImageUrl={previewImageUrl}
        assetDataFromOrderDetail={assetDataFromOrderDetail}
      />
    )
  }

  if (previewImageUrl) {
    return (
      <>
        <ImageEditorPreview
          artwork={artwork}
          artworkTransformations={artworkTransformations}
          attributes={attributes}
          countryCode={countryCode}
          dataTest="line-item-image"
          key={lineItem.id}
          selectedPrintArea={selectedPrintArea}
          sku={lineItem.prodigiSku}
        />
        {editableContent}
      </>
    )
  }

  return (
    <>
      <div className="grid h-[160px] w-[160px] place-content-center border-4 border-gray-200">
        <PhotographIcon className="h-10 w-10 text-gray-600" />
      </div>
      {editableContent}
    </>
  )
}

function PausedOrderPreview({
  assetDataFromOrderDetail,
  editableContent,
  previewImageUrl
}: {
  assetDataFromOrderDetail?: OrderDetailItemAssetInterface | null
  editableContent: ReactNode
  previewImageUrl: string
}) {
  let isPDF = false
  try {
    if (assetDataFromOrderDetail?.assetMimeType) {
      isPDF = assetDataFromOrderDetail?.assetMimeType === 'application/pdf'
    } else {
      isPDF = new URL(previewImageUrl).pathname.endsWith('.pdf')
    }
  } catch (e) {
    console.log({ previewImageUrl })
    console.error(e)
  }

  if (isPDF) {
    return (
      <>
        <div className="grid h-[160px] w-[160px] place-content-center">
          <a
            className="flex h-48 w-48 flex-col items-center justify-center gap-2 border-4 border-status-red/10 md:h-64 md:w-64"
            href={previewImageUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img src="/img/icons/pdf-logo.svg" className="h-12 w-12" />
            <span className="flex items-center gap-2 text-sm text-gray-700">
              <span className="text-sm text-gray-700">pdf file</span>
              <ExternalLinkIcon className="h-5 w-5" />
            </span>
          </a>
        </div>
        {editableContent}
      </>
    )
  }

  return (
    <>
      <div className="grid h-[160px] w-[160px] place-content-center">
        <img
          loading="lazy"
          src={previewImageUrl}
          alt="preview"
          className="h-48 w-48 object-contain object-top md:h-64 md:w-64"
          data-test="line-item-image"
        />
      </div>
      {editableContent}
    </>
  )
}
