import clsx from 'clsx'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { StarIcon } from '@heroicons/react/solid'

import { generateNewSavedItems } from '..'
import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { updateMerchantPreferences } from '../../../helpers'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { MerchantPreferencesErrorInterface, useMerchantPreferences, useMerchantService } from '../../../hooks'

const FAVOURITE_TOAST_IDS = { ERROR: 'prodigiSkuFavouriteErrorToast' }

export function SaveItemToggle({ sku }: { sku: string }) {
  const { merchantDetails } = useMerchantService()
  const { merchantPreferences, mutateMerchantPreferences } = useMerchantPreferences()

  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')
  const [isFavourite, setIsFavourite] = useState(
    () => merchantPreferences?.starredProducts.some((starredProduct) => starredProduct.skuName === sku) ?? false
  )

  useEffect(() => {
    return () => {
      toast.dismiss(FAVOURITE_TOAST_IDS.ERROR)
    }
  }, [])

  async function handleFavouriteToggle(shouldFavourite: boolean) {
    setIsFavourite(shouldFavourite)
    toast.dismiss(FAVOURITE_TOAST_IDS.ERROR)
    setSaveStatus('loading')

    if (shouldFavourite) {
      window.analytics.track('Clicked: Save item', { sku })
    } else {
      window.analytics.track('Remove saved item', { sku })
    }

    try {
      if (!merchantPreferences) {
        throw Error('No merchant preferences')
      }
      await updateMerchantPreferences(
        {
          ...merchantPreferences,
          starredProducts: generateNewSavedItems({ merchantPreferences, sku, shouldFavourite })
        },
        merchantDetails?.id
      )
      await mutateMerchantPreferences()
      setSaveStatus('success')
    } catch (error) {
      const fetchErrorResponse = error as FetchErrorInterface<MerchantPreferencesErrorInterface>
      createErrorToast({
        heading: 'Failed to update saved item',
        id: FAVOURITE_TOAST_IDS.ERROR,
        errorMessage: sku,
        errorCode: `${fetchErrorResponse.responseBodyJson?.traceParent ?? 0}-${fetchErrorResponse.status ?? 0}`
      })
      setIsFavourite(!shouldFavourite)
      setSaveStatus('error')
    }
  }

  return (
    <>
      <button
        className={clsx(
          'ml-3 inline-block align-bottom transition-opacity',
          saveStatus === 'loading' && 'cursor-wait opacity-80',
          !isFavourite && 'group-hover:opacity-100 supports-hover:opacity-0'
        )}
        disabled={saveStatus === 'loading'}
        onClick={() => handleFavouriteToggle(!isFavourite)}
      >
        <motion.div whileTap={{ scale: 0.8 }}>
          <StarIcon className={clsx('h-10 w-10 transition-colors', isFavourite ? 'text-[#FFD907]' : 'text-gray-200')} />
        </motion.div>
      </button>
      {saveStatus === 'loading' && <OverlayPortal className="cursor-wait" />}
    </>
  )
}
