import { EyeIcon, EyeOffIcon, RefreshIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import Button from 'src/v2/components/Button'
import ClipboardCopy from 'src/v2/components/ClipboardCopy'
import { EXTERNAL_URLS } from 'src/v2/constants'
import { useUser } from 'src/v2/hooks'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { ConfirmRegenerateApiKeyModal } from './components'

function ApiPanel({ className = '' }: { className?: string }) {
  const [showConfirmResetApiKeysModal, setShowConfirmResetApiKeysModal] = useState(false)
  const [showApiSecret, setShowApiSecret] = useState(false)
  const { user } = useUser()

  if (!user) {
    throw Error('No user')
  }

  return (
    <SettingsPanel
      className={className}
      title={<h2>API authentication</h2>}
      subtitle={
        <>
          <span className="mt-4 max-w-lg text-gray-600">
            Connect your website or app directly to our manufacturing infrastructure.
          </span>
          <a
            className="w-fit text-purple-500"
            href={EXTERNAL_URLS.PRODIGI.API_DOCS.URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            View documentation.
          </a>
        </>
      }
    >
      <div className="relative md:whitespace-nowrap">
        <label className="text-gray-600">X-API-Key</label>
        <div className="mt-2 flex">
          <span className="break-all">
            {showApiSecret ? (
              user.settings.apiSecret
            ) : (
              <span className="text-gray-800">{user.settings.apiSecret.slice(0, 8)}-XXXX-XXXX-XXXX-XXXXXXXXXXXX</span>
            )}
          </span>
          <span className="flex">
            <ClipboardCopy showText={false} text={user?.settings?.apiSecret ?? ''} />
          </span>
        </div>
      </div>
      <div className="mt-12 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8">
        <Button
          variant="none"
          theme="none"
          className="inline-flex space-x-2 text-purple-500 hover:underline md:w-1/4"
          startIcon={!showApiSecret ? <EyeIcon className="h-8 w-8" /> : <EyeOffIcon className="h-8 w-8" />}
          onClick={() => {
            setShowApiSecret((prev) => !prev)
          }}
        >
          {showApiSecret ? 'Hide' : 'Show'} key
        </Button>
        <Button
          onClick={() => {
            setShowConfirmResetApiKeysModal(true)
          }}
          variant="none"
          theme="none"
          startIcon={<RefreshIcon className="h-8 w-8" />}
          className="inline-flex items-center space-x-2 text-red-600 hover:underline"
        >
          Regenerate API key
        </Button>
      </div>
      <ConfirmRegenerateApiKeyModal open={showConfirmResetApiKeysModal} setOpen={setShowConfirmResetApiKeysModal} />
    </SettingsPanel>
  )
}

export { ApiPanel }
