import { useState } from 'react'
import { InformationCircleIcon } from '@heroicons/react/solid'

import {
  formatCountyLabel,
  formatPostcodeLabel,
  isCountyOrStateRequired,
  isEmailValid,
  isPhoneNumberRequired,
  isPostcodeRequired,
  isPostcodeValid
} from '../../helpers'
import { areAllRequiredAddressFieldsFilledIn, isAddressValid } from './helpers'
import Button from '../Button'
import FormItem from '../FormItem'
import TextField from '../TextField'
import ErrorMessage from '../ErrorMessage'
import { OrderDetailRecipientInterface } from '../../interfaces'

export interface OrderAddressFormPropsInterface {
  children?: React.ReactNode
  formattingCountryCode?: string
  formClassName?: string
  isSaving?: boolean
  orderRecipient?: OrderDetailRecipientInterface
  showContactInfo?: boolean
  onCancel?: () => void
  onSave: (updatedAddress: OrderDetailRecipientInterface) => void
}

const ERROR_MESSAGES = {
  INCOMPLETE_FORM: 'Please complete all required fields.',
  INVALID_POSTCODE: 'Please enter a valid postcode.',
  INVALID_EMAIL: 'Please enter a valid email address.'
}

export default function OrderAddressForm({
  children,
  formattingCountryCode,
  formClassName = '',
  isSaving = false,
  orderRecipient = {
    address: {
      countryCode: '',
      line1: '',
      line2: '',
      postcodeOrZipCode: '',
      stateOrCounty: '',
      townOrCity: ''
    },
    email: '',
    name: '',
    phone: ''
  },
  showContactInfo = true,
  onCancel,
  onSave
}: OrderAddressFormPropsInterface) {
  const [orderAddress, setOrderAddress] = useState<OrderDetailRecipientInterface>(orderRecipient)

  const addressIsValid = isAddressValid(orderAddress, showContactInfo)
  const emailValid = orderAddress.email === '' ? true : isEmailValid(orderAddress.email)
  const phoneNumberRequired = isPhoneNumberRequired(orderAddress.address.countryCode)
  const countyOrStateRequired = isCountyOrStateRequired(orderAddress.address.countryCode)
  const postcodeRequired = isPostcodeRequired(orderAddress.address.countryCode)
  const postcodeValid = isPostcodeValid(orderAddress.address.countryCode, orderAddress.address.postcodeOrZipCode)
  const validationMessage = validationErrorMessage()

  function validationErrorMessage(): string | null {
    if (!areAllRequiredAddressFieldsFilledIn(orderAddress, showContactInfo)) {
      return ERROR_MESSAGES.INCOMPLETE_FORM
    } else if (postcodeRequired && !postcodeValid) {
      return ERROR_MESSAGES.INVALID_POSTCODE
    } else if (!emailValid) {
      return ERROR_MESSAGES.INVALID_EMAIL
    } else {
      return null
    }
  }

  return (
    <form
      className={`${formClassName} flex flex-col items-stretch space-y-7`}
      onSubmit={(event) => {
        event.preventDefault()
        onSave(orderAddress)
      }}
    >
      <FormItem
        dataTest="customer-name-form-item"
        inputField={
          <TextField
            dataTest="recipient-name"
            id="recipient-name-field"
            type="text"
            value={orderAddress.name}
            onChange={(e) =>
              setOrderAddress({
                ...orderAddress,
                name: e.target.value
              })
            }
          />
        }
        labelClassName="pt-0 mt-4 pb-2"
        labelText="Customer Name"
        required
        valid={Boolean(orderAddress.name)}
        validate={Boolean(orderAddress.name)}
      />

      <div>
        <FormItem
          dataTest="customer-address-line-1-form-item"
          inputField={
            <TextField
              dataTest="recipient-address-line-1"
              id="recipient-address-line-1-field"
              type="text"
              value={orderAddress.address.line1}
              onChange={(e) =>
                setOrderAddress({
                  ...orderAddress,
                  address: {
                    ...orderAddress.address,
                    line1: e.target.value
                  }
                })
              }
            />
          }
          labelText="Address"
          required
          valid={Boolean(orderAddress.address.line1)}
          validate={Boolean(orderAddress.address.line1)}
        />

        <FormItem
          dataTest="address-line-2-form-item"
          inputField={
            <TextField
              dataTest="recipient-address-line-2"
              id="recipient-address-line-2-field"
              type="text"
              value={orderAddress.address.line2}
              onChange={(e) =>
                setOrderAddress({
                  ...orderAddress,
                  address: {
                    ...orderAddress.address,
                    line2: e.target.value
                  }
                })
              }
            />
          }
          labelClassName="pt-4 mt-4 pb-2"
          validate={false}
        />
      </div>

      <FormItem
        dataTest="address-town-form-item"
        inputField={
          <TextField
            dataTest="recipient-address-town"
            id="recipient-address-town-field"
            type="text"
            value={orderAddress.address.townOrCity}
            onChange={(e) =>
              setOrderAddress({
                ...orderAddress,
                address: {
                  ...orderAddress.address,
                  townOrCity: e.target.value
                }
              })
            }
          />
        }
        labelText="Town/City"
        required
        valid={Boolean(orderAddress.address.townOrCity)}
        validate={Boolean(orderAddress.address.townOrCity)}
      />

      <FormItem
        dataTest="address-county-form-item"
        inputField={
          <TextField
            dataTest="recipient-address-county"
            id="recipient-address-county-field"
            type="text"
            value={orderAddress.address.stateOrCounty}
            onChange={(e) =>
              setOrderAddress({
                ...orderAddress,
                address: {
                  ...orderAddress.address,
                  stateOrCounty: e.target.value
                }
              })
            }
          />
        }
        labelText={formatCountyLabel(formattingCountryCode || orderAddress.address.countryCode)}
        required={countyOrStateRequired}
        valid={countyOrStateRequired ? Boolean(orderAddress.address.stateOrCounty) : true}
        validate={Boolean(countyOrStateRequired && orderAddress.address.stateOrCounty)}
      />

      <FormItem
        dataTest="postcode-form-item"
        inputField={
          <TextField
            dataTest="recipient-address-postcode"
            id="recipient-address-postcode-field"
            type="text"
            uppercase
            value={orderAddress.address.postcodeOrZipCode}
            onChange={(e) =>
              setOrderAddress({
                ...orderAddress,
                address: {
                  ...orderAddress.address,
                  postcodeOrZipCode: e.target.value
                }
              })
            }
          />
        }
        labelText={formatPostcodeLabel(formattingCountryCode || orderAddress.address.countryCode)}
        required={postcodeRequired}
        valid={postcodeValid}
        validate={Boolean(postcodeRequired && orderAddress.address.postcodeOrZipCode)}
      />

      {children}

      {showContactInfo && (
        <div className="space-y-7" data-test="address-contact-message-container">
          <div className="mt-4 pt-2">
            <InformationCircleIcon className="float-left mr-2 h-8 w-8 text-cyan-600" />
            <p className="my-0">
              Where customer contact information can be provided, it will help to ensure that there are no delays for
              packages passing through international borders and customs checks.
            </p>
          </div>

          <FormItem
            dataTest="email-form-item"
            inputField={
              <TextField
                dataTest="recipient-email"
                id="recipient-email-field"
                type="email"
                value={orderAddress.email}
                onChange={(e) =>
                  setOrderAddress({
                    ...orderAddress,
                    email: e.target.value
                  })
                }
              />
            }
            labelText="Email"
            valid={emailValid}
            validate={Boolean(orderAddress.email)}
          />

          <FormItem
            dataTest="phone-form-item"
            labelText="Phone"
            inputField={
              <TextField
                type="tel"
                id="recipient-phone-field"
                value={orderAddress.phone}
                dataTest="recipient-phone"
                onChange={(e) =>
                  setOrderAddress({
                    ...orderAddress,
                    phone: e.target.value
                  })
                }
              />
            }
            required={phoneNumberRequired}
            valid={Boolean(orderAddress.phone)}
            validate={Boolean(phoneNumberRequired && orderAddress.phone)}
          />
        </div>
      )}

      {!addressIsValid && validationMessage && (
        <ErrorMessage className="mt-2 flex w-full" message={validationMessage} showHeading={false} />
      )}

      <span className="sticky bottom-0 flex flex-wrap justify-start gap-4 whitespace-nowrap bg-white pb-2 sm:gap-8">
        <Button className="mt-6 w-fit" disabled={!addressIsValid || isSaving} isLoading={isSaving} type="submit">
          <span>
            Save <span className="hidden sm:inline">and close</span>
          </span>
        </Button>

        <Button className="mt-6 w-fit" variant="secondary" onClick={onCancel}>
          <span>
            Cancel <span className="hidden sm:inline">changes</span>
          </span>
        </Button>
      </span>
    </form>
  )
}
