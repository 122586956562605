import clsx from 'clsx'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { PhotographIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'

import Checkbox from '../../Checkbox'
import { IMAGE_LIBRARY_TILE_TOASTS } from '../constants'
import { ImageLibraryImageInterface } from '../../../hooks'
import ImageLibraryFileTypePill from '../../ImageLibraryFileTypePill'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'

interface ImageTilePropsInterface {
  className?: string
  imageLibraryImage: ImageLibraryImageInterface
  imageLibraryMode: ImageLibraryFullPageModeType
  index: number
  selectedImages: ImageLibraryImageInterface[]
  onImageSelectionUpdate: (selected: ImageLibraryImageInterface[]) => void
}

export function FullPageImageTile({
  className,
  imageLibraryImage,
  imageLibraryMode,
  index,
  selectedImages,
  onImageSelectionUpdate
}: ImageTilePropsInterface) {
  const isImageSelected = selectedImages.some((image) => image.public_id === imageLibraryImage.public_id)

  useEffect(() => {
    return () => {
      toast.dismiss(IMAGE_LIBRARY_TILE_TOASTS.ERROR)
    }
  }, [])

  function handleImageSelection() {
    const updatedList = [...selectedImages]

    if (!updatedList.some((i) => i.public_id === imageLibraryImage.public_id)) {
      updatedList.push(imageLibraryImage)
      onImageSelectionUpdate(updatedList)
    } else {
      onImageSelectionUpdate(updatedList.filter((i) => i.public_id !== imageLibraryImage.public_id))
    }
  }

  const imageDimensionsLabel =
    imageLibraryImage.pixel_width && imageLibraryImage.pixel_height ? (
      <>
        {imageLibraryImage.pixel_width}
        {'x'}
        {imageLibraryImage.pixel_height}
        {'px'}
      </>
    ) : null

  const pageCountLabel =
    imageLibraryImage.pdf_page_count && imageLibraryImage.pdf_page_count > 1 ? (
      <>
        {imageLibraryImage.pdf_page_count}
        {' pages'}
      </>
    ) : null

  return (
    <div className={clsx('relative mx-auto min-h-[200px] w-full md:min-h-[260px]', className)}>
      <div data-test={`image-library-tile-${index}`} className="group w-full text-left text-gray-700 antialiased">
        <figure>
          <ImageThumbnail imageLibraryImage={imageLibraryImage} />
          <figcaption className="mt-1 break-words font-medium text-gray-900 line-clamp-2">
            {imageLibraryImage.name}
          </figcaption>
        </figure>

        <div className="flex items-center justify-between text-sm">
          <span className="flex-1">{pageCountLabel || imageDimensionsLabel}</span>
          <ImageLibraryFileTypePill fileType={imageLibraryImage.file_type} />
        </div>
      </div>

      <AnimatePresence>
        {imageLibraryMode === 'manage' && (
          <motion.button
            className="absolute inset-0 grid aspect-square w-full place-content-start bg-white/25 pt-4 pl-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={handleImageSelection}
          >
            <Checkbox id="manageImage" checked={isImageSelected} disabled={false} readOnly={true} />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  )
}

function ImageThumbnail({ imageLibraryImage }: { imageLibraryImage: ImageLibraryImageInterface }) {
  if (!imageLibraryImage.thumbnail_urls.small) {
    return (
      <div className="grid aspect-square w-full place-content-center bg-gray-100">
        <PhotographIcon className="h-10 w-10 text-gray-400" />
      </div>
    )
  }

  return (
    <div className="relative flex aspect-square items-center justify-center">
      <img
        alt={imageLibraryImage.name}
        className="block aspect-square h-auto w-full max-w-full object-cover object-center opacity-100 transition-opacity group-hover:opacity-0"
        loading="lazy"
        src={imageLibraryImage.thumbnail_urls.small}
      />
      <img
        alt={imageLibraryImage.name}
        className={`absolute inset-0 m-auto block opacity-0 transition-opacity group-hover:opacity-100 ${
          (imageLibraryImage.pixel_width ?? 0) > (imageLibraryImage.pixel_height ?? 0)
            ? 'h-auto w-full'
            : 'h-full w-auto'
        } `}
        loading="lazy"
        src={imageLibraryImage.thumbnail_urls.small}
      />
    </div>
  )
}
