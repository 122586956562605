import { CheckIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

type AlertType = 'ERROR' | 'WARNING' | 'SUCCESS'

interface AlertPanelPropsInterface {
  alertType?: AlertType
  children: React.ReactNode
  className?: string
  isVisible: boolean
  heading?: string
  onClose: () => void
}

export default function AlertPanel({
  alertType = 'ERROR',
  className = '',
  children,
  heading = '',
  isVisible,
  onClose
}: AlertPanelPropsInterface) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          className={cx('w-full rounded-lg p-8', className, {
            'bg-red-100': alertType === 'ERROR',
            'bg-yellow-100': alertType === 'WARNING',
            'bg-green-100': alertType === 'SUCCESS'
          })}
          exit={{ opacity: 0, scale: 0 }}
          initial={{ opacity: 0, y: 10 }}
        >
          <header className="mb-6 flex items-center">
            {alertType === 'ERROR' && <ExclamationIcon className="h-8 w-8 text-red-800" />}
            {alertType === 'WARNING' && <InformationCircleIcon className="h-8 w-8 text-yellow-800" />}
            {alertType === 'SUCCESS' && <CheckIcon className="h-8 w-8 text-green-800" />}

            <p className="m-0 ml-2">{heading}</p>

            <button className="ml-auto p-3" onClick={onClose}>
              <XIcon className="h-8 w-8" />
              <span className="sr-only">Close</span>
            </button>
          </header>

          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
