import clsx from 'clsx'
import { XIcon } from '@heroicons/react/outline'

import Button from '../../Button'

interface ModalHeaderPropsInterface {
  className?: string
  subtitle: string | null | React.ReactNode
  title: string | null
  onClose: () => void
}

export function ModalHeader({ className = '', subtitle, title, onClose }: ModalHeaderPropsInterface) {
  return (
    <div className={clsx('items-top flex justify-between py-8 md:py-12', className)}>
      <div className="flex flex-col justify-center pr-4 text-gray-900 md:pr-12" data-test="modal-header">
        {title && (
          <span className="flex items-center">
            <h2 className="mt-0">{title}</h2>
          </span>
        )}
        {subtitle && <div className="mt-1 text-gray-600">{subtitle}</div>}
      </div>

      <div className={`flex h-full items-center justify-end`}>
        <Button
          className="col-start-10 flex items-center text-gray-600 duration-200 hover:text-purple-500 focus:text-purple-500 focus-visible:outline-purple-500"
          variant="none"
          theme="none"
          onClick={onClose}
        >
          <XIcon className="h-8 w-8 md:h-12 md:w-12" />
        </Button>
      </div>
    </div>
  )
}
