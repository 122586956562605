import LoadingSpinner from '../../LoadingSpinner'
import { EmailAvailabilityStatusEnum } from '../enums'
import { EmailAvailabilityResultInterface } from '../interfaces'

import { CheckIcon, XIcon } from '@heroicons/react/solid'

interface PropTypesInterface {
  emailError: string | null
  emailAvailability: EmailAvailabilityResultInterface | null
}

export function EmailAvailableIndicator({ emailError, emailAvailability }: PropTypesInterface) {
  return (
    <span className="absolute right-6 top-0 bottom-0 mt-1 flex h-full w-6 items-center justify-center">
      {!emailError && emailAvailability?.availability === EmailAvailabilityStatusEnum.Loading && (
        <LoadingSpinner className="h-6 w-6" />
      )}
      {!emailError && emailAvailability?.availability === EmailAvailabilityStatusEnum.Available && (
        <CheckIcon className="h-6 w-6 text-green-500" />
      )}
      {emailError && emailAvailability?.availability === EmailAvailabilityStatusEnum.Unavailable && (
        <XIcon className="h-6 w-6 text-red-500" />
      )}
    </span>
  )
}
