import { useState } from 'react'
import toast from 'react-hot-toast'
import { PhotographIcon } from '@heroicons/react/outline'

import Button from '../../Button'
import { createToast } from '../../Toast'
import { fetcher } from '../../../helpers'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import OverlayPortal from '../../OverlayPortal'
import { IMAGE_LIBRARY_TOASTS } from '../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { FetchErrorInterface, StatusType } from '../../../interfaces'
import { ImageLibraryImageInterface, useImageLibraryImages } from '../../../hooks'
import { getImageExtentionFromImageLibraryMimeType } from '../helpers/getImageExtensionFromMimeType.helper'

interface DeleteFromImageLibraryButtonPropsInterface {
  imageLibraryImage: ImageLibraryImageInterface
}

export function DeleteFromImageLibraryButton({ imageLibraryImage }: DeleteFromImageLibraryButtonPropsInterface) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  return (
    <>
      <Button variant="primary" theme="danger" onClick={() => setIsDeleteModalOpen(true)}>
        Delete
      </Button>

      <DeleteFromImageLibaryModal
        imageLibraryImage={imageLibraryImage}
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
      />
    </>
  )
}

interface DeleteFromImageLibaryModalPropsInterface {
  imageLibraryImage: ImageLibraryImageInterface
  open: boolean
  setOpen: (isOpen: boolean) => void
}

function DeleteFromImageLibaryModal({ imageLibraryImage, open, setOpen }: DeleteFromImageLibaryModalPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()

  const [imageDeletionStatus, setImageDeletionStatus] = useState<StatusType>('idle')

  async function handleDeleteImage() {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    setImageDeletionStatus('loading')
    window.analytics.track('Image library - clicked: Delete image')

    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/${imageLibraryImage.public_id}`, {
        method: 'DELETE'
      })
      await mutateImageLibraryImages()
      createToast({ heading: 'Image deleted', type: 'success' })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>
      const errorMessage = errorResponse.responseBodyJson?.detail
      const errorCode = errorResponse.status ?? '0'

      createToast({
        content: (
          <>
            {errorMessage && <p className="mb-2">{errorMessage}</p>}
            <p>
              Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
            </p>
          </>
        ),
        duration: Infinity,
        footer: (
          <span className="text-sm text-gray-600">
            Code: {errorCode} <ClipboardCopy showText={false} text={errorCode.toString()} />
          </span>
        ),
        heading: 'Failed to delete image',
        id: IMAGE_LIBRARY_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setImageDeletionStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setImageDeletionStatus('idle')
      toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    }
    setOpen(isOpen)
  }

  const imageDimensions =
    imageLibraryImage.pixel_width && imageLibraryImage.pixel_height
      ? { width: imageLibraryImage.pixel_width, height: imageLibraryImage.pixel_height }
      : null

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        closeOnEscape={imageDeletionStatus !== 'loading'}
        closeOnInteractionOutside={imageDeletionStatus !== 'loading'}
        continueButton={{ text: 'Delete image' }}
        isLoading={imageDeletionStatus === 'loading'}
        title="Delete image?"
        open={open}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleDeleteImage}
      >
        <div className="flex min-w-80vw gap-6 sm:min-w-[450px]">
          <ImageThumbnail imageLibraryImage={imageLibraryImage} />
          <div className="flex flex-col justify-center">
            <strong className="max-w-[300px] break-words">{imageLibraryImage.name}</strong>
            <span className="text-gray-600">
              {imageDimensions ? (
                <>
                  {imageDimensions.width}x{imageDimensions.height},{' '}
                  {getImageExtentionFromImageLibraryMimeType(imageLibraryImage.mime_type)}
                </>
              ) : (
                <>{getImageExtentionFromImageLibraryMimeType(imageLibraryImage.mime_type)}</>
              )}
            </span>
          </div>
        </div>
      </ConfirmationModal>

      {imageDeletionStatus === 'loading' && <OverlayPortal />}
    </>
  )
}

function ImageThumbnail({ imageLibraryImage }: { imageLibraryImage: ImageLibraryImageInterface }) {
  if (!imageLibraryImage.thumbnail_urls.small) {
    return (
      <div className="grid h-[120px] w-[120px] flex-shrink-0 place-content-center bg-gray-100">
        <PhotographIcon className="h-8 w-8 text-gray-400" />
      </div>
    )
  }

  return (
    <img
      alt={imageLibraryImage.name}
      className="block h-[120px] w-[120px] flex-shrink-0 object-cover object-center"
      loading="lazy"
      src={imageLibraryImage.thumbnail_urls.small}
    />
  )
}
