import { motion } from 'framer-motion'

import Button from '../../Button'
import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import { SearchResultItem } from './SearchResultItem.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { SkeletonSearchItem } from './SkeletonSearchItem.component'
import { useProducts, useProductSearch, useSplitToggle } from '../../../hooks'
import { ProductAdditionalDataInterface, SelectedProductInterface } from '../SelectProduct.component'

interface ProductSearchPropsInterface {
  category: string | null
  countryCode: string
  defaultSearchQuery: string
  defaultSelectedAttributes: Record<string, string>
  productType: string | null
  selectingSkuName?: string
  searchQuery: string
  showCountryPicker: boolean
  onClearSearch: () => void
  onSelectProduct?: (selectedProduct: SelectedProductInterface) => void
  onSelectProductWithAdditionalData?: (
    selectedProduct: SelectedProductInterface,
    additionalProductData: ProductAdditionalDataInterface
  ) => void
}

export function ProductSearch({
  category,
  countryCode,
  defaultSearchQuery,
  defaultSelectedAttributes,
  productType,
  selectingSkuName,
  searchQuery,
  showCountryPicker,
  onClearSearch,
  onSelectProduct,
  onSelectProductWithAdditionalData
}: ProductSearchPropsInterface) {
  const {
    error: searchResultsFetchError,
    isLoading: isLoadingSearchResults,
    searchResults
  } = useProductSearch({ countryCode, category, productType, query: searchQuery, config: { revalidateOnFocus: false } })

  const searchResultSkus = searchResults?.map((searchResult) => searchResult.sku)

  const { splitIsOn: isCostedAttributesOn } = useSplitToggle({ toggle: FEATURE_NAMES.COSTED_ATTRIBUTES })
  const { products, isLoadingProducts, productsFetchError } = useProducts(
    isCostedAttributesOn ? searchResultSkus : undefined,
    {
      config: { revalidateOnFocus: false }
    }
  )

  const shouldUseDefaultSelectedAttributes = defaultSearchQuery === searchQuery

  if (searchResultsFetchError) {
    return <SearchError errorCode={`SRFE-${searchResultsFetchError.status ?? '0'}`} onClearSearch={onClearSearch} />
  }

  if (productsFetchError) {
    return <SearchError errorCode={`CSRFE-${productsFetchError.status ?? '0'}`} onClearSearch={onClearSearch} />
  }

  if (isLoadingSearchResults || !searchResults || isLoadingProducts) {
    return (
      <ul className="border border-gray-200">
        {Array.from({ length: 3 }, (_, i) => i).map((value) => (
          <SkeletonSearchItem key={value} />
        ))}
      </ul>
    )
  }

  if (searchResults.length === 0 || !searchResultSkus) {
    return (
      <div className="border border-gray-200 bg-white">
        <div className="mx-auto my-32 max-w-5xl">
          <div className="px-6 text-center">
            <strong>No products found</strong>
          </div>

          <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: -10, opacity: 0 }}>
            <Button className="mx-auto mt-8" variant="secondary" onClick={onClearSearch}>
              Clear search & filters
            </Button>
          </motion.div>
        </div>
      </div>
    )
  }

  return (
    <ul className="border border-gray-200">
      {searchResults.map((searchResult) => {
        const product = products?.[searchResult.sku]

        return (
          <SearchResultItem
            key={searchResult.sku}
            countryCode={countryCode}
            defaultSelectedAttributes={shouldUseDefaultSelectedAttributes ? defaultSelectedAttributes : {}}
            product={product ?? null}
            searchResult={searchResult}
            searchResultSkus={searchResultSkus}
            selectingSkuName={selectingSkuName}
            showCountryPicker={showCountryPicker}
            onSelectProduct={onSelectProduct}
            onSelectProductWithAdditionalData={onSelectProductWithAdditionalData}
          />
        )
      })}
    </ul>
  )
}

function SearchError({ errorCode, onClearSearch }: { errorCode?: string; onClearSearch: () => void }) {
  return (
    <div className="mt-12 flex w-full justify-center">
      <NotFound
        heading="Failed to load results"
        body={
          <p className="break-words text-center">
            An error occurred while loading search results. Please try again later and{' '}
            <SupportLink>contact us</SupportLink> if this issue persists (code - {errorCode})
          </p>
        }
      >
        <Button className="mx-auto mt-8" onClick={onClearSearch}>
          Clear search
        </Button>
      </NotFound>
    </div>
  )
}
