import { useState } from 'react'
import toast from 'react-hot-toast'
import { DocumentTextIcon } from '@heroicons/react/outline'

import Modal from '../../Modal'
import Button from '../../Button'
import { createToast } from '../../Toast'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { fetcher } from '../../../helpers'
import ClipboardCopy from '../../ClipboardCopy'
import { getOrdersExportRequestTimestamps } from '../helpers'
import { ExportOptionsFormDataInterface } from '../interfaces'
import { OrdersExportForm } from './OrdersExportForm.component'
import { FetchErrorInterface, OMSErrorResponseInterface, OMSResponseInterface } from '../../../interfaces'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function OrdersExport() {
  const [isOrdersExportModalOpen, setIsOrdersExportModalOpen] = useState(false)

  return (
    <>
      <Button
        startIcon={<DocumentTextIcon className="h-7 w-7" />}
        variant="tertiary"
        theme="greyscale"
        onClick={() => {
          window.analytics.track('Clicked Export')
          setIsOrdersExportModalOpen(true)
        }}
      >
        Export
      </Button>

      <OrdersExportFormModal open={isOrdersExportModalOpen} setOpen={setIsOrdersExportModalOpen} />
    </>
  )
}

/* -------------------------------------------------------------------------- */
/*                              Helper components                             */
/* -------------------------------------------------------------------------- */

interface OrdersExportModalPropsInterface {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

const ORDER_EXPORT_SUCCESS_TOAST_ID = 'orderExportSuccessToast'
const ORDER_EXPORT_ERROR_TOAST_ID = 'orderExportErrorToast'

function OrdersExportFormModal({ open, setOpen }: OrdersExportModalPropsInterface) {
  const [ordersExportStatus, setOrdersExportStatus] = useState<StatusEnum>(StatusEnum.Idle)

  async function handleSubmit(data: ExportOptionsFormDataInterface) {
    setOrdersExportStatus(StatusEnum.Loading)
    toast.dismiss(ORDER_EXPORT_ERROR_TOAST_ID)
    toast.dismiss(ORDER_EXPORT_SUCCESS_TOAST_ID)

    window.analytics.track('Orders Export Requested', {
      createdSinceDateOption: data.createdSinceDateOption,
      email: data.email.value
    })

    const { createdBeforeOrOn, createdSince } = getOrdersExportRequestTimestamps(data.createdSinceDateOption)

    try {
      await fetcher<OMSResponseInterface>(`${process.env.REACT_APP_PRODIGI_OMS}/orders/exports/queue`, {
        body: JSON.stringify({
          emailAddress: data.email.value,
          exportType: 'OrderDetails',
          filters: {
            createdSince,
            createdBeforeOrOn
          }
        }),
        method: 'POST'
      })

      setOrdersExportStatus(StatusEnum.Success)
      setOpen(false)

      createToast({
        content: `Your report will be sent to ${data.email.value}`,
        duration: 5000,
        heading: 'Generating order export',
        id: ORDER_EXPORT_SUCCESS_TOAST_ID,
        type: 'success'
      })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>

      let toastFooter
      if (errorResponse.responseBodyJson?.traceParent) {
        toastFooter = (
          <span className="break-all">
            Code: {errorResponse.responseBodyJson.traceParent}{' '}
            <ClipboardCopy text={errorResponse.responseBodyJson.traceParent} showText={false} />
          </span>
        )
      } else if (errorResponse.message) {
        toastFooter = `Code: ${errorResponse.message}`
      } else {
        toastFooter = `Code: ${errorResponse.status}`
      }

      createToast({
        content: 'We are unable process your export',
        duration: Infinity,
        footer: toastFooter,
        heading: 'Could not export orders',
        id: ORDER_EXPORT_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setOrdersExportStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(open: boolean) {
    if (!open) {
      setOrdersExportStatus(StatusEnum.Idle)
      toast.dismiss(ORDER_EXPORT_ERROR_TOAST_ID)
    }
    setOpen(open)
  }

  return (
    <Modal
      closeOnInteractionOutside={ordersExportStatus !== StatusEnum.Loading}
      open={open}
      subtitle="In CSV format"
      title="Export orders"
      setOpen={handleOpenChange}
    >
      <div className="min-w-80vw sm:min-w-[450px]">
        <OrdersExportForm
          isLoading={ordersExportStatus === StatusEnum.Loading}
          onCancel={() => handleOpenChange(false)}
          onSubmit={handleSubmit}
        />
      </div>

      {ordersExportStatus === StatusEnum.Loading && <OverlayPortal />}
    </Modal>
  )
}
