// @flow
import type { LocalStorageOrder } from '../../types'
import { UNFINISHED_ORDER } from '../../data/localStorageKeys'
import Option from '../../helpers/Option'

export function saveOrderInLocalStorage (order: LocalStorageOrder): void {
  window.localStorage.setItem(UNFINISHED_ORDER, JSON.stringify(order))
}

export function getOrderFromLocalStorage (): Option<LocalStorageOrder> {
  const serialisedOrder = window.localStorage.getItem(UNFINISHED_ORDER)

  return serialisedOrder
    ? tryParse(serialisedOrder)
    : Option.none()
}

function tryParse (serialisedOrder: string): Option<LocalStorageOrder> {
  try {
    return Option.some(JSON.parse(serialisedOrder))
  } catch {
    return Option.none()
  }
}

export function removeUnfinishedOrderFromLocalStorage (): void {
  window.localStorage.removeItem(UNFINISHED_ORDER)
}
