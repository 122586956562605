import { useMemo } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import { useHistory, useLocation } from 'react-router'

import Button from '../../Button'
import { QUERY_PARAMS } from '../../../constants'
import ImageLibraryModal from '../../ImageLibraryModal'
import { ImageLibraryFileType, ImageLibraryImageInterface, useSplitToggle } from '../../../hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

export const PACKING_SLIP_ALLOWED_FILE_TYPES: ImageLibraryFileType[] = ['png', 'jpg', 'pdf (single page)']

export function UploadPackingSlip({
  className,
  isImageLibraryModalOpen,
  isLoading,
  handleUploadPackingUpdate,
  onOpenImageLibrary,
  setIsImageLibraryModalOpen
}: {
  className?: string
  isImageLibraryModalOpen: boolean
  isLoading: boolean
  handleUploadPackingUpdate: (imageLibraryImage: ImageLibraryImageInterface) => Promise<void>
  onOpenImageLibrary?: () => void
  setIsImageLibraryModalOpen: (open: boolean) => void
}) {
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function onSetIsImageLibraryModalOpen(open: boolean) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpen(open)
  }

  return (
    <div className="my-6 flex gap-3">
      <Button
        className={className}
        dataTest="add-packing-slip-button"
        isLoading={isLoading}
        size="sm"
        variant="secondary"
        startIcon={<PlusIcon className="h-6 w-6" />}
        onClick={() => {
          const newSearchParams = new URLSearchParams(searchParams)
          newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
          if (isPDFSplitOn) {
            newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))
          }
          history.replace({ pathname, search: newSearchParams.toString() })

          setIsImageLibraryModalOpen(true)
          if (onOpenImageLibrary) {
            onOpenImageLibrary()
          }
        }}
      >
        Add packing slip
      </Button>

      <ImageLibraryModal
        config={{ forceSaveToLibrary: false }}
        open={isImageLibraryModalOpen}
        type="packing"
        setOpen={onSetIsImageLibraryModalOpen}
        onSelectImage={handleUploadPackingUpdate}
      />
    </div>
  )
}
