/**
• ^ asserts the start of the string.
• [...] defines a character class that includes:
  • \w which matches any alphanumeric character (letters and numbers) and underscores.
  • \s which matches any whitespace character (spaces, tabs, etc.).
  • Common punctuation marks & math symbols like .,;:!?'"(){}[\]/\\<>\-@#$%^&*±×÷=+¬~`|€£¥•
  • Currency symbols: €£¥.
  • Accented characters from \u00C0-\u00FF which covers many accented Latin characters.
• + ensures that the pattern matches one or more of the allowed characters.
• $ asserts the end of the string.
 */
export const PHOTOBOOK_SPINE_TEXT_REGEX = /^[\w\s.,;:!?'"(){}[\]/\\<>\-@#$%^&*±×÷=+¬~`|€£¥•\u00C0-\u00FF]+$/
