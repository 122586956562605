import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { InboxIcon, UploadIcon, PlusCircleIcon } from '@heroicons/react/solid'

import { ROUTE_PATHS } from '../../../constants'
import { useOrdersOverview, useSplitToggle } from '../../../hooks'
import { NavGroupTitle } from './NavGroupTitle.component'
import { NavGroupListContainer } from './NavGroupListContainer.component'
import { FEATURE_NAMES } from 'src/split-io/feature-names'

export function NavGroupOrders({ className }: { className?: string }) {
  const { orderOverviewResponse } = useOrdersOverview()
  const numberOfOrdersRequiringAttention = orderOverviewResponse?.data?.requiresAttention ?? 0
  const numberOfOrdersToDisplay = numberOfOrdersRequiringAttention > 99 ? '99+' : numberOfOrdersRequiringAttention
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })

  return (
    <div className={className}>
      <NavGroupTitle>Orders</NavGroupTitle>

      <NavGroupListContainer>
        <li>
          <Link to={ROUTE_PATHS.ORDERS} className="side-nav-item" data-test="navigation__orders">
            <InboxIcon className="h-7 w-7" />
            <div className="relative flex flex-1 items-center justify-start gap-2 md:justify-between">
              <span>All orders</span>
              <AnimatePresence>
                {Boolean(numberOfOrdersToDisplay) && (
                  <motion.div
                    className="ml-4 flex aspect-square items-center justify-center rounded-md bg-cyan-100 px-1 text-xs font-bold text-purple-950"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {numberOfOrdersToDisplay}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </Link>
        </li>

        {!isShopifySplitOn && <li>
          <Link to={ROUTE_PATHS.BULK_UPLOAD} className="side-nav-item">
            <UploadIcon className="h-7 w-7" />
            Import orders
          </Link>
        </li>}

        {!isShopifySplitOn && <li>
          <Link to={ROUTE_PATHS.CREATE_ORDER} className="side-nav-item">
            <PlusCircleIcon className="h-7 w-7" />
            Create order
          </Link>
        </li>}
      </NavGroupListContainer>
    </div>
  )
}
