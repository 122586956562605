import { useMemo, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import { useHistory, useLocation } from 'react-router'

import Button from '../../Button'
import { QUERY_PARAMS } from '../../../constants'
import { ArtworkTransformationsInterface } from '../interfaces'
import { V1DashboardArtworkInterface } from '../../../interfaces'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { EditorImageLibraryModal } from './EditorImageLibraryModal.component'
import { TemplateServiceSuccessResponseInterface, useSplitToggle } from '../../../hooks'
import { ImageLibraryConfigPropInterface } from '../../ImageLibrary/ImageLibrary.component'
import { generateImageLibraryFilterParamsForArtwork } from '../../../helpers/generateImageLibraryFilterParamsForArtwork.helper'

interface AddImageFromLibraryPropsInteface {
  imageLibraryConfig?: ImageLibraryConfigPropInterface
  itemCategory: string
  productType: string
  printAreaName: string
  templates: TemplateServiceSuccessResponseInterface
  onOpenImageLibrary?: () => void
  onSelectImageLibraryImage: (artworkData: {
    artwork: V1DashboardArtworkInterface
    artworkTransformations: ArtworkTransformationsInterface
    selectedPrintArea: string
  }) => void
  onTogglePreviewMode: (isPreviewOnly: boolean) => void
}

export function AddImageFromLibrary({
  imageLibraryConfig,
  itemCategory,
  productType,
  printAreaName,
  templates,
  onOpenImageLibrary,
  onSelectImageLibraryImage,
  onTogglePreviewMode
}: AddImageFromLibraryPropsInteface) {
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  function onSetIsImageLibraryModalOpen(open: boolean) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpen(open)
  }

  return (
    <div className="pointer-events-none absolute inset-0 grid place-content-center">
      <Button
        startIcon={<PlusIcon className="h-8 w-8" />}
        dataTest="add-image-button"
        className="pointer-events-auto min-w-[186px]"
        onClick={() => {
          window.analytics.track('Order editing - clicked add image')

          const newSearchParams = generateImageLibraryFilterParamsForArtwork({ searchParams, productType })
          if (!isPDFSplitOn) {
            newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
          }

          history.replace({ pathname, search: newSearchParams.toString() })

          setIsImageLibraryModalOpen(true)
          if (onOpenImageLibrary) {
            onOpenImageLibrary()
          }
        }}
      >
        Add image
      </Button>

      <EditorImageLibraryModal
        open={isImageLibraryModalOpen}
        imageLibraryConfig={imageLibraryConfig}
        itemCategory={itemCategory}
        productType={productType}
        printAreaName={printAreaName}
        templates={templates}
        setOpen={onSetIsImageLibraryModalOpen}
        onSelectImageLibraryImage={onSelectImageLibraryImage}
        onTogglePreviewMode={onTogglePreviewMode}
      />
    </div>
  )
}
