import { ReactNode } from 'react'
import { useSWRConfig } from 'swr'
import { twMerge } from 'tailwind-merge'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CogIcon, LogoutIcon, UserIcon } from '@heroicons/react/outline'

import { logOut } from '../../../../actions/auth'
import SingleSelectDropdown from '../../SingleSelectDropdown'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'

type AccountNavItemValueType = 'settings' | 'logout'

const ACCOUNT_NAV_ITEM_OPTIONS: Array<{ content: ReactNode; searchLabel: string; value: AccountNavItemValueType }> = [
  {
    content: (
      <span className="flex items-center pr-10">
        <CogIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
        Settings
      </span>
    ),
    searchLabel: 'Settings',
    value: 'settings'
  },
  {
    content: (
      <span className="flex items-center pr-10">
        <LogoutIcon className="h-12 w-12 pr-4 text-gray-600" aria-hidden="true" />
        Log out
      </span>
    ),
    searchLabel: 'Logout',
    value: 'logout'
  }
]

export function AccountNavItem({ className = '' }: { className?: string }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { cache } = useSWRConfig()

  function handleLogout() {
    // SWR v1.3.0 supports this as per the docs but is not typed
    // prettier-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cache as any)?.clear?.()
    // TODO: v2: Remove v1 logout when updated
    dispatch(logOut())
  }

  function handleSelect(value: AccountNavItemValueType) {
    if (value === 'settings') {
      const settingsPath =
        process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX ? ROUTE_PATHS.SETTINGS.API : ROUTE_PATHS.SETTINGS.ACCOUNT
      history.push(settingsPath)
    } else if (value === 'logout') {
      handleLogout()
    }
  }

  return (
    <SingleSelectDropdown
      align="end"
      className={twMerge('border-none py-6 px-6', className)}
      dropdownName="Account options"
      options={ACCOUNT_NAV_ITEM_OPTIONS}
      triggerContent={
        <>
          <UserIcon className="h-9 w-9" />
          <span className="sr-only">Account options</span>
        </>
      }
      updateSelected={(value) => handleSelect(value as AccountNavItemValueType)}
    />
  )
}
