// @flow
import React from 'react'
import type { AppState, DispatchFunc } from '../../../types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getPackingSlip, selectSelectedShippingMethodName } from '../../../selectors/manualOrderForm'
import { getRsaaStatusProps, getRsaaStatus } from '../../../selectors/rsaa'
import { getAllTemplateStatus, getAllArtworkStatus, selectTemplates } from '../../../selectors/images'
import {
  changeMerchantOrderId,
  clearOrderData,
  updatePriceToUser,
  createMofOrder,
  MOF_ORDER_LOGGER_ID,
  getMofBasketQuotes,
  BASKET_ITEM_LOGGER_ID,
  changeSelectedPrintAreaForBasketItem
} from '../../../actions/manualOrderForm'
import { getUserHasToSetUpCard, getCurrencyCode, getUser } from '../../../selectors/user'
import { selectMofQuotes } from '../../../selectors/quotes'
import WithUserDetails from '../../../components/hoc/WithUserDetails'
import { debounce } from 'lodash'
import { isAddressValid } from '../../../helpers'
import { CREATE_V4_ORDER } from '../../../actions/orders'
import { buildRsaaActionId } from '../../../helpers/rsaa/buildRsaaActionId'
import { resetRsaaStatus } from '../../../actions/rsaa'
import OrderSummaryController from './OrderSummaryController'
import WithRsaa from '../../../components/hoc/WithRsaa'
import { GET_QUOTES, GET_MOF_QUOTES_REQUEST_ID } from '../../../actions/quotes'
import { getCatalogueItemBySkus, getV4ProductDetailsBySkus } from '../../../selectors/catalogue'
import { SEARCH_PRODUCTS_BY_SKU_LIST } from '../../../actions/catalogue'
import { convertV4OrderIdToV3OrderId } from '../../../helpers/orderId'
import type { Props } from './OrderSummaryController'
import { FEATURE_NAMES } from '../../../split-io/feature-names'

// $FlowFixMe: TypeScript file
import { useSplitToggle } from '../../../v2/hooks'

function mapStateToProps(state: AppState) {
  const packingSlip = getPackingSlip(state)
  const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
  const basketItems = state.manualOrderForm.inProgressOrder.itemsToBeCreated
  const allSkus = basketItems.map((item) => item.sku)
  const catalogueDataActionId = buildRsaaActionId(SEARCH_PRODUCTS_BY_SKU_LIST, BASKET_ITEM_LOGGER_ID)

  return {
    addressIsValid: isAddressValid(state.manualOrderForm.customer),
    currencyCode: getCurrencyCode(state),
    hasToSetUpCard: getUserHasToSetUpCard(state),
    customer: state.manualOrderForm.customer,
    deliveryCountry: state.manualOrderForm.deliveryCountry,
    errorMessage: state.manualOrderForm.errorMessage,
    inProgressOrder: state.manualOrderForm.inProgressOrder,
    itemsToBeCreated: basketItems,
    merchantOrderId: state.manualOrderForm.inProgressOrder.merchantOrderId,
    orderError: state.manualOrderForm.inProgressOrder.orderError,
    selectedShippingMethodName: selectSelectedShippingMethodName(state),
    tag: state.manualOrderForm.tag,
    packingSlipUrl: packingSlip ? packingSlip.url : null,
    orderCreationStatus: getRsaaStatus(state, createOrderActionId),
    imageStatus: getAllArtworkStatus(state),
    templateStatus: getAllTemplateStatus(state),
    templates: selectTemplates(state),
    quotes: selectMofQuotes(state),
    catalogueItems: getCatalogueItemBySkus(state, allSkus),
    v4ProductDetails: getV4ProductDetailsBySkus(state, allSkus),
    catalogueDataRsaaStatus: getRsaaStatusProps(state, catalogueDataActionId),
    merchantId: getUser(state).merchantUniqueId,
    orderId: convertV4OrderIdToV3OrderId(state.manualOrderForm.inProgressOrder.id)
  }
}

const debounceTrackingEvent = debounce(window.analytics.track, 500)

function mapDispatchToProps(dispatch: DispatchFunc) {
  return {
    resetOrderCreationStatus: () => {
      const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
      dispatch(resetRsaaStatus(createOrderActionId))
    },
    changeMerchantOrderId: (merchantOrderId: string) => {
      dispatch(changeMerchantOrderId(merchantOrderId))
    },
    clearOrderData: () => dispatch(clearOrderData()),
    createV4Order: (isTransformUrlsOn?: boolean) => {
      dispatch(createMofOrder(isTransformUrlsOn))
    },
    updatePriceToUser: (priceToUserAsInt: number, basketItemId: number) => {
      debounceTrackingEvent('Change customs value', { label: 'MOF' })
      dispatch(updatePriceToUser(priceToUserAsInt, basketItemId))
    },
    getMofBasketQuotes: () => {
      dispatch(getMofBasketQuotes())
    },
    changeSelectedPrintAreaForBasketItem: (basketItemId: string, selectedPrintArea: string) => {
      dispatch(changeSelectedPrintAreaForBasketItem(basketItemId, selectedPrintArea))
    }
  }
}

const quotesRsaaActionId = buildRsaaActionId(GET_QUOTES, GET_MOF_QUOTES_REQUEST_ID)

export default (compose(
  WithUserDetails,
  WithRsaa(quotesRsaaActionId, 'quotes'),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderSummarySplit): any)

function OrderSummarySplit(props: Props) {
  const { splitIsOn: isTransformUrlsOn } = useSplitToggle({ toggle: FEATURE_NAMES.TRANSORM_URLS })

  return <OrderSummaryController {...props} isTransformUrlsOn={isTransformUrlsOn} />
}
