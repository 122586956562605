import { useDebouncedCallback } from 'use-debounce'
import { useMemo, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import SearchField from '../../SearchField'
import { VARIANTS_QUERY_PARAMS } from './Variants.component'

export function VariantsGlobalSearchInput({ tableStateGlobalSearchValue }: { tableStateGlobalSearchValue: string }) {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [searchValue, setSearchValue] = useState(tableStateGlobalSearchValue)

  // Sync with table state prop
  useEffect(() => {
    setSearchValue(tableStateGlobalSearchValue)
  }, [tableStateGlobalSearchValue])

  const debouncedSearch = useDebouncedCallback((globalSearchValue) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (!globalSearchValue) {
      newSearchParams.delete(VARIANTS_QUERY_PARAMS.GLOBAL_SEARCH)
    } else {
      newSearchParams.set(VARIANTS_QUERY_PARAMS.GLOBAL_SEARCH, globalSearchValue)
    }
    history.replace({ pathname, search: newSearchParams.toString() })
  }, 500)

  return (
    <div className="w-96">
      <SearchField
        placeholder="Search all"
        type="search"
        value={searchValue}
        onChange={(event) => {
          setSearchValue(event.target.value)
          debouncedSearch(event.target.value)
        }}
        onClearSearch={() => {
          setSearchValue('')
          debouncedSearch('')
        }}
      />
    </div>
  )
}
