import React, { useState } from 'react'
import { Remark } from 'react-remark'
import { HashLink } from 'react-router-hash-link'
import { ClockIcon, GlobeIcon } from '@heroicons/react/outline'

import Button from '../../Button'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function ProductDescription({ productData }: { productData: ProductCatalogueProductInterface }) {
  const [showEntireDescription, setShowEntireDescription] = useState<boolean>(productData.features.length === 0)

  const numberOfDescriptionFieldsAvailable = productData.description.length

  const numberOfDescriptionFieldsToShow = showEntireDescription ? numberOfDescriptionFieldsAvailable : 1
  const hasHiddenDescription = numberOfDescriptionFieldsToShow < numberOfDescriptionFieldsAvailable

  return (
    <div className="flex h-full flex-col py-10">
      <div>
        <div className="flex flex-wrap">
          <h1 className="mr-auto text-3xl text-black">{productData.name}</h1>
        </div>

        <h2 className="my-8 text-base text-black">{productData.strapline}</h2>

        <div className="mb-8 max-w-[600px]">
          <ul>
            {productData.description.slice(0, numberOfDescriptionFieldsToShow).map((line) => (
              <li key={line} className="my-4 list-none">
                <Remark
                  rehypeReactOptions={{
                    components: {
                      p(props: JSX.IntrinsicAttributes) {
                        return <React.Fragment {...props} />
                      },
                      a(props: JSX.IntrinsicAttributes) {
                        return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                      }
                    }
                  }}
                >
                  {line}
                </Remark>
              </li>
            ))}
          </ul>
          {productData.features.length > 0 && (showEntireDescription || hasHiddenDescription) && (
            <Button
              className="text-sm text-purple-500"
              variant="none"
              onClick={() => setShowEntireDescription(!showEntireDescription)}
            >
              {showEntireDescription ? 'Show less' : 'Read more'}...
            </Button>
          )}
        </div>

        <div>
          <ul>
            {productData.features.map((feature) => (
              <li key={feature} className="ml-2 mt-1 list-inside list-disc">
                <Remark
                  rehypeReactOptions={{
                    components: {
                      p(props: JSX.IntrinsicAttributes) {
                        return <React.Fragment {...props} />
                      },
                      a(props: JSX.IntrinsicAttributes) {
                        return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                      }
                    }
                  }}
                >
                  {feature}
                </Remark>
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-16 mt-10">
          {productData.variants.rows.length !== 0 && (
            <HashLink className="btn btn-primary w-fit" smooth to="#variants" replace>
              Order now
            </HashLink>
          )}
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex flex-wrap items-center gap-4 py-2 sm:flex-col sm:items-start sm:pr-12">
            <div className="flex flex-wrap gap-2">
              {productData.manufacturing.regions.map((region) => (
                <img
                  alt={region}
                  key={region}
                  className="h-10 w-10 rounded-full object-cover object-center"
                  src={`/img/flags-100/${region}.png`}
                  title={region}
                />
              ))}
            </div>
            <div className="font-medium">
              {productData.manufacturing.regions.length}{' '}
              {productData.manufacturing.regions.length > 1 ? 'regions' : 'region'}
            </div>
          </div>

          {productData.manufacturing.time && (
            <div className="flex items-center gap-2 border-gray-200 py-2 sm:flex-col sm:items-start sm:border-l sm:px-12">
              <ClockIcon className="h-12 w-12 stroke-1 text-gray-600" />
              <div className="font-medium">{productData.manufacturing.time}h lead time</div>
            </div>
          )}

          <div className="flex items-center gap-2 border-gray-200 py-2 sm:flex-col sm:items-start sm:border-l sm:px-12">
            <GlobeIcon className="h-12 w-12 stroke-1 text-gray-600" />
            <div className="font-medium">
              {productData.manufacturing.shipsTo.includes('Worldwide')
                ? 'Ships worldwide'
                : `Ships to ${productData.manufacturing.shipsTo.join(', ')}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
