import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { useState, useEffect, useCallback } from 'react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

/**
 * Adapted from https://www.embla-carousel.com/examples/predefined/#thumbnails
 */
export default function ImageCarousel({
  className,
  images,
  options
}: {
  className?: string
  images: string[]
  options?: EmblaOptionsType
}) {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options, [WheelGesturesPlugin()])
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel(
    {
      containScroll: 'keepSnaps',
      dragFree: true
    },
    [WheelGesturesPlugin()]
  )

  const onThumbClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaThumbsApi) return
      emblaMainApi.scrollTo(index)
    },
    [emblaMainApi, emblaThumbsApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaThumbsApi) return
    setSelectedIndex(emblaMainApi.selectedScrollSnap())
    emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaMainApi) return
    onSelect()

    emblaMainApi.on('select', onSelect).on('reInit', onSelect)
  }, [emblaMainApi, onSelect])

  const scrollPrev = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollPrev()
  }, [emblaMainApi])

  const scrollNext = useCallback(() => {
    if (emblaMainApi) emblaMainApi.scrollNext()
  }, [emblaMainApi])

  return (
    <>
      <div className={twMerge('mx-auto h-[400px] w-full', className)}>
        <div className="h-full w-full overflow-hidden" ref={emblaMainRef}>
          <div className="flex h-full w-full touch-pan-y touch-pinch-zoom">
            {images.map((image, index) => (
              <div className="w-full flex-shrink-0" key={image + index}>
                <img
                  className="h-full w-full cursor-grab object-contain object-center active:cursor-grabbing"
                  alt=""
                  src={image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mx-auto mt-6 flex w-fit cursor-grab items-stretch justify-center gap-3 active:cursor-grabbing">
        <button className="cursor-pointer whitespace-nowrap text-gray-700 hover:text-purple-500" onClick={scrollPrev}>
          <ChevronLeftIcon className="h-12 w-12 text-current transition-colors" />
        </button>

        <div className="overflow-hidden" ref={emblaThumbsRef}>
          <div className="flex gap-3">
            {images.map((image, index) => (
              <Thumbnail
                key={image + index}
                onClick={() => onThumbClick(index)}
                isSelected={index === selectedIndex}
                image={image}
              />
            ))}
          </div>
        </div>
        <button className="cursor-pointer whitespace-nowrap text-gray-700 hover:text-purple-500" onClick={scrollNext}>
          <ChevronRightIcon className="h-12 w-12 text-current transition-colors" />
        </button>
      </div>
    </>
  )
}

function Thumbnail({ image, isSelected, onClick }: { image: string; isSelected: boolean; onClick: () => void }) {
  return (
    <div className="flex-shrink-0">
      <button onClick={onClick} type="button" className="flex cursor-pointer items-center justify-center">
        <img
          alt=""
          className={clsx(
            'h-[50px] w-[50px] border object-contain object-center transition-colors',
            isSelected ? 'border-purple-500' : 'border-gray-200'
          )}
          src={image}
        />
      </button>
    </div>
  )
}
