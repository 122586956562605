import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { Ref, forwardRef, useEffect, useState } from 'react'
import { HomeIcon, MenuIcon, XIcon } from '@heroicons/react/solid'
import { BellIcon, ShoppingCartIcon } from '@heroicons/react/outline'

import LogoProdigi from '../../LogoProdigi'
import { ROUTE_PATHS } from '../../../constants'
import { NavGroupHelp } from './NavGroupHelp.component'
import { AccountNavItem } from './AccountNavItem.component'
import { NavGroupOrders } from './NavGroupOrders.component'
import { PORTAL_ID } from '../../../constants/portalId.const'
import { NavGroupSettings } from './NavGroupSettings.component'
import { NavGroupCatalogue } from './NavGroupCatalogue.component'
import { CreateOrderButton } from './CreateOrderButton.component'
import { NavGroupListContainer } from './NavGroupListContainer.component'
import { getNumberOfItemsInBasket } from '../../../../selectors/manualOrderForm'
import { FEATURE_NAMES } from 'src/split-io/feature-names'
import { useSplitToggle } from 'src/v2/hooks'

export function MobileNav() {
  const [showMobileNav, setShowMobileNav] = useState(false)

  return (
    <DialogPrimitive.Root open={showMobileNav} onOpenChange={setShowMobileNav}>
      <div className="flex h-full items-center">
        <div className="h-full flex-1">
          <div>
            <DialogPrimitive.Trigger
              className="flex h-full items-center justify-center p-6"
              onClick={() => setShowMobileNav(true)}
            >
              <MenuIcon className="h-10 w-10" />
              <span className="sr-only">Open navigation</span>
            </DialogPrimitive.Trigger>
          </div>
        </div>

        <div className="h-full">
          <Link className="flex h-full items-center px-2" to={ROUTE_PATHS.DASHBOARD}>
            <LogoProdigi className="h-12 w-12" />
          </Link>
        </div>

        <div className="flex h-full flex-1 justify-end">
          <div>
            <AccountNavItem />
          </div>
        </div>

        <AnimatePresence>
          {showMobileNav && (
            <DialogPrimitive.Portal container={document.getElementById(PORTAL_ID)} forceMount>
              <MobileNavContent onClose={() => setShowMobileNav(false)} />
            </DialogPrimitive.Portal>
          )}
        </AnimatePresence>
      </div>
    </DialogPrimitive.Root>
  )
}

export const MobileNavContent = forwardRef(
  ({ onClose }: { onClose: () => void }, ref: Ref<HTMLDivElement> | undefined) => {
    const history = useHistory()
    const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })

    useEffect(() => {
      const unlisten = history.listen(() => {
        onClose()
      })

      return () => {
        unlisten()
      }
    }, [history, onClose])

    return (
      <DialogPrimitive.Content
        ref={ref}
        onCloseAutoFocus={() => {
          document.body.style.overflow = 'unset'
        }}
        onOpenAutoFocus={() => {
          document.body.style.overflow = 'hidden'
        }}
      >
        <motion.div
          className="fixed inset-0 overflow-auto bg-purple-950 text-white"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="sticky top-0 z-10 bg-purple-950">
            <MobileNavHeader onClose={onClose} />
          </div>

          <div className="p-6">
            {!isShopifySplitOn && <CreateOrderButton className="w-fit" />}

            <NavGroupQuickLinks className="mt-10" onClose={onClose} />

            <NavGroupOrders className="mt-10" />

            <NavGroupCatalogue className="mt-10" />

            <NavGroupSettings className="mt-10" />

            <NavGroupHelp className="mt-10" onOpenChat={onClose} />
          </div>
        </motion.div>
      </DialogPrimitive.Content>
    )
  }
)
MobileNavContent.displayName = 'MobileNavContent'

function MobileNavHeader({ onClose }: { onClose: () => void }) {
  return (
    <div className="flex h-nav-bar-height items-center">
      <div className="flex-1">
        <div>
          <button className="flex h-full items-center justify-center p-6" onClick={onClose}>
            <XIcon className="h-10 w-10 " />
            <span className="sr-only">Close navigation</span>
          </button>
        </div>
      </div>

      <div className="h-full">
        <Link className="flex h-full items-center px-2" to={ROUTE_PATHS.DASHBOARD}>
          <LogoProdigi className="h-12 w-12 fill-white" />
        </Link>
      </div>

      <div className="flex h-full flex-1 justify-end">
        <div>
          <AccountNavItem className="bg-transparent" />
        </div>
      </div>
    </div>
  )
}

function NavGroupQuickLinks({ className, onClose }: { className?: string; onClose: () => void }) {
  const numberOfItemsInBasket: number = useSelector(getNumberOfItemsInBasket)
  const numberOfItemsInBasketToDisplay = numberOfItemsInBasket > 99 ? '99+' : numberOfItemsInBasket

  return (
    <div className={className}>
      <NavGroupListContainer>
        <li>
          <Link to={ROUTE_PATHS.DASHBOARD} className="side-nav-item">
            <HomeIcon className="h-7 w-7" />
            Home
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.MOF.BASKET} className="side-nav-item">
            <ShoppingCartIcon className="h-7 w-7" />
            Basket
            {numberOfItemsInBasketToDisplay
              ? `: ${numberOfItemsInBasketToDisplay} ${numberOfItemsInBasket > 1 ? 'items' : 'item'}`
              : null}
          </Link>
        </li>

        <li>
          <button
            className="side-nav-item beamer-trigger w-full hover:opacity-100"
            onClick={() => {
              window.Beamer?.show?.()
              onClose()
            }}
          >
            <BellIcon className="h-7 w-7" />
            News & updates
          </button>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
