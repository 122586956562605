import { useRef } from 'react'
import cx from 'classnames'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

interface ToggleHidePasswordInputPropsInterface {
  className?: string
  showPassword: boolean
  onShowPasswordChange: (val: boolean) => void
}

export default function ToggleHidePasswordInput({
  className,
  showPassword,
  onShowPasswordChange
}: ToggleHidePasswordInputPropsInterface) {
  const passwordToggleRef = useRef<HTMLInputElement>(null)

  return (
    <span
      className={cx(
        'absolute right-6 top-0 bottom-0 flex h-full w-8 cursor-pointer items-center justify-center',
        className
      )}
    >
      <input
        className="hidden h-full w-full"
        id="toggleShowPasswordCheckbox"
        name="toggleShowPassword"
        ref={passwordToggleRef}
        type="checkbox"
        onChange={(e) => {
          onShowPasswordChange(e.target.checked)
        }}
      />
      <button
        className="h-full w-full cursor-pointer border-none bg-transparent p-0 leading-none"
        type="button"
        onClick={() => passwordToggleRef?.current && passwordToggleRef?.current?.click()}
      >
        {showPassword ? <EyeIcon className="text-gray-800" /> : <EyeOffIcon className="text-gray-800" />}
        <span className="sr-only">{showPassword ? 'Hide password' : 'Show password'}</span>
      </button>
    </span>
  )
}
