import { useEffect } from 'react'
import { CheckCircleIcon } from '@heroicons/react/solid'

import NotFound from '../../NotFound'
import { downloadUrl } from '../../../helpers'

export function DownloadSuccess({ url }: { url: string }) {
  useEffect(() => {
    downloadUrl(url)
  }, [url])

  const downloadMessageBody = (
    <div className="text-center">
      Your download will begin shortly or{' '}
      <a className="text-purple-500" download href={url}>
        click here
      </a>{' '}
      if not
    </div>
  )

  return (
    <div className="grid min-h-screen place-content-center">
      <NotFound
        heading="Downloading file"
        icon={<CheckCircleIcon className="mx-auto mb-10 h-16 w-16" />}
        body={downloadMessageBody}
      />
    </div>
  )
}
