// @flow

import toast from 'react-hot-toast'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CheckIcon, PencilAltIcon } from '@heroicons/react/solid'

import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import styles from '../AttributeSelector/AttributeSelector.module.css'
import type { MultiAssetBasketItem } from '../../../../types/basketItem'
import type { CatalogueItem } from '../../../../types/catalogue/catalogue'
import { updateBasketItemMetaData } from '../../../../actions/manualOrderForm/manualOrderForm'

// $FlowFixMe: TypeScript file
import { useSplitToggle } from '../../../../v2/hooks'
// $FlowFixMe: TypeScript file
import Button from '../../../../v2/components/Button'
// $FlowFixMe: TypeScript file
import TextField from '../../../../v2/components/TextField'
// $FlowFixMe: TypeScript file
import { getProductFileCompatibilityData } from 'src/v2/helpers'
// $FlowFixMe: TypeScript file
import { PHOTOBOOK_SPINE_TEXT_REGEX } from '../../../../v2/constants'

type BasketItemMetaDataPropsType = {|
  basketItem: MultiAssetBasketItem,
  catalogueItem: CatalogueItem
|}

const DEFAULT_PHOTOBOOK_META_DATA = {
  spineBackgroundColourHexCode: '#ffffff',
  spineTextColourHexCode: '#000000',
  spineText: ''
}

export function BasketItemMetaData({ basketItem, catalogueItem }: BasketItemMetaDataPropsType): React$Node {
  const dispatch = useDispatch()
  const { splitIsOn: isPhotobookMetaDataSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.PHOTOBOOK_METADATA })

  const isPhotobookMetaDataRequired =
    isPhotobookMetaDataSplitOn &&
    getProductFileCompatibilityData?.({ productType: catalogueItem?.productType })?.metaDataType === 'photobook'

  if (isPhotobookMetaDataRequired) {
    return (
      <>
        <tr className={styles.row}>
          <td className={styles.label}>Spine Text:</td>
          <td className={styles.value}>
            <div className="tailwind">
              <SpineTextInput basketItem={basketItem} />
            </div>
          </td>
        </tr>

        <tr className={styles.row}>
          <td className={styles.label}>Spine Background Colour:</td>
          <td className={styles.value}>
            <div className="tailwind">
              <TextField
                className="h-12 w-24 cursor-pointer"
                type="color"
                value={
                  basketItem.metaData?.spineBackgroundColourHexCode ??
                  DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
                }
                onChange={(event) => {
                  const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
                  dispatch(
                    updateBasketItemMetaData(basketItem.id, {
                      ...basketItem.metaData,
                      spineBackgroundColourHexCode: value
                    })
                  )
                }}
              />
            </div>
          </td>
        </tr>

        <tr className={styles.row}>
          <td className={styles.label}>Spine Text Colour:</td>
          <td className={styles.value}>
            <div className="tailwind">
              <TextField
                className="h-12 w-24 cursor-pointer"
                type="color"
                value={
                  basketItem.metaData?.spineTextColourHexCode ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode
                }
                onChange={(event) => {
                  const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode
                  dispatch(
                    updateBasketItemMetaData(basketItem.id, { ...basketItem.metaData, spineTextColourHexCode: value })
                  )
                }}
              />
            </div>
          </td>
        </tr>
      </>
    )
  }

  return null
}

function SpineTextInput({ basketItem }: SpineTextInputPropsType) {
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(basketItem.metaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText)

  if (!isEditing) {
    return (
      <div className="flex items-center">
        {(basketItem.metaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText) && (
          <div className="mr-2">{basketItem.metaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText}</div>
        )}
        <Button
          variant="tertiary"
          size="sm"
          startIcon={<PencilAltIcon className="h-6 w-6" />}
          onClick={() => setIsEditing(true)}
        >
          Edit
        </Button>
      </div>
    )
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const isValueInvalid = value !== '' && !PHOTOBOOK_SPINE_TEXT_REGEX.test(value)

        if (isValueInvalid) {
          toast.error('Your text contains an unsupported character.')
          return
        }

        dispatch(updateBasketItemMetaData(basketItem.id, { ...basketItem.metaData, spineText: value }))
        setIsEditing(false)
      }}
    >
      <TextField
        autoFocus
        className="mt-0 mb-2 h-auto w-[150px] px-4 py-2 lg:w-[250px]"
        type="text"
        maxLength="1000"
        value={value}
        onChange={(event) => {
          setValue(event.target.value ?? '')
        }}
      />
      <Button variant="primary" size="sm" startIcon={<CheckIcon className="h-6 w-6" />} type="submit">
        Save
      </Button>
    </form>
  )
}

type SpineTextInputPropsType = {| basketItem: MultiAssetBasketItem |}
