import { useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'
import { TrashIcon } from '@heroicons/react/outline'

import Button from '../../Button'
import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { ROUTE_PATHS } from '../../../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { FetchErrorInterface } from '../../../interfaces'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { useMerchantService, useSplitToggle } from '../../../hooks'
import { SalesChannelPlatformEnum, StatusEnum } from '../../../enums'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../../SalesChannelConfigure/constants'
import { deleteSalesChannel } from '../../SalesChannelConfigure/helpers/deleteSalesChannel.helper'

export function DeleteChannelModalButton({
  platform,
  salesChannelId
}: {
  platform: SalesChannelPlatformEnum
  salesChannelId: string
}) {
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()
  const history = useHistory()

  const { splitIsOn: isDeleteOrdersSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.SALES_CHANNELS_DELETE_ORDERS })

  const [isDeleteChannelModalVisible, setIsDeleteChannelModalVisible] = useState(false)
  const [deleteChannelStatus, setDeleteChannelStatus] = useState(StatusEnum.Idle)

  async function handleDeleteSalesChannel() {
    toast.dismiss()
    setDeleteChannelStatus(StatusEnum.Loading)

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      const newMerchantDetails = {
        ...merchantDetails,
        salesChannels: merchantDetails.salesChannels.filter((salesChannel) => salesChannel.id !== salesChannelId)
      }
      await deleteSalesChannel({
        merchantId: merchantDetails.id,
        salesChannelId
      })
      setDeleteChannelStatus(StatusEnum.Success)
      history.push(ROUTE_PATHS.SALES_CHANNELS.INDEX)
      mutateMerchantDetails(newMerchantDetails)
      createToast({
        heading: 'Deleted successfully',
        id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS,
        type: 'success'
      })
      window.analytics.track('Sales Channel Deleted', { platformName: platform })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to update',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setDeleteChannelStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setDeleteChannelStatus(StatusEnum.Idle)
      toast.dismiss()
    }
    setIsDeleteChannelModalVisible(isOpen)
  }

  const isDeleting = deleteChannelStatus === StatusEnum.Loading

  return (
    <>
      <Button
        startIcon={<TrashIcon className="h-7 w-7" />}
        theme="danger"
        variant="secondary"
        onClick={() => handleOpenChange(true)}
      >
        Delete this channel
      </Button>

      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        continueButton={{ text: 'Delete channel', theme: 'danger' }}
        isLoading={isDeleting}
        open={isDeleteChannelModalVisible}
        title="Delete this channel"
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={handleDeleteSalesChannel}
      >
        <p>
          Prodigi will no longer receive orders automatically from this store. Any orders currently in production will
          be unaffected.
        </p>
        {isDeleteOrdersSplitOn && (
          <p className="mt-8">
            Any orders that have not been submitted for fulfilment will be deleted from Prodigi. If you have draft
            orders that you wish us to fulfil, we recommend submitting these for fulfilment before deleting this store
            connection.
          </p>
        )}
        <p className="mt-8">
          This cannot be undone, and you will need to reconnect and reconfigure your store if you wish to reactivate
          this channel.
        </p>
      </ConfirmationModal>

      {isDeleting && <OverlayPortal />}
    </>
  )
}
