// @flow
import type {
  DispatchFunc,
  Cost,
  V4OrderItem,
  V4ProductDetails,
  V4OrderCreationRequest,
  V4OrderCreationErrorResponse,
  CsvItemProductDetails,
  OrderItemAsset,
  FormattedOrderItem,
  Dictionary,
  FormattedCsvRow,
  GetStateFunc,
  V4OrderCreationResponse,
  CsvFileType,
  ThunkAsync
} from '../../types'
import type { CsvOrderSubmissionAction } from '../types'
import { mapCustomerAddressToRecipient } from '../../helpers'
import { getRequiredAttributeNames } from '../../helpers/v4ProductDetails'
import { mapV4AttributeNameToV3 } from '../../helpers/attributes'
import { createV4Order, CREATE_V4_ORDER_SUCCESS } from '../orders'
import * as ACTIONS from './orderSubmissionActions'
import { mapV4OrderCreationErrorMessage } from '../../helpers/errorMessages/mapV4OrderCreationErrorMessage'
import { getCsvItemProductDetails, getCsvOrderQuotesByOrderId, selectV4OrderTotal } from '../../selectors/csvUpload'
import { entries } from '../../helpers/dictionary'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { nanoid } from 'nanoid'
import { combineCosts } from '../../helpers/charge'

export function submitCsvV4Orders(
  csvFileType: ?CsvFileType,
  csvOrders: FormattedCsvRow[],
  csvOrderItems: Dictionary<FormattedOrderItem[]>,
  productDetails: Dictionary<V4ProductDetails>,
  userCurrency: string,
  isTransformUrlsOn: boolean
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    let submittedOrders = 0
    let submittedOrderItems = 0
    let submittedOrderCost: Cost[] = []

    for (const csvOrder of csvOrders) {
      const orderItems = csvOrderItems[csvOrder.id]
      const mappedOrder = mapCsvOrderToApiV4Order(csvOrder, orderItems, productDetails, userCurrency, isTransformUrlsOn)
      const actionResult = await dispatch(createV4Order(mappedOrder, csvOrder.id))

      const successfullOutcome =
        actionResult.payload.outcome === V4_API_ACTION_OUTCOME.CREATED ||
        actionResult.payload.outcome === V4_API_ACTION_OUTCOME.ON_HOLD
      const isSuccess = Boolean(successfullOutcome && actionResult.type === CREATE_V4_ORDER_SUCCESS)
      if (isSuccess) {
        submittedOrders += 1
        orderItems.forEach((item) => {
          submittedOrderItems += 1 * item.quantity
        })

        const orderQuotes = getCsvOrderQuotesByOrderId(getState(), csvOrder.id) ?? []
        const orderCost = selectV4OrderTotal(orderQuotes, csvOrder.preferredShippingMethod)
        if (orderCost) {
          submittedOrderCost.push(orderCost)
        }

        const newOrderId = actionResult.payload.order.id
        dispatch(submitCsvOrderSuccess(csvOrder, newOrderId))
      } else {
        const orderItemDetails = getCsvItemProductDetails(getState(), csvOrder.id)

        const mappedErrorMessage = mapErrorMessage(
          actionResult.payload.response || actionResult.payload,
          actionResult.payload.status,
          actionResult.meta.requestBody,
          orderItemDetails
        )

        dispatch(submitCsvOrderError(csvOrder, actionResult.payload.status, mappedErrorMessage))
      }
    }

    if (submittedOrders > 0) {
      const submittedOrdersTotal = combineCosts(submittedOrderCost).toFixed(2)
      window.analytics.track(
        'Csv submission success',
        {
          csvFileType,
          submittedOrders,
          submittedOrderItems,
          submittedOrdersTotal,
          userCurrency
        },
        { label: 'csv uploader' }
      )
    }
  }
}

function mapErrorMessage(
  errorResponseBody: V4OrderCreationErrorResponse | V4OrderCreationResponse,
  statusCode: ?number,
  orderCreationRequestBody: V4OrderCreationRequest,
  orderItemDetails: Dictionary<CsvItemProductDetails>
): string {
  const skusInSubmissionOrder = orderCreationRequestBody.items.map((item) => item.sku)
  const productCategoriesBySku = entries(orderItemDetails).reduce((categoryAcc, [sku, productDetails]) => {
    categoryAcc[sku] = productDetails.category
    return categoryAcc
  }, {})

  return mapV4OrderCreationErrorMessage(errorResponseBody, productCategoriesBySku, skusInSubmissionOrder, statusCode)
}

function submitCsvOrderSuccess(order: FormattedCsvRow, pwintyOrderId: number): CsvOrderSubmissionAction<*> {
  return {
    type: ACTIONS.SUBMIT_CSV_ORDER_SUCCESS,
    order,
    pwintyOrderId
  }
}

function submitCsvOrderError(
  order: FormattedCsvRow,
  statusCode: number,
  errorMessage: string
): CsvOrderSubmissionAction<*> {
  return {
    type: ACTIONS.SUBMIT_CSV_ORDER_ERROR,
    order,
    errorMessage,
    statusCode
  }
}

function mapCsvOrderToApiV4Order(
  csvOrder: FormattedCsvRow,
  orderItems: FormattedOrderItem[],
  productDetails: Dictionary<V4ProductDetails>,
  userCurrency: string,
  isTransformUrlsOn: boolean
): V4OrderCreationRequest {
  return {
    tag: 'source:CSV',
    merchantReference: csvOrder.merchantOrderId ? csvOrder.merchantOrderId : null,
    packingSlip: csvOrder.packingSlip ? { url: csvOrder.packingSlip } : null,
    shippingMethod: csvOrder.preferredShippingMethod,
    items: mapOrderItems(csvOrder, orderItems, productDetails, userCurrency, isTransformUrlsOn),
    recipient: mapCustomerAddressToRecipient(csvOrder.customer),
    idempotencyKey: nanoid(),
    USSalesTaxAlreadyCollected: csvOrder.customer.countryCode === 'US' ? csvOrder.isUsSalesTaxCollected : undefined
  }
}

function mapOrderItems(
  csvOrder: FormattedCsvRow,
  orderItems: FormattedOrderItem[],
  productDetails: Dictionary<V4ProductDetails>,
  userCurrency: string,
  isTransformUrlsOn: boolean
): V4OrderItem[] {
  return orderItems.map((orderItem) => {
    return {
      sku: orderItem.sku,
      copies: orderItem.quantity,
      sizing: 'fillPrintArea',
      assets: mapOrderItemAssets(orderItem, productDetails[orderItem.sku], isTransformUrlsOn),
      attributes: mapAttributes(orderItem, productDetails[orderItem.sku]),
      recipientCost: csvOrder.customsValue
        ? mapRecipientCost(csvOrder.customsValue, orderItems.length, userCurrency)
        : null
    }
  })
}

function mapOrderItemAssets(
  orderItem: FormattedOrderItem,
  productDetails: V4ProductDetails,
  isTransformUrlsOn: boolean
): OrderItemAsset[] {
  const printAreasWithArtworks = Object.keys(orderItem.printAreaImageUrls).filter((printAreaName) =>
    Boolean(orderItem.printAreaImageUrls[printAreaName])
  )

  return printAreasWithArtworks.map((printArea) => {
    if (!orderItem.printAreaImageUrls[printArea]) {
      throw Error(`printAreaImageUrl cannot be null - ${orderItem.id}, ${orderItem.sku}, ${printArea} `)
    }
    const url = orderItem.printAreaImageUrls[printArea]
    const transformImageUrl = orderItem.transformImageUrls[printArea] ?? url

    return {
      printArea,
      url: isTransformUrlsOn ? transformImageUrl : url
    }
  })
}

function mapRecipientCost(totalOrderCustomsValueAsInt: number, orderItemCount: number, currencyCode: string): Cost {
  const orderItemValue = Math.round(totalOrderCustomsValueAsInt / orderItemCount)
  return {
    amount: (orderItemValue / 100).toFixed(2),
    currency: currencyCode
  }
}

function mapAttributes(orderItem: FormattedOrderItem, productDetails: V4ProductDetails): Dictionary<string> {
  const requiredAttributeNames = getRequiredAttributeNames(productDetails)

  return requiredAttributeNames.reduce((attributeAcc, requiredAttributeName) => {
    const v3AttributeName = mapV4AttributeNameToV3(requiredAttributeName)

    attributeAcc[requiredAttributeName] = orderItem.selectedAttributes[v3AttributeName]
    return attributeAcc
  }, {})
}
