import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CellContext } from '@tanstack/react-table'

import Button from '../../Button'
import { VariantRowType } from '../../../hooks'
import ProductDetailsModal from '../../ProductDetailsModal'
import { selectCurrencyCode } from '../../../../selectors/user'
import { selectDeliveryCountry } from '../../../../selectors/manualOrderForm'

export function VariantsInfoAction({ info }: { info: CellContext<VariantRowType, unknown> }) {
  const [open, setOpen] = useState(false)
  const currencyCode = useSelector(selectCurrencyCode)
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)

  const sku = info.row.original.sku
  const description = info.row.original.description

  return (
    <div className="flex justify-end">
      <Button
        variant="tertiary"
        onClick={() => {
          window.analytics.track('Show product details modal', { sku })
          setOpen(true)
        }}
      >
        <InfoIcon />
      </Button>

      <ProductDetailsModal
        actions={<></>}
        countryCode={selectedDeliveryCountry}
        currencyCode={currencyCode}
        description={description}
        open={open}
        sku={sku}
        showCountryPicker={true}
        setOpen={setOpen}
      />
    </div>
  )
}

function InfoIcon() {
  return (
    <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.354 4.906C1.582 4.858 1.81 4.822 2.038 4.798C2.266 4.774 2.488 4.756 2.704 4.744C2.92 4.72 3.124 4.708 3.316 4.708C3.556 4.708 3.778 4.78 3.982 4.924C4.186 5.068 4.348 5.26 4.468 5.5C4.588 5.74 4.624 5.992 4.576 6.256C4.504 6.712 4.402 7.156 4.27 7.588C4.15 8.02 4.012 8.44 3.856 8.848C3.712 9.256 3.574 9.664 3.442 10.072C3.31 10.468 3.196 10.864 3.1 11.26C3.028 11.644 2.98 11.938 2.956 12.142C2.944 12.346 2.944 12.526 2.956 12.682C3.028 12.742 3.112 12.79 3.208 12.826C3.304 12.85 3.406 12.862 3.514 12.862C3.73 12.862 3.916 12.82 4.072 12.736C4.24 12.64 4.408 12.502 4.576 12.322H4.684C4.708 12.49 4.714 12.67 4.702 12.862C4.69 13.054 4.666 13.252 4.63 13.456C4.594 13.624 4.534 13.78 4.45 13.924C4.378 14.056 4.288 14.182 4.18 14.302C4.084 14.398 3.97 14.476 3.838 14.536C3.706 14.596 3.556 14.644 3.388 14.68C3.232 14.716 3.052 14.734 2.848 14.734C2.572 14.734 2.314 14.674 2.074 14.554C1.846 14.446 1.648 14.278 1.48 14.05C1.312 13.822 1.192 13.534 1.12 13.186C1.06 12.838 1.06 12.43 1.12 11.962C1.192 11.446 1.288 10.966 1.408 10.522C1.54 10.066 1.678 9.628 1.822 9.208C1.978 8.788 2.122 8.374 2.254 7.966C2.386 7.546 2.5 7.114 2.596 6.67L2.506 6.598C2.374 6.646 2.242 6.694 2.11 6.742C1.978 6.79 1.852 6.832 1.732 6.868C1.624 6.892 1.516 6.904 1.408 6.904C1.24 6.904 1.114 6.802 1.03 6.598C0.946 6.382 0.958 6.058 1.066 5.626C1.102 5.506 1.138 5.386 1.174 5.266C1.222 5.146 1.282 5.026 1.354 4.906ZM3.604 0.856C3.688 0.831999 3.778 0.813999 3.874 0.801999C3.97 0.789999 4.066 0.784 4.162 0.784C4.27 0.772 4.372 0.766 4.468 0.766C4.564 0.766 4.654 0.766 4.738 0.766C5.266 0.766 5.632 0.898 5.836 1.162C6.04 1.426 6.082 1.798 5.962 2.278L5.8 2.998C5.704 3.01 5.608 3.022 5.512 3.034C5.416 3.046 5.32 3.058 5.224 3.07C5.128 3.07 5.032 3.07 4.936 3.07C4.84 3.07 4.744 3.07 4.648 3.07C4.096 3.07 3.718 2.95 3.514 2.71C3.31 2.458 3.28 2.062 3.424 1.522L3.604 0.856Z"
        fill="#420FA0"
      />
    </svg>
  )
}
